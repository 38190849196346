import { createRouter, createWebHistory } from 'vue-router'

import RegisterScreen from '@/components/SignComponents/RegisterScreen.vue'
import SplashScreen from '@/components/SignComponents/SplashScreen.vue' 
import LoginScreen from '@/components/SignComponents/LoginScreen.vue'
import OTPScreen from '@/components/SignComponents/OTPScreen'
import AdhaarScreen from '@/components/KYCComponents/AdhaarScreen.vue'
import DrivingScreen from '@/components/KYCComponents/DrivingScreen.vue'

import CalendarScreen from '@/components/CalendarScreen.vue'
import TermsConditions from '@/components/TermsConditions.vue'
import RefundCancellation from '@/components/RefundCancellation.vue'
import PrivacyPolicy from '@/components/PrivacyPolicy.vue'

import HomeScreen from '@/components/HomeComponents/HomeScreen.vue'
import MobileBookings from '@/components/BookingsComponents/MobileBookings.vue'
import Myrides from '@/components/BookingsComponents/Myrides.vue'
import WishScreen from '@/components/WishScreen.vue'
import KYCScreen from '@/components/KYCComponents/KYCScreen.vue'

import ProfileScreen from '@/components/ProfileScreen.vue'

import UpdateAdhaar from '@/components/KYCComponents/UpdateAdhaar.vue'
import UpdateDriving from '@/components/KYCComponents/UpdateDriving.vue'


const routes = [
  {
    path: '/',
    name: 'HomeScreen',
    component: HomeScreen
  },
  {
    path: '/bookings',
    name: 'MobileBookings',
    component: MobileBookings
  },
  {
    path: '/rides',
    name: 'Myrides',
    component: Myrides
  },
  {
    path: '/wishlist',
    name: 'WhishScreen',
    component: WishScreen
  },
  {
    path: '/kyc',
    name: 'KYCScreen',
    component: KYCScreen
  },
  {
    path: '/login',
    name: 'SplashScreen',
    component: SplashScreen
  },
  {
    path: '/signup',
    name: 'RegisterScreen',
    component: RegisterScreen
  },
  {
    path: '/signin',
    name: 'LoginScreen',
    component: LoginScreen
  },
  {
    path: '/KYC-1',
    name: 'AdhaarScreen',
    component: AdhaarScreen
  },
  {
    path: '/KYC-2',
    name: 'DrivingScreen',
    component: DrivingScreen
  },
  {
    path: '/otp',
    name: 'OTPScreen',
    component: OTPScreen
  },
  {
    path: '/calendar',
    name: 'CalendarScreen',
    component: CalendarScreen
  },
  {
    path: '/terms&conditions',
    name: 'TermsConditions',
    component: TermsConditions
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/refund&cancellations',
    name: 'RefundCancellation',
    component: RefundCancellation
  },
  {
    path: '/edit-profile',
    name: 'ProfileScreen',
    component: ProfileScreen
  },
  {
    path: '/edit-adhaar',
    name: 'UpdateAdhaar',
    component: UpdateAdhaar
  },
  {
    path: '/edit-dl',
    name: 'UpdateDriving',
    component: UpdateDriving
  },
]

const router = createRouter({
  base: process.env.VUE_APP_PUBLICPATH,
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
