<template>
  <div class="container">

    <!-- Modal1 -->
    <input
      type="text"
      v-model="dates"
      data-bs-toggle="modal"
      data-bs-target="#staticBackdrop"
    />

    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Start Date & Time
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <!-- Date -->
            <div class="mb-4">
              <label for="select-date" class="pb-2">Select Start Date</label>
              <div id="date"></div>
            </div>
            <!-- Date-->
            <!-- Time -->
            <div>
              <label for="pickupHour" class="mb-1">Pickup Hour</label>
              <select
                class="form-select"
                aria-label="Default select example"
                id="time"
              >
                <option value="8:00:00">8:00 AM</option>
                <option value="8:30:00">8:30 AM</option>
                <option value="9:00:00">9:00 AM</option>
                <option value="9:30:00">9:30 AM</option>
                <option value="10:00:00">10:00 AM</option>
                <option value="10:30:00">10:30 AM</option>
                <option value="11:00:00">11:00 AM</option>
                <option value="11:30:00">11:30 AM</option>
                <option value="12:00:00">12:00 PM</option>
                <option value="12:30:00">12:30 PM</option>
                <option value="13:00:00">1:00 PM</option>
                <option value="13:30:00">1:30 PM</option>
                <option value="14:00:00">2:00 PM</option>
                <option value="14:30:00">2:30 PM</option>
                <option value="15:00:00">3:00 PM</option>
                <option value="15:30:00">3:30 PM</option>
                <option value="16:00:00">4:00 PM</option>
                <option value="16:30:00">4:30 PM</option>
                <option value="17:00:00">5:00 PM</option>
                <option value="17:30:00">5:30 PM</option>
                <option value="18:00:00">6:00 PM</option>
                <option value="18:30:00">6:30 PM</option>
                <option value="19:00:00">7:00 PM</option>
                <option value="19:30:00">7:30 PM</option>
                <option value="20:00:00">8:00 PM</option>
                <option value="20:30:00">8:30 PM</option>
              </select>
            </div>
            <!-- Time -->

            <div class="d-flex align-items-center justify-content-center py-4">
              <div class="pe-2 w-100">
                <button
                  type="button"
                  class="btn btn-outline-danger w-100"
                  v-on:click="Clear()"
                >
                  Clear
                </button>
              </div>
              <div class="ps-2 w-100">
                <button
                  type="button"
                  class="btn btn-outline-success w-100"
                  v-on:click="Submit()"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal1 -->




    <!-- Modal2 -->
    <input
      type="text"
      v-model="datesend"
      data-bs-toggle="modal"
      data-bs-target="#staticBackdrop2"
    />

    <div
      class="modal fade"
      id="staticBackdrop2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdrop2Label"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdrop2Label">
              End Date & Time
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <!-- Date -->
            <div class="mb-4">
              <label for="select-enddate" class="pb-2">Select Start Date</label>
              <div id="dateend"></div>
            </div>
            <!-- Date-->
            <!-- Time -->
            <div>
              <label for="dropHour" class="mb-1">Pickup Hour</label>
              <select
                class="form-select"
                aria-label="Default select example"
                id="timeend"
              >
                <option value="8:00:00">8:00 AM</option>
                <option value="8:30:00">8:30 AM</option>
                <option value="9:00:00">9:00 AM</option>
                <option value="9:30:00">9:30 AM</option>
                <option value="10:00:00">10:00 AM</option>
                <option value="10:30:00">10:30 AM</option>
                <option value="11:00:00">11:00 AM</option>
                <option value="11:30:00">11:30 AM</option>
                <option value="12:00:00">12:00 PM</option>
                <option value="12:30:00">12:30 PM</option>
                <option value="13:00:00">1:00 PM</option>
                <option value="13:30:00">1:30 PM</option>
                <option value="14:00:00">2:00 PM</option>
                <option value="14:30:00">2:30 PM</option>
                <option value="15:00:00">3:00 PM</option>
                <option value="15:30:00">3:30 PM</option>
                <option value="16:00:00">4:00 PM</option>
                <option value="16:30:00">4:30 PM</option>
                <option value="17:00:00">5:00 PM</option>
                <option value="17:30:00">5:30 PM</option>
                <option value="18:00:00">6:00 PM</option>
                <option value="18:30:00">6:30 PM</option>
                <option value="19:00:00">7:00 PM</option>
                <option value="19:30:00">7:30 PM</option>
                <option value="20:00:00">8:00 PM</option>
                <option value="20:30:00">8:30 PM</option>
              </select>
            </div>
            <!-- Time -->

            <div class="d-flex align-items-center justify-content-center py-4">
              <div class="pe-2 w-100">
                <button
                  type="button"
                  class="btn btn-outline-danger w-100"
                  v-on:click="ClearEnd()"
                >
                  Clear
                </button>
              </div>
              <div class="ps-2 w-100">
                <button
                  type="button"
                  class="btn btn-outline-success w-100"
                  v-on:click="SubmitEnd()"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal1 -->




  </div>
</template>

 
<script>
export default {
  name: "App",
  data() {
    return {
      dates: null,
      time: "",

      datesend: null,
      timeend: ""
    };
  },
  methods: {
    Submit() {
      $("#staticBackdrop").modal("hide");
      console.log(this.$storage.getStorageSync('mydate'));
      console.log(this.$storage.getStorageSync('mytime'));

    },
    Clear(){
      this.dates="";
      $("#staticBackdrop").modal("hide");
    },

     SubmitEnd() {
      $("#staticBackdrop2").modal("hide");
      console.log(this.$storage.getStorageSync('mydate2'));
      console.log(this.$storage.getStorageSync('mytime2'));

    },
    ClearEnd(){
      this.datesend="";
      $("#staticBackdrop2").modal("hide");
    }
  },

   watch: {
    time(newtime) {
      this.dates = this.dates.split(',')[0]+',';
      this.dates += newtime;

      this.$storage.setStorageSync('mydate', this.dates.split(',')[0]);
      this.$storage.setStorageSync('mytime', this.dates.split(',')[1]);
    },
    timeend(newtimeend) {
      this.datesend = this.datesend.split(',')[0]+',';
      this.datesend += newtimeend;

      this.$storage.setStorageSync('mydate2', this.datesend.split(',')[0]);
      this.$storage.setStorageSync('mytime2', this.datesend.split(',')[1]);
    },
  },

  mounted() {
    var _this = this;
    $(function () {
      $("#date").datepicker({
        minDate: 0,
        dateFormat: "dd-mm-yy",
      });

      $("#date").on("change", function () {
        _this.getAllData;
        var selected = $(this).val();
        // console.log(selected);
        _this.dates = selected+",";
        // console.log(_this.dates);
      });

      $("#time").on("change", function () {
        _this.time = "";
        var selected = $(this).val();
        _this.time = selected;
      });
    });

    // dafsgd

    $(function () {
      $("#dateend").datepicker({
        minDate: 0,
        dateFormat: "dd-mm-yy",
      });

      $("#dateend").on("change", function () {
        _this.getAllData;
        var selected = $(this).val();
        // console.log(selected);
        _this.datesend = selected+",";
        // console.log(_this.dates);
      });

      $("#timeend").on("change", function () {
        _this.timeend = "";
        var selected = $(this).val();
        _this.timeend = selected;
      });
    });
  },
};
</script>



  <style >
/*Calendar*/

.ui-datepicker {
  width: 100% !important;
  height: auto;
  margin: 5px auto 0;
  /* -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5); */
}
.ui-datepicker a {
  text-decoration: none;
}
.ui-datepicker table {
  width: 100%;
}
.ui-datepicker-header {
  background-color: #ff66000d !important;
  color: #ff6600;
  font-weight: bold;
  line-height: 30px;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: transparent;
  border-radius: 5px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.ui-datepicker-title {
  text-align: center;
}
.ui-datepicker-prev,
.ui-datepicker-next {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  background-image: url("~@/assets/arrow.png");
  background-repeat: no-repeat;
  line-height: 600%;
  overflow: hidden;
}
.ui-datepicker-prev {
  float: left;
  background-position: center -30px;
}
.ui-datepicker-next {
  float: right;
  background-position: center 0px;
}
.ui-datepicker th {
  /* text-transform: uppercase; */
  padding: 5px 0;
  color: #000;
  text-align: center;
}
.ui-datepicker tbody td {
  padding: 0;
}

.ui-datepicker thead {
  background: #fff !important;
}

.ui-datepicker td span,
.ui-datepicker td a {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  width: 100%;
  height: 100%;
  line-height: 30px;
  color: #000;
}
.ui-datepicker-calendar .ui-state-default {
  background-color: #fff;
}
.ui-datepicker-unselectable .ui-state-default {
  background: #fff;
  color: #b4b3b3;
}
.ui-datepicker-calendar .ui-state-hover {
  background: #ff6600;
  color: #fff;
}

.ui-datepicker-calendar .ui-state-active {
  background: #ff6600;
  color: #fff;
  position: relative;
  margin: -1px;
}
</style>
