<template>
  <div id="App">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style scope>
#App {
  overflow-x: hidden;
}
</style>
