<template>
  <div class="main">
    <!-- OTP ALERT Modal -->
    <div
      class="modal fade"
      id="otpalert"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body py-4">
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <div>
                <img src="@/assets/otpalert.png" alt="" class="img-fluid" />
              </div>
              <p class="text-center py-3">
                You have successfully logged in.<br> Welcome back to
                <span style="color: #ff6600">SPEED RENT</span>!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="valert"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body py-4">
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <div>
                <img src="@/assets/otpalert.png" alt="" class="img-fluid" />
              </div>
              <p class="text-center py-3">
                 OTP verified<br> Please Complete Your
                <span style="color: #ff6600">KYC</span>!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="invalidalert"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body py-4">
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <div>
                <!-- <img src="@/assets/otpalert.png" alt="" class="img-fluid" /> -->
              </div>
              <p class="text-center py-3">
                Invalid OTP!<br> Please Try Again!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Web View -->
    <section class="web d-none d-lg-block">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-7 col-lg-6 first-sec">
            <div class="row">
              <div class="col-md-12">
                <div class="px-5 pt-5">
                  <img
                    src="@/assets/speedrentlogo.png"
                    alt=""
                    class="logo img-fluid"
                  />
                </div>
              </div>
            </div>
            <div class="row pt-xxl-5 pt-xl-3 pt-5">
              <div
                class="col-md-12 d-flex align-items-center justify-content-start pt-xl-2 pt-5"
              >
                <div class="ps-xxl-5 ps-xl-2 ps-0">
                  <img
                    src="@/assets/Hero.png"
                    alt=""
                    class="img-fluid hero-img ps-5"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-5 col-lg-6 second-sec">
            <div class="row">
              <div class="col-md-12">
                <router-link to="/">
                  <div class="px-5 pt-5 float-end">
                    <button type="button" class="btn btn-outline px-5">
                      Skip
                    </button>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="row pt-xxl-5 pt-xl-3 pt-3">
              <div
                class="col-md-12 d-flex align-items-center justify-content-center pt-5"
              >
                <div class="content pt-5">
                  <form
                    class="w-100 d-flex align-items-center justify-content-center pt-1"
                    v-on:submit.prevent="onVerify()"
                  >
                    <div class="p-5 form">
                      <h6 class="pb-3">
                        Please enter a 6 digit code to your mobile number to
                        verification
                      </h6>
                      <div
                        class="inputs d-flex flex-row justify-content-center pb-3"
                      >
                        <input
                          v-model="otp1"
                          class="m-2 text-center form-control rounded otpinput"
                          type="text"
                          id="first"
                          maxlength="1"
                        />
                        <input
                          v-model="otp2"
                          class="m-2 text-center form-control rounded otpinput"
                          type="text"
                          id="second"
                          maxlength="1"
                        />
                        <input
                          v-model="otp3"
                          class="m-2 text-center form-control rounded otpinput"
                          type="text"
                          id="third"
                          maxlength="1"
                        />
                        <input
                          v-model="otp4"
                          class="m-2 text-center form-control rounded otpinput"
                          type="text"
                          id="fourth"
                          maxlength="1"
                        />
                        <input
                          v-model="otp5"
                          class="m-2 text-center form-control rounded otpinput"
                          type="text"
                          id="fifth"
                          maxlength="1"
                        />
                        <input
                          v-model="otp6"
                          class="m-2 text-center form-control rounded otpinput"
                          type="text"
                          id="sixth"
                          maxlength="1"
                        />
                      </div>
                      <div
                        class="d-flex align-items-center justify-content-center pb-2"
                      >
                        <a href="/signin">
                          <span style="font-size: small"
                          >+91 {{ this.$storage.getStorageSync("pnumber") }}
                          <span class="ps-2"
                            ><i class="fa-solid fa-pen"></i
                          ></span>
                          <span class="text-uppercase ps-1">Edit</span></span
                        >
                        </a>
                      </div>
                      <div class="text-center">
                        <button
                          type="submit"
                          class="btn btn-primary btn-large w-100 text-uppercase"
                          :disabled="!otp1 || !otp2 || !otp3 || !otp4 || !otp5 || !otp6"
                        >
                          verify
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Mobile View -->
    <section class="mobile-login p-0 d-lg-none">
      <div class="log-sec row">
        <div class="col-12 py-3">
          <router-link to="/">
            <div
              class="logo-c col-12 d-flex align-items-end justify-content-end py-3 px-5"
            >
              <button type="button" class="btn btn-outline-light px-3">
                <img src="@/assets/arrow.svg" alt="" /> Skip
              </button>
            </div>
          </router-link>
          <div class="col-12 d-flex align-items-center justify-content-center">
            <img src="@/assets/loginlogo.png" alt="" class="img-fluid" />
          </div>
        </div>
        <div
          class="logo-c2 col-12 bg-light rounded-5 rounded-bottom pb-0 px-4 pt-5"
        >
          <div class="mt-5">
            <form
              class="w-100 d-flex align-items-center justify-content-center pt-1"
              v-on:submit.prevent="onVerify()"
            >
              <div class="form">
                <h6 class="pb-3">
                  Please enter a 6 digit code to your mobile number to
                  verification
                </h6>
                <div class="inputs d-flex flex-row justify-content-center pb-3">
                  <input
                    class="m-2 text-center form-control rounded otpinput2"
                    v-model="otp1"
                    type="text"
                    id="first"
                    maxlength="1"
                  />
                  <input
                    v-model="otp2"
                    class="m-2 text-center form-control rounded otpinput2"
                    type="text"
                    id="second"
                    maxlength="1"
                  />
                  <input
                    v-model="otp3"
                    class="m-2 text-center form-control rounded otpinput2"
                    type="text"
                    id="third"
                    maxlength="1"
                  />
                  <input
                    v-model="otp4"
                    class="m-2 text-center form-control rounded otpinput2"
                    type="text"
                    id="fourth"
                    maxlength="1"
                  />
                  <input
                    v-model="otp5"
                    class="m-2 text-center form-control rounded otpinput2"
                    type="text"
                    id="fifth"
                    maxlength="1"
                  />
                  <input
                    v-model="otp6"
                    class="m-2 text-center form-control rounded otpinput2"
                    type="text"
                    id="sixth"
                    maxlength="1"
                  />
                </div>
                <div
                  class="d-flex align-items-center justify-content-center pb-2"
                >
                <a href="/signin">
                          <span style="font-size: small"
                          >+91 {{ this.$storage.getStorageSync("pnumber") }}
                          <span class="ps-2"
                            ><i class="fa-solid fa-pen"></i
                          ></span>
                          <span class="text-uppercase ps-1">Edit</span></span
                        >
                        </a>
                  
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-primary btn-large w-100 text-uppercase"
                    :disabled="!otp1 || !otp2 || !otp3 || !otp4 || !otp5 || !otp6"
                  >
                    verify
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!-- mobile login view ends-->
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "App",
  data() {
    return {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
      verified: "",
      submitted: false,
    };
  },
  watch: {
    otp1(newotp1) {
      this.$storage.setStorageSync("otp1", newotp1);
    },
    otp2(newotp2) {
      this.$storage.setStorageSync("otp2", newotp2);
    },
    otp3(newotp3) {
      this.$storage.setStorageSync("otp3", newotp3);
    },
    otp4(newotp4) {
      this.$storage.setStorageSync("otp4", newotp4);
    },
    otp5(newotp5) {
      this.$storage.setStorageSync("otp5", newotp5);
    },
    otp6(newotp6) {
      this.$storage.setStorageSync("otp6", newotp6);
    },
  },
  methods: {
    onVerify() {
      axios
        .post(
          "https://speedrent.in/v2web/verifyOtp.php",
          {
            pnumber: this.$storage.getStorageSync("pnumber"),
            otp:
              this.$storage.getStorageSync("otp1") +
              this.$storage.getStorageSync("otp2") +
              this.$storage.getStorageSync("otp3") +
              this.$storage.getStorageSync("otp4") +
              this.$storage.getStorageSync("otp5") +
              this.$storage.getStorageSync("otp6"),
          },
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }
        )
        .then((response) => {
          console.log("------------------------");
          console.log(response);
         console.log("------------------------");

          this.$storage.setStorageSync("message", response.data.message);
           this.$storage.setStorageSync("kycRequired", response.data.kycRequired);
          console.log(this.$storage.getStorageSync("message", response.data.message));
           console.log(this.$storage.getStorageSync("kycRequired", response.data.kycRequired));
          if (response.data.message == "wrong OTP") {
            console.log(response.data.message);
            $("#invalidalert").modal("show");
              setTimeout(function () {
                $("#invalidalert").hide();
                $(".modal-backdrop").remove();
              }, 2500);
              this.$router.push({ path: "/signin" });
          } else {
            console.log("kyc")
            console.log(response.data.kycRequired)
            if (response.data.kycRequired == false) {
              this.$storage.setStorageSync(
                "kycRequired",
                "Not Required"
              );
              // console.log(this.$storage.getStorageSync("kycRequired"));
              // if(this.$storage.getStorageSync("kycRequired") == 'false') {
              //   alert("login");
              // }
               
              $("#otpalert").modal("show");
              setTimeout(function () {
                $("#otpalert").hide();
                $(".modal-backdrop").remove();
              }, 2500);
              this.$router.push({ path: "/" });
            } else {
              $("#valert").modal("show");
              setTimeout(function () {
                $("#valert").hide();
                $(".modal-backdrop").remove();
              }, 2500);
                this.$router.push({ path: "/signup" });
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "Server responded with status code:",
              error.response.status
            );
            console.log("Response data:", error.response.data);
          } else if (error.request) {
            console.log("No response received:", error.request);
          } else {
            console.log("Error creating request:", error.message);
          }
        });
    },
  },
  mounted() {
    var myopt = document.querySelectorAll(".otpinput");
    for (var i = 0; i < myopt.length - 1; i++) {
      myopt[i].addEventListener("keyup", function () {
        this.nextElementSibling.focus();
      });
    }

    var myopt2 = document.querySelectorAll(".otpinput2");
    for (var i = 0; i < myopt2.length - 1; i++) {
      myopt2[i].addEventListener("keyup", function () {
        this.nextElementSibling.focus();
      });
    }
  },
};
</script>

<style scoped>
.main {
  overflow-x: hidden;
}
@media (max-width: 1200px) {
  .hero-img {
    max-width: 350px !important;
  }
}
.get-otp {
  background-color: #ffb180 !important;
  color: #fff;
}
.otpinput {
  height: 50px;
}

/* mobile view css */
/* .log-sec {
  background: #ff6602 !important;
}
@media screen and (min-width: 1200px) {
  .mobile-login {
    display: none;
  }
  .hero {
    display: block !important;
  }
} */

a {
  text-decoration: none;
}
@media (max-width: 1200px) {
  .hero-img {
    max-width: 350px !important;
  }
}
/* Web View */
.web {
  background: #f5d8c5;
}
@media (min-width: 1200px) {
  .web .first-sec {
    background-image: url("@/assets/Intersect2.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
  }
}
@media (max-width: 1199.98px) {
  .web .first-sec {
    background-image: url("@/assets/Intersect3.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100vh;
  }
}
.web .heading {
  color: #ffffff;
  font-family: "Satoshi";
  font-weight: 400;
}
.web .btn-primary {
  background: #ff6600;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-primary:hover {
  background: #ff6600;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-outline {
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-outline:hover {
  background: #000;
  border: none;
  color: #fff;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web form {
  background: #fff;
  border-radius: 10px;
}
.web form select:focus {
  box-shadow: none;
  border: 1px solid gray;
}
.web form input:focus {
  box-shadow: none;
  border: 1px solid gray;
}

/* mobile view css */
.figure-shadow {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.log-sec {
  height: 100vh;
}
.mobile-login form {
  background: transparent;
  border-radius: 10px;
  width: 100%;
}
.mobile-login form select:focus {
  box-shadow: none;
  border: 1px solid gray;
}
.mobile-login form input:focus {
  box-shadow: none;
  border: 1px solid gray;
  color: #000;
}
.mobile-login .btn-primary {
  background: #ff6600;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.mobile-login .btn-primary:hover {
  background: #ff6600;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.get-otp {
  background-color: #ff6600 !important;
  color: #fff;
}
.log-sec {
  background: #ff6602 !important;
}
@media screen and (min-width: 1200px) {
  .mobile-login {
    display: none;
  }
  .hero {
    display: block !important;
  }
}
a {
  text-decoration: none;
  color: #000;
}
</style>