<template>
  <div class="main">
    <!-- START_DATE_MODAL -->
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Start Date & Time
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <!-- Date -->
            <div class="mb-4">
              <label for="select-date" class="pb-2">Select Start Date</label>
              <div id="date"></div>
            </div>
            <!-- Date-->
            <!-- Time -->
            <div>
              <div class="mb-0">
                <label for="select-time" class="pb-2">Select Pickup Time</label>
              </div>
              <div class="input-wrapper">
                <input type="text" id="time" value="00:00 AM" readonly hidden />
              </div>
              <div class="row d-flex justify-content-end">
                <div class="picker col-md-4">
                  <div class="swiper-container hours">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" id="hr" value="01:00">
                        01:00
                      </div>
                      <div class="swiper-slide" id="hr" value="01:30">
                        01:30
                      </div>
                      <div class="swiper-slide" id="hr" value="02:00">
                        02:00
                      </div>
                      <div class="swiper-slide" id="hr" value="02:30">
                        02:30
                      </div>
                      <div class="swiper-slide" id="hr" value="03:00">
                        03:00
                      </div>
                      <div class="swiper-slide" id="hr" value="03:30">
                        03:30
                      </div>
                      <div class="swiper-slide" id="hr" value="04:00">
                        04:00
                      </div>
                      <div class="swiper-slide" id="hr" value="04:30">
                        04:30
                      </div>
                      <div class="swiper-slide" id="hr" value="05:00">
                        05:00
                      </div>
                      <div class="swiper-slide" id="hr" value="05:30">
                        05:30
                      </div>
                      <div class="swiper-slide" id="hr" value="06:00">
                        06:00
                      </div>
                      <div class="swiper-slide" id="hr" value="06:30">
                        06:30
                      </div>
                      <div class="swiper-slide" id="hr" value="07:00">
                        07:00
                      </div>
                      <div class="swiper-slide" id="hr" value="07:30">
                        07:30
                      </div>
                      <div class="swiper-slide" id="hr" value="08:00">
                        08:00
                      </div>
                      <div class="swiper-slide" id="hr" value="08:30">
                        08:30
                      </div>
                      <div class="swiper-slide" id="hr" value="09:00">
                        09:00
                      </div>
                      <div class="swiper-slide" id="hr" value="09:30">
                        09:30
                      </div>
                      <div class="swiper-slide" id="hr" value="10:00">
                        10:00
                      </div>
                      <div class="swiper-slide" id="hr" value="10:30">
                        10:30
                      </div>
                      <div class="swiper-slide" id="hr" value="11:00">
                        11:00
                      </div>
                      <div class="swiper-slide" id="hr" value="11:30">
                        11:30
                      </div>
                      <div class="swiper-slide" id="hr" value="12:00">
                        12:00
                      </div>
                      <div class="swiper-slide" id="hr" value="12:30">
                        12:30
                      </div>
                    </div>
                  </div>
                </div>
                <div class="picker col-md-4">
                  <div class="swiper-container seconds">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" id="meridiem" value="AM">
                        AM
                      </div>
                      <div class="swiper-slide" id="meridiem" value="PM">
                        PM
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Time -->

            <div class="d-flex align-items-center justify-content-center py-4">
              <div class="pe-2 w-100">
                <button
                  type="button"
                  class="btn btn-outline-danger w-100"
                  v-on:click="Clear()"
                >
                  Clear
                </button>
              </div>
              <div class="ps-2 w-100">
                <button
                  type="button"
                  class="btn btn-outline-success w-100"
                  v-on:click="Submit()"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- START_DATE_MODAL -->

    <!-- END_DATE_MODAL -->
    <div
      class="modal fade"
      id="staticBackdrop2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdrop2Label"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdrop2Label">
              End Date & Time
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <!-- Date -->
            <div class="mb-4">
              <label for="select-enddate" class="pb-2">Select End Date</label>
              <div id="dateend"></div>
            </div>
            <!-- Date-->
            <!-- Time -->
            <div>
              <div class="mb-0">
                <label for="select-end-time" class="pb-2"
                  >Select Pickup Time</label
                >
              </div>
              <div class="input-wrapper">
                <input
                  type="text"
                  id="input"
                  value="00:00 AM"
                  readonly
                  hidden
                />
              </div>
              <div class="row d-flex justify-content-end">
                <div class="picker col-md-4">
                  <div class="swiper-container hours-end">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" id="hr" value="01:00">
                        01:00
                      </div>
                      <div class="swiper-slide" id="hr" value="01:30">
                        01:30
                      </div>
                      <div class="swiper-slide" id="hr" value="02:00">
                        02:00
                      </div>
                      <div class="swiper-slide" id="hr" value="02:30">
                        02:30
                      </div>
                      <div class="swiper-slide" id="hr" value="03:00">
                        03:00
                      </div>
                      <div class="swiper-slide" id="hr" value="03:30">
                        03:30
                      </div>
                      <div class="swiper-slide" id="hr" value="04:00">
                        04:00
                      </div>
                      <div class="swiper-slide" id="hr" value="04:30">
                        04:30
                      </div>
                      <div class="swiper-slide" id="hr" value="05:00">
                        05:00
                      </div>
                      <div class="swiper-slide" id="hr" value="05:30">
                        05:30
                      </div>
                      <div class="swiper-slide" id="hr" value="06:00">
                        06:00
                      </div>
                      <div class="swiper-slide" id="hr" value="06:30">
                        06:30
                      </div>
                      <div class="swiper-slide" id="hr" value="07:00">
                        07:00
                      </div>
                      <div class="swiper-slide" id="hr" value="07:30">
                        07:30
                      </div>
                      <div class="swiper-slide" id="hr" value="08:00">
                        08:00
                      </div>
                      <div class="swiper-slide" id="hr" value="08:30">
                        08:30
                      </div>
                      <div class="swiper-slide" id="hr" value="09:00">
                        09:00
                      </div>
                      <div class="swiper-slide" id="hr" value="09:30">
                        09:30
                      </div>
                      <div class="swiper-slide" id="hr" value="10:00">
                        10:00
                      </div>
                      <div class="swiper-slide" id="hr" value="10:30">
                        10:30
                      </div>
                      <div class="swiper-slide" id="hr" value="11:00">
                        11:00
                      </div>
                      <div class="swiper-slide" id="hr" value="11:30">
                        11:30
                      </div>
                      <div class="swiper-slide" id="hr" value="12:00">
                        12:00
                      </div>
                      <div class="swiper-slide" id="hr" value="12:30">
                        12:30
                      </div>
                    </div>
                  </div>
                </div>
                <div class="picker col-md-4">
                  <div class="swiper-container seconds-end">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" id="meridiem" value="AM">
                        AM
                      </div>
                      <div class="swiper-slide" id="meridiem" value="PM">
                        PM
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Time -->

            <div class="d-flex align-items-center justify-content-center py-4">
              <div class="pe-2 w-100">
                <button
                  type="button"
                  class="btn btn-outline-danger w-100"
                  v-on:click="ClearEnd()"
                >
                  Clear
                </button>
              </div>
              <div class="ps-2 w-100">
                <button
                  type="button"
                  class="btn btn-outline-success w-100"
                  v-on:click="SubmitEnd()"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END_DATE_MODAL -->

    <!-- Contact Modal -->
    <div
      class="modal fade"
      id="contactmodal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body py-4">
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <div>
                <img src="@/assets/otpalert.png" alt="" class="img-fluid" />
              </div>
              <p class="text-center py-3">
                Your Query Submitted.<br> We will ge back to you soon!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Contact Modal -->

    <!-- Logout Modal -->
    <div
      class="modal fade"
      id="logoutmodal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="container py-2">
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <img src="" alt="" class="img-fluid" />
                <h5>Do you want to logout?</h5>
                <p class="text-center">
                  Confirmation prompt to log out from the current session
                  securely
                </p>
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <div class="pe-2 w-100">
                  <button
                    type="button"
                    class="btn btn-primary w-100"
                    v-on:click="No()"
                  >
                    No
                  </button>
                </div>
                <div class="ps-2 w-100">
                  <button
                    type="button"
                    class="btn btn-outline-primary w-100"
                    v-on:click="Yes()"
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Logout Modal -->

    <!-- Minimum Modal -->
    <div
      class="modal fade"
      id="minmodal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="container py-2">
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <img src="" alt="" class="img-fluid" />
                <p class="text-center">
                  <span class="px-2"
                    >Please ensure bookings are made for a minimum duration of 6
                    hours.</span
                  >
                  <br />
                  Please Select Valid Date And Time!
                </p>
              </div>
              <div class="text-center">
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click="Okay()"
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Minimum Modal -->

    <!-- Wishlist Modal -->
    <div
      class="modal fade"
      id="wishalert"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <div class="pt-3">
                <img src="" alt="" class="img-fluid" />
              </div>
              <p class="text-center py-3">Added to your Wishlist</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Wishlist Modal -->

    <!-- filter modal -->
    <div
      class="modal fade"
      id="filtermodal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Filter</h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <form class="mb-3">
                <p class="mb-1">
                  <span style="color: grey">Transmission Type</span>
                </p>
                <label class="pe-4">
                  <input
                    type="radio"
                    name="rating"
                    value="MOPPED"
                    v-model="transmissionType"
                  />
                  MOPPED
                </label>
                <label class="pe-4">
                  <input
                    type="radio"
                    name="rating"
                    value="GEAR"
                    v-model="transmissionType"
                  />
                  GEAR
                </label>
              </form>
            </div>
            <div class="row">
              <form class="mb-3">
                <p class="mb-1"><span style="color: grey">FuelType</span></p>
                <label class="pe-4">
                  <input
                    type="radio"
                    name="rating"
                    value="PETROL"
                    v-model="fuelType"
                  />
                  Petrol
                </label>
                <label class="pe-4">
                  <input
                    type="radio"
                    name="rating"
                    value="ELECTRIC"
                    v-model="fuelType"
                  />
                  Electric
                </label>
              </form>
            </div>
            <div class="row">
              <form class="mb-3">
                <p class="mb-1"><span style="color: grey">Rating</span></p>
                <label class="pe-4">
                  <input
                    type="radio"
                    name="rating"
                    value="4"
                    v-model="ratings"
                  />
                  4+ Rating
                </label>
                <label class="pe-4">
                  <input
                    type="radio"
                    name="rating"
                    value="3"
                    v-model="ratings"
                  />
                  3+ Rating
                </label>
                <label class="pe-4 mb-4">
                  <input
                    type="radio"
                    name="rating"
                    value="all"
                    v-model="ratings"
                  />All
                </label>
              </form>
            </div>
          </div>
          <div
            class="modal-footer d-flex align-items-center justify-content-evenly"
          >
            <button
              type="button"
              class="btn btn-light btn-lg px-5 ms-1"
              data-dismiss="modal"
              style="color: #011640"
              @click="clearFilter()"
            >
              CLEAR
            </button>
            <button
              type="button"
              class="btn text-light btn-lg px-5"
              style="background-color: #ff6600"
              @click="myFilter()"
            >
              APPLY
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- filter modal  -->

    <!-- VehicleDetails -->
    <div
      class="modal fade"
      id="vehicle"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Bike Details</h5>
            <button
              type="button"
              class="btn-close btn-close-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-6">
                  <div class="d-flex">
                    <!-- <div>
                      <img src="" alt="" />
                    </div> -->
                    <div>
                      <h5>
                        {{ vehicleData.bikeName }} <br>
                        <span style="font-size: 18px;">₹ {{ vehicleData.hourly }}/hr</span>
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    <div class="float-end">
                      <div class="ps-2" @click="addWishlist()">
                        <button
                          type="button"
                          class="btn"
                          style="background: #f3f7fe; border: none;"
                        >
                          <img src="@/assets/like.svg" alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6 d-flex align-items-center justify-content-center">
                  <div>
                    <img
                      v-bind:src="`https://speedrent.in/adminapp/uploads/${vehicleData.bikeImage}`"
                      alt=""
                      style="width: 100%; height: 300px; object-fit: cover"
                    />
                    <!-- <h5 class="text-center pt-2">{{ vehicleData.bikeName }}</h5> -->
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3 mt-4">
                    <h6>Specifications</h6>
                    <div class="row">
                      <div class="col-6">
                        <div class="d-flex">
                          <div>
                            <img src="@/assets/gas-station.png" alt="" class="img-fluid vimg" />
                          </div>
                          <div class="ps-2 pt-1">
                            {{ vehicleData.fuelType }}
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="d-flex">
                          <div>
                            <img src="@/assets/transmission.png" alt="" class="img-fluid vimg vimg1" />
                          </div>
                          <div class="ps-1 pt-1">
                            {{ vehicleData.transmissionType }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-1">
                    <h6>Pickup Location</h6>
                    <div v-if="vehicleData.branch == 'Nayabazar'">
                      <div
                        style="width: 100%; height: 200px; overflow-x: hidden"
                      >
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14952.91918033967!2d85.9144693930148!3d20.455752851608583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a190d48f00c54d1%3A0xa82c4d7434072f80!2sNaya%20Bazaar%2C%20Cuttack%2C%20Odisha!5e0!3m2!1sen!2sin!4v1706605974839!5m2!1sen!2sin"
                          style="border: 0"
                          allowfullscreen=""
                          loading="lazy"
                        ></iframe>
                      </div>
                    </div>
                    <div v-if="vehicleData.branch == 'PATIA'">
                      <div
                        style="width: 100%; height: 200px; overflow-x: hidden"
                      >
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29926.09926597977!2d85.80364213697564!3d20.351431629559332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a190912b69339ab%3A0xa11e7186a04f1474!2sPatia%2C%20Bhubaneswar%2C%20Odisha!5e0!3m2!1sen!2sin!4v1706607123833!5m2!1sen!2sin"
                          style="border: 0"
                          allowfullscreen=""
                          loading="lazy"
                        ></iframe>
                      </div>
                    </div>
                    <div v-if="vehicleData.branch == 'JAGAMARA'">
                      <div
                        style="width: 100%; height: 200px; overflow-x: hidden"
                      >
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14971.775029380748!2d85.78511799293467!3d20.26116717934412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19a799c0bfde1f%3A0x6620519121bc5b58!2sJagamara%2C%20Bhubaneswar%2C%20Odisha!5e0!3m2!1sen!2sin!4v1706607196258!5m2!1sen!2sin"
                          style="border: 0"
                          allowfullscreen=""
                          loading="lazy"
                        ></iframe>
                      </div>
                    </div>
                    <div v-if="vehicleData.branch == 'majestic'">
                      <div
                        style="width: 100%; height: 200px; overflow-x: hidden"
                      >
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7775.828218081205!2d77.56601923949235!3d12.977345204203141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae16052b7ceb8b%3A0xf9db1b29f78b9f96!2sMajestic%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1706607237379!5m2!1sen!2sin"
                          style="border: 0"
                          allowfullscreen=""
                          loading="lazy"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- VehicleDetails -->

    <!-- ConfirmBooking -->
    <div
      class="modal fade"
      id="confirmbooking"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Confirm Booking</h5>
            <button
              type="button"
              class="btn-close btn-close-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="mb-2">
                    <div class="row">
                      <div class="col-6">
                        <h5>{{ confirmbooking.bikeNumber }}</h5>
                      </div>
                      <div class="col-6">
                        <h5 class="float-end">
                          {{ confirmbooking.bookingAmount }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="mb-2">
                    <div class="row">
                      <div class="col-6">
                        <p>
                          {{ confirmbooking.pickupDate }},{{
                            confirmbooking.pickupTime
                          }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="float-end">
                          {{ confirmbooking.dropDate }},{{
                            confirmbooking.dropTime
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mb-2">
                    <div class="row">
                      <div class="col-6">
                        <h6>Pickup Location:</h6>
                      </div>
                      <div class="col-6">
                        <h6 class="float-end">{{ confirmbooking.branch }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <h4 class="pb-3">Billing Details</h4>
                    <div class="row">
                      <div class="col-12">
                        <div class="mb-1">
                          <div class="row">
                            <div class="col-6">
                              <h6>Bike Number :</h6>
                            </div>
                            <div class="col-6">
                              <h6 class="float-end">
                                {{ confirmbooking.bikeNumber }}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="mb-1">
                          <div class="row">
                            <div class="col-6">
                              <h6>Booking ID :</h6>
                            </div>
                            <div class="col-6">
                              <h6 class="float-end">
                                {{ confirmbooking.bookingId }}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="mb-1">
                          <div class="row">
                            <div class="col-6">
                              <h6>Customer Name :</h6>
                            </div>
                            <div class="col-6">
                              <h6 class="float-end">
                                {{ confirmbooking.customerName }}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="mb-1">
                          <div class="row">
                            <div class="col-6">
                              <h6>Customer Mobile Number :</h6>
                            </div>
                            <div class="col-6">
                              <h6 class="float-end">
                                {{ confirmbooking.cutomerMobile }}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="mb-1">
                          <div class="row">
                            <div class="col-6">
                              <h6>Booking Amount :</h6>
                            </div>
                            <div class="col-6">
                              <h6 class="float-end">
                                {{ confirmbooking.bookingAmount }}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="mb-4">
                          <div class="row">
                            <div class="col-6">
                              <h6>Discount Amount :</h6>
                            </div>
                            <div class="col-6">
                              <h6 class="float-end">
                                {{ confirmbooking.discountAmount }}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="mb-2">
                          <div class="row">
                            <div class="col-6">
                              <h4>Total Amount :</h4>
                            </div>
                            <div class="col-6">
                              <h4 class="float-end">
                                {{ confirmbooking.bookingAmount }}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div class="w-100">
                          <button
                            type="button"
                            class="btn btn-primary w-100"
                            v-on:click="booknow()"
                          >
                            Pay Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ConfirmBooking -->

    <!-- Profile Edit -->
    <div
      class="modal fade profile"
      id="profilemodal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">My Profile</h5>
            <button
              type="button"
              class="btn-close btn-close-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="content pb-2">
              <form class="p-4" v-on:submit.prevent="onEditPro()" >
                    <div class="mb-2">
                      <label for="" class="pb-2">Profile Picture</label>
                      <div class="pb-2 pb-xxl-2">
                        <label for="input-file" id="drop-area">
                          <input
                            type="file"
                            accept="image/*"
                            id="input-file"
                            @change="preview"
                            hidden
                          />
                          <div id="img-view">
                            <div v-if="selfie">
                              <img class="img-fluid imgs" :src="selfie" />
                            </div>
                            <div v-else>
                              <img
                                v-bind:src="`https://speedrent.in/adminapp/uploads/${userDetails.number}.jpg`"
                                alt=""
                                class="img-fluid imgs"
                              />
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="name" class="form-label">Name</label>
                      <input
                        type="text"
                        v-model="userDetails.name"
                        class="form-control"
                        placeholder="Enter your full name"
                        id="name"
                        required
                      />
                    </div>
                    <div class="sub mb-4">
                      Mobile Number:
                      <div class="d-flex pt-2">
                        <div class="col-auto pe-2">
                          <select class="form-select" id="autoSizingSelect" disabled>
                            <option selected>+91</option>
                            <option value="1">+44</option>
                            <option value="2">+66</option>
                            <option value="3">+88</option>
                          </select>
                        </div>
                        <div class="w-100">
                          <input
                            type="text"
                            v-model="userDetails.number"
                            class="form-control"
                            id="autoSizingInput"
                            placeholder="Mobile Number"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="cMail" class="form-label"
                        >Email Address:</label
                      >
                      <input
                        type="email"
                        v-model="userDetails.email"
                        class="form-control"
                        id="cMail"
                        placeholder="Enter your email address"
                      />
                    </div>
                    <div class="text-center">
                      <button
                        type="submit"
                        class="btn btn-primary register-btn w-100"
                      >
                        Edit Profile
                      </button>
                    </div>
                  </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Profile Edit -->

    <div class="container-fluid">
      <div class="container-fluid">
        <nav
          class="navbar navbar-expand-md navbar-light bg-light fixed-top d-none d-md-block bg-info"
          v-if="
            $route.path !== '/login' &&
            $route.path !== '/signin' &&
            $route.path !== '/otp-verification' &&
            $route.path !== '/KYC-1' &&
            $route.path !== '/KYC-2'
          "
          style="border: 0.1px solid #f1ecec"
        >
          <div class="d-flex align-items-center">
            <a class="navbar-brand ps-lg-5" href="/">
              <img
                src="@/assets/speedrentlogo.png"
                alt=""
                class="logo img-fluid"
              />
            </a>
            <button
              class="navbar-toggler shadow-none border-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="offcanvas offcanvas-start"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                  Features
                </h5>
                <button
                  type="button"
                  class="btn-close shadow-none"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                <ul class="navbar-nav justify-content-center flex-grow-1">
                  <li class="nav-item mx-xl-3 mx-md-2">
                    <a href="#blogs" class="nav-link">Blogs</a>
                  </li>
                  <!-- <li class="nav-item mx-xl-3 mx-md-2">
                    <a href="" class="nav-link">Testimonials</a>
                  </li> -->
                  <li class="nav-item mx-xl-3 mx-md-2">
                    <a href="#FAQs" class="nav-link">FAQ's</a>
                  </li>
                  <li class="nav-item mx-xl-3 mx-md-2">
                    <a href="#contact-us" class="nav-link">Contact Us</a>
                  </li>
                  <li>
                    <div
                      v-if="
                        this.$storage.getStorageSync('message') == 'verified'
                      "
                    >
                      <div class="d">
                        <a href="/" class="nav-btn">
                          <div class="download">
                            <button
                              type="button"
                              class="btn btn-download btn-txt"
                            >
                              Download App
                            </button>
                          </div></a
                        >
                      </div>
                    </div>
                    <div v-else>
                      <div class="d">
                          <div class="download">
                            <button
                              type="button"
                              class="btn btn-download btn-txt"
                              style="border: none;"
                            >
                            <a href="/signin" class="nav-btn text-white">
                              Login
                            </a><span class="text-white">/</span>
                            <a href="/signin" class="nav-btn text-white">
                              SignUp
                            </a>
                            </button>
                          </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="d-lg-flex">
                  <div class="sec-nav">
                    <div>
                      <button
                        class="btn"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#right"
                        aria-controls="offcanvasRight"
                        style="border: none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-list"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <!-- Web-Toggle-Button -->
        <div class="d-none d-md-block">
          <div
            class="offcanvas offcanvas-end"
            tabindex="-1"
            id="right"
            aria-labelledby="offcanvasRightLabel"
          >
            <div class="offcanvas-header">
              <img src="@/assets/speedrentlogo 1.png" alt="" />
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body">
              <div class="row">
                <div class="col 12">
                  <div class="bookfont">
                    <div class="row gap-0 border-bottom">
                      <div class="col-2">
                        <div
                          v-if="
                            this.$storage.getStorageSync('message') ==
                            'verified'
                          "
                        >
                        <img
                          v-bind:src="`https://speedrent.in/adminapp/uploads/${userDetails.number}.jpg`"
                          class="img-fluid"
                          style="height: 56px; width: 56px; object-fit:cover;"
                        />
                        </div>
                        <div v-else>
                          <div class="pt-1">
                            <img src="@/assets/Profile.png" alt="" class="">
                          </div>
                        </div>
                      </div>
                      <div class="col-7 text-start pt-2">
                        <div
                          v-if="
                            this.$storage.getStorageSync('message') ==
                            'verified'
                          "
                        >
                          <h6 class="m-0">{{ userDetails.name }}</h6>
                          <p style="color: #ff6600">
                            +91 {{ this.$storage.getStorageSync("pnumber") }}
                          </p>
                        </div>
                        <div v-else>
                          <h6 class="m-0">Welcome</h6>
                          <p style="color: #ff6600">Please Login..</p>
                        </div>
                      </div>
                      <div class="col-3 pt-2">
                        <div
                          v-if="
                            this.$storage.getStorageSync('message') ==
                            'verified'
                          "
                        >
                          <div v-on:click="editProfile()">
                            <div
                              class="rounded px-1 py-1"
                              style="color: white; background: #ff6600"
                            >
                              <div class="d-flex">
                                <div class="">
                                  <img src="@/assets/pen-circle 1.svg" alt="" />
                                </div>
                                <div class="ps-1 pt-1">EDIT</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <div
                        v-if="
                          this.$storage.getStorageSync('message') == 'verified'
                        "
                      >
                        <a href="/rides">
                          <p>
                            <img src="@/assets/book1.png" alt="" />
                            <span class="ps-1"> My Bookings</span>
                          </p>
                        </a>
                      </div>
                      <div v-else>
                        <a href="/signin">
                          <p>
                            <img src="@/assets/book1.png" alt="" />
                            <span class="ps-1"> My Bookings</span>
                          </p>
                        </a>
                      </div>
                    </div>
                    <div>
                      <div
                        v-if="
                          this.$storage.getStorageSync('message') == 'verified'
                        "
                      >
                        <a href="/wishlist">
                          <p>
                            <img src="@/assets/heart.png" alt="" />
                            <span class="ps-1"> Wishlist</span>
                          </p>
                        </a>
                      </div>
                      <div v-else>
                        <a href="/signin">
                          <p>
                            <img src="@/assets/heart.png" alt="" />
                            <span class="ps-1"> Wishlist</span>
                          </p>
                        </a>
                      </div>
                    </div>
                    <div
                      v-if="
                        this.$storage.getStorageSync('message') == 'verified'
                      "
                    >
                      <div v-if="this.kycDone === 'true'">
                        <p>
                          <img src="@/assets/kycimage.png" alt="" />
                          <span class="ps-2 pe-2">KYC</span>
                          <label
                            style="
                              background-color: #00b950;
                              color: white;
                              border-radius: 5px;
                            "
                            class="br-0 px-1"
                          >
                            Verfication Success
                          </label>
                        </p>
                      </div>
                      <div v-else>
                        <div
                          v-if="
                            this.$storage.getStorageSync('register') ==
                            'Customer added successfully..!'
                          "
                        >
                          <a href="/KYC-1">
                            <p>
                              <img src="@/assets/kycimage.png" alt="" />
                              <span class="ps-2 pe-2">KYC</span>
                              <label
                                style="
                                  background-color: #fea53f;
                                  color: white;
                                  border-radius: 5px;
                                "
                                class="br-0 px-1"
                              >
                                Verfication Pending
                              </label>
                            </p>
                          </a>
                        </div>

                        <div v-else>
                          <a href="/signup">
                            <p>
                              <img src="@/assets/kycimage.png" alt="" />
                              <span class="ps-2 pe-2">KYC</span>
                              <label
                                style="
                                  background-color: #fea53f;
                                  color: white;
                                  border-radius: 5px;
                                "
                                class="br-0 px-1"
                              >
                                Verfication Pending
                              </label>
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <a href="/signin">
                        <p>
                          <img src="@/assets/kycimage.png" alt="" />
                          <span class="ps-2 pe-2">KYC</span>
                          <label
                            style="
                              background-color: #fea53f;
                              color: white;
                              border-radius: 5px;
                            "
                            class="br-0 px-1"
                          >
                            Verfication Pending
                          </label>
                        </p>
                      </a>
                    </div>
                    <div>
                      <a href="/terms&conditions">
                        <p>
                          <img src="@/assets/policesimage.png" alt="" />
                          <span class="ps-2">Terms & Conditions</span>
                        </p>
                      </a>
                    </div>
                    <div>
                      <a href="/privacy-policy">
                        <p>
                          <img src="@/assets/policesimage.png" alt="" />
                          <span class="ps-2">Privacy Policy</span>
                        </p>
                      </a>
                    </div>
                    <div>
                      <a href="/refund&cancellations">
                        <p>
                          <img src="@/assets/policesimage.png" alt="" />
                          <span class="ps-2">Refund & Cancellations</span>
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="offcanvas-footer p-3">
              <div
                class="logbutton"
                v-if="this.$storage.getStorageSync('message') == 'verified'"
              >
                <button
                  @click.prevent="Logout()"
                  class="btn btn-primary w-100 b-0"
                  type="button"
                  style="background: #f3fbfe; color: #1291ee"
                >
                  <span class="float-start">Logout</span>
                  <span class="float-end">
                    <img
                      src="@/assets/form-logout.png"
                      alt=""
                      class="img-fluid"
                    />
                  </span>
                </button>
              </div>
              <div v-else>
                <a href="/signin">
                  <button
                    class="btn btn-primary w-100 b-0"
                    type="button"
                    style="background: #ff6600; color: #fff; border: none"
                  >
                    <span class="text-center">Login</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- Web-Toggle-Button -->
        <!-- Mobile-Toggle-Button -->
        <div class="nav2 pt-3 d-md-none fixed-top navbar-light bg-light">
          <div class="container-fluid">
            <div class="row">
              <div class="col-10">
                <div v-if="$route.path == '/bookings'">
                  <div class="d-flex">
                    <div>
                      <a href="/">
                        <div style="background: #fff; border: none">
                          <img
                            src="@/assets/wishlist/wishlistarrow.svg"
                            alt=""
                            class="img-fluid"
                          />
                        </div>
                      </a>
                    </div>
                    <div class="ps-1">
                      <select
                        class="form-select"
                        v-model="branch"
                        aria-label="Default select example"
                        style="border: 2px solid #ff6600; border-radius: 5px"
                      >
                        <option value="majestic" selected>
                          Bengaluru - Majestic
                        </option>
                        <option value="nayabazar">Cuttack - Naya bazar</option>
                        <option value="PATIA">Bhubaneshwar - Patia</option>
                        <option value="JAGAMARA">
                          Bhubaneshwar - Jagamara
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <a class="navbar-brand ps-lg-5" href="/">
                    <img
                      src="@/assets/speedrentlogo.png"
                      alt=""
                      class="logo img-fluid pb-2"
                      style="width: 56px !important"
                    />
                  </a>
                </div>
              </div>
              <div class="col-2">
                <div class="float-end">
                  <div class="sec-nav pe-md-5">
                    <div>
                      <button
                        class="btn"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                        style="border: none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-list"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                          />
                        </svg>
                      </button>

                      <div
                        class="offcanvas offcanvas-start"
                        tabindex="-1"
                        id="offcanvasRight"
                        aria-labelledby="offcanvasRightLabel"
                      >
                        <div class="offcanvas-header">
                          <img src="@/assets/speedrentlogo 1.png" alt="" />
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="offcanvas-body">
                          <div class="row">
                            <div class="col 12">
                              <div class="bookfont">
                                <div class="row gap-0 border-bottom">
                                  <div class="col-2">
                                    <div
                          v-if="
                            this.$storage.getStorageSync('message') ==
                            'verified'
                          "
                        >
                        <img
                          v-bind:src="`https://speedrent.in/adminapp/uploads/${userDetails.number}.jpg`"
                          class="img-fluid"
                          style="height: 56px; width: 56px; object-fit:cover;"
                        />
                        </div>
                        <div v-else>
                          <div class="pt-1">
                            <img src="@/assets/Profile.png" alt="" class="">
                          </div>
                        </div>
                                  </div>
                                  <div class="col-7 text-start pt-2">
                                    <div
                                      v-if="
                                        this.$storage.getStorageSync(
                                          'message'
                                        ) == 'verified'
                                      "
                                    >
                                      <h6 class="m-0">
                                        {{ userDetails.name }}
                                      </h6>
                                      <p style="color: #ff6600">
                                        +91
                                        {{
                                          this.$storage.getStorageSync(
                                            "pnumber"
                                          )
                                        }}
                                      </p>
                                    </div>
                                    <div v-else>
                                      <h6 class="m-0">Welcome</h6>
                                      <p style="color: #ff6600">
                                        Please Login..
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-3 pt-2">
                                    <div
                                      v-if="
                                        this.$storage.getStorageSync(
                                          'message'
                                        ) == 'verified'
                                      "
                                    >
                                      <div v-on:click="editProfile()">
                                        <div
                                          class="rounded px-1 py-1"
                                          style="
                                            color: white;
                                            background: #ff6600;
                                          "
                                        >
                                          <div class="d-flex">
                                            <div class="">
                                              <img
                                                src="@/assets/pen-circle 1.svg"
                                                alt=""
                                              />
                                            </div>
                                            <div class="ps-1 pt-1">EDIT</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="mt-4">
                                  <div
                                    v-if="
                                      this.$storage.getStorageSync('message') ==
                                      'verified'
                                    "
                                  >
                                    <a href="/rides">
                                      <p>
                                        <img src="@/assets/book1.png" alt="" />
                                        <span class="ps-1"> My Bookings</span>
                                      </p>
                                    </a>
                                  </div>
                                  <div v-else>
                                    <a href="/signin">
                                      <p>
                                        <img src="@/assets/book1.png" alt="" />
                                        <span class="ps-1"> My Bookings</span>
                                      </p>
                                    </a>
                                  </div>
                                </div>
                                <div>
                                  <div
                                    v-if="
                                      this.$storage.getStorageSync('message') ==
                                      'verified'
                                    "
                                  >
                                    <a href="/wishlist">
                                      <p>
                                        <img src="@/assets/heart.png" alt="" />
                                        <span class="ps-1"> Wishlist</span>
                                      </p>
                                    </a>
                                  </div>
                                  <div v-else>
                                    <a href="/signin">
                                      <p>
                                        <img src="@/assets/heart.png" alt="" />
                                        <span class="ps-1"> Wishlist</span>
                                      </p>
                                    </a>
                                  </div>
                                </div>

                                <div
                                  v-if="
                                    this.$storage.getStorageSync('message') ==
                                    'verified'
                                  "
                                >
                                  <div v-if="this.kycDone === 'true'">
                                    <p>
                                      <img src="@/assets/kycimage.png" alt="" />
                                      <span class="ps-2 pe-2">KYC</span>
                                      <label
                                        style="
                                          background-color: #00b950;
                                          color: white;
                                          border-radius: 5px;
                                        "
                                        class="br-0 px-1"
                                      >
                                        Verfication Success
                                      </label>
                                    </p>
                                  </div>
                                  <div v-else>
                                    <div
                                      v-if="
                                        this.$storage.getStorageSync(
                                          'register'
                                        ) == 'Customer added successfully..!'
                                      "
                                    >
                                      <a href="/KYC-1">
                                        <p>
                                          <img
                                            src="@/assets/kycimage.png"
                                            alt=""
                                          />
                                          <span class="ps-2 pe-2">KYC</span>
                                          <label
                                            style="
                                              background-color: #fea53f;
                                              color: white;
                                              border-radius: 5px;
                                            "
                                            class="br-0 px-1"
                                          >
                                            Verfication Pending
                                          </label>
                                        </p>
                                      </a>
                                    </div>

                                    <div v-else>
                                      <a href="/signup">
                                        <p>
                                          <img
                                            src="@/assets/kycimage.png"
                                            alt=""
                                          />
                                          <span class="ps-2 pe-2">KYC</span>
                                          <label
                                            style="
                                              background-color: #fea53f;
                                              color: white;
                                              border-radius: 5px;
                                            "
                                            class="br-0 px-1"
                                          >
                                            Verfication Pending
                                          </label>
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div v-else>
                                  <a href="/signin">
                                    <p>
                                      <img src="@/assets/kycimage.png" alt="" />
                                      <span class="ps-2 pe-2">KYC</span>
                                      <label
                                        style="
                                          background-color: #fea53f;
                                          color: white;
                                          border-radius: 5px;
                                        "
                                        class="br-0 px-1"
                                      >
                                        Verfication Pending
                                      </label>
                                    </p>
                                  </a>
                                </div>
                                <div>
                                  <a href="/terms&conditions">
                                    <p>
                                      <img
                                        src="@/assets/policesimage.png"
                                        alt=""
                                      />
                                      <span class="ps-2"
                                        >Terms & Conditions</span
                                      >
                                    </p>
                                  </a>
                                </div>
                                <div>
                                  <a href="/privacy-policy">
                                    <p>
                                      <img
                                        src="@/assets/policesimage.png"
                                        alt=""
                                      />
                                      <span class="ps-2">Privacy Policy</span>
                                    </p>
                                  </a>
                                </div>
                                <div>
                                  <a href="/refund&cancellations">
                                    <p>
                                      <img
                                        src="@/assets/policesimage.png"
                                        alt=""
                                      />
                                      <span class="ps-2"
                                        >Refund & Cancellations</span
                                      >
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="offcanvas-footer p-3">
                          <div
                            class="logbutton"
                            v-if="
                              this.$storage.getStorageSync('message') ==
                              'verified'
                            "
                          >
                            <button
                              @click.prevent="Logout()"
                              class="btn btn-primary w-100 b-0"
                              type="button"
                              style="background: #f3fbfe; color: #1291ee"
                            >
                              <span class="float-start">Logout</span>
                              <span class="float-end">
                                <img
                                  src="@/assets/form-logout.png"
                                  alt=""
                                  class="img-fluid"
                                />
                              </span>
                            </button>
                          </div>
                          <div v-else>
                            <a href="/signin">
                              <button
                                class="btn btn-primary w-100 b-0"
                                type="button"
                                style="
                                  background: #ff6600;
                                  color: #fff;
                                  border: none;
                                "
                              >
                                <span class="text-center">Login</span>
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Mobile-Toggle-Button -->
      </div>

      <!-- HomeScreen-WebView -->
      <div class="d-none d-md-block">
        <div class="row">
          <!-- HomeScreen-First-Section -->
          <div
            class="col-xxl-8 col-xl-7 col-lg-6 col-md-6 p-0"
            style="border: 0.1px solid #f1ecec"
          >
            <!-- hero-section -->
            <section class="hero py-5 px-md-5">
              <div class="container py-md-5">
                <div class="py-xxl-0 py-md-5">
                  <div class="row flex-wrap-reverse">
                    <div
                      class="col-lg-6 d-flex align-items-md-center justify-content-md-start align-items-center justify-content-center"
                    >
                      <div>
                        <h1 class="heading text-md-start text-center">
                          <span>#1</span><br />Bike Rental Services in Odisha
                        </h1>
                        <p class="header-text text-md-start text-center">
                          Your Ultimate Solution for Bike and Scooter Rentals in
                          Odisha – Explore Cities with Ease!
                        </p>
                        <div class="d-xl-flex flex-xl-row">
                          <div>
                            <button
                            type="button"
                            class="btn btn-primary text-uppercase"
                          >
                            Download App
                          </button>
                          </div>
                          <div>
                            <a href="tel:+919124046510"><button
                            type="button"
                            class="btn btn-primary mx-1 my-xl-0 my-2"
                            style="background: #011640"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-telephone-fill"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                              />
                            </svg>
                          </button></a>
                          <a href="https://wa.me/9124046510" target="blank"><button
                            type="button"
                            class="btn btn-primary text-uppercase"
                            style="background: #01b951"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-whatsapp"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"
                              />
                            </svg>
                          </button></a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-lg-6 d-flex align-items-center justify-content-center"
                    >
                      <div>
                        <img
                          src="@/assets/hero-img.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- end of hero-section -->

            <!-- section-1 -->
            <section class="discover py-5 px-md-5">
              <div class="container">
                <div class="row flex-wrap-reverse">
                  <div
                    class="col-lg-6 d-flex align-items-md-center justify-content-md-start align-items-center justify-content-center"
                  >
                    <div>
                      <div class="container-fluid">
                        <div class="d-flex gap-3 mb-3">
                          <div class="d-flex flex-column">
                            <div>
                              <img
                                src="@/assets/Bhubaneswar.png"
                                alt=""
                                class="img-fluid"
                              />
                            </div>
                            <div class="py-1 img-text">
                              <img
                                src="@/assets/location-arrow.png"
                                alt=""
                                class="img-fluid"
                              />
                              Bhubaneshwar
                            </div>
                          </div>
                          <div class="d-flex flex-column">
                            <div>
                              <img
                                src="@/assets/Cuttack.png"
                                alt=""
                                class="img-fluid"
                              />
                            </div>
                            <div class="py-1 img-text">
                              <img
                                src="@/assets/location-arrow.png"
                                alt=""
                                class="img-fluid"
                              />
                              Cuttack
                            </div>
                          </div>
                        </div>
                        <div class="d-flex gap-3">
                          <div class="d-flex flex-column">
                            <div>
                              <img
                                src="@/assets/Bangalore.png"
                                alt=""
                                class="img-fluid"
                              />
                            </div>
                            <div class="py-1 img-text">
                              <img
                                src="@/assets/location-arrow.png"
                                alt=""
                                class="img-fluid"
                              />
                              Bangalore
                            </div>
                          </div>
                          <div>
                            <img
                              src="@/assets/More.png"
                              alt=""
                              class="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 d-flex align-items-md-center justify-content-md-start align-items-center justify-content-center mb-md-0 mb-5"
                  >
                    <div>
                      <div>
                        <img
                          src="@/assets/quote.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div>
                        <h1 class="discover-text pt-1">
                          Discover Rental Freedom Across Numerous Cities!
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- end of section-1 -->

            <!-- section-2 -->
            <div id="blogs">
              <section class="services py-5 px-md-5">
                <div class="container">
                  <div class="pb-5">
                    <h5 class="text-center sub-heading">Know Why We are</h5>
                    <h1 class="heading text-center">
                      Best Bike Rental Services
                    </h1>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="row">
                      <div class="col-lg-6 col-xxl-4 mb-3">
                        <div class="card p-3">
                          <div class="d-flex">
                            <div
                              class="d-flex align-items-center justify-content-center icons"
                            >
                              <img
                                src="@/assets/location.png"
                                alt=""
                                class="img-fluid service-img"
                              />
                            </div>
                            <div>
                              <h5 class="card-title ps-2 pt-1">Access</h5>
                            </div>
                          </div>
                          <div class="card-text pt-2">
                            You can access Speed Rent Bike Rental Services
                            branches all over Odisha. Our broad-based network
                            will provide you with the comfort to hire a bike
                            near you anytime.
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-xxl-4 mb-3">
                        <div class="card p-3">
                          <div class="d-flex">
                            <div
                              class="d-flex align-items-center justify-content-center icons"
                            >
                              <img
                                src="@/assets/shield.png"
                                alt=""
                                class="img-fluid service-img"
                              />
                            </div>
                            <div>
                              <h5 class="card-title ps-2 pt-1">Safety</h5>
                            </div>
                          </div>
                          <div class="card-text pt-2">
                            Speed Rent gives the utmost priority to its
                            customers’ safety. Our bikes and scooters are
                            regularly serviced and mandatory mechanical checkups
                            will be carried out before renting the bike.
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-xxl-4 mb-3">
                        <div class="card p-3">
                          <div class="d-flex">
                            <div
                              class="d-flex align-items-center justify-content-center icons"
                            >
                              <img
                                src="@/assets/credit-card.png"
                                alt=""
                                class="img-fluid service-img"
                              />
                            </div>
                            <div>
                              <h5 class="card-title ps-2 pt-1">Payments</h5>
                            </div>
                          </div>
                          <div class="card-text pt-2">
                            With Speed Rent Bike Rental Services you will get
                            Flexible payment methods so that you will have a
                            smoother and hassle-free booking and payment
                            experience. You can Pay through UPI, Cards, Net
                            Banking or Cash.
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-xxl-4 mb-3">
                        <div class="card p-3">
                          <div class="d-flex">
                            <div
                              class="d-flex align-items-center justify-content-center icons"
                            >
                              <img
                                src="@/assets/award.png"
                                alt=""
                                class="img-fluid service-img"
                              />
                            </div>
                            <div>
                              <h5 class="card-title ps-2 pt-1">Quality</h5>
                            </div>
                          </div>
                          <div class="card-text pt-2">
                            At Speed Rent, we prioritize top-notch quality in
                            bikes and services, ensuring each ride is in
                            excellent condition and thoroughly sanitized.
                            Experience unparalleled value for your investment
                            with our commitment to service excellence.
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-xxl-4 mb-3">
                        <div class="card p-3">
                          <div class="d-flex">
                            <div
                              class="d-flex align-items-center justify-content-center icons"
                            >
                              <img
                                src="@/assets/user-headset.png"
                                alt=""
                                class="img-fluid service-img"
                              />
                            </div>
                            <div>
                              <h5 class="card-title ps-2 pt-1">
                                Customer Support
                              </h5>
                            </div>
                          </div>
                          <div class="card-text pt-2">
                            Count on our dedicated team, available 24/7, for
                            seamless support from booking to ride completion.
                            Whether by call or WhatsApp, we're here to assist
                            you at every step. Your satisfaction is our
                            immediate priority.
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-xxl-4 mb-3">
                        <div class="card p-3">
                          <div class="d-flex">
                            <div
                              class="d-flex align-items-center justify-content-center icons"
                            >
                              <img
                                src="@/assets/tags.png"
                                alt=""
                                class="img-fluid service-img"
                              />
                            </div>
                            <div>
                              <h5 class="card-title ps-2 pt-1">
                                Affordable Prices
                              </h5>
                            </div>
                          </div>
                          <div class="card-text pt-2">
                            Best Quality, Best Customer Support, and here comes
                            the Low cost Bike Rental services. Speed Rent
                            provides bikes on rent at the most competitive and
                            lowest prices across the industry.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <!-- end of section-2 -->

            <!-- section-3 -->
            <!-- <section class="reviews py-5 px-md-5">
              <div class="container">
                <div class="pb-5">
                  <h4 class="heading text-md-start text-center">
                    Real Experiences, Real Results
                  </h4>
                  <h6 class="sub-heading text-md-start text-center">
                    Lorem ipsum dolor sit amet consectetur. Enim ut eget integer
                    tellus ullamcorper mauris eleifend.
                  </h6>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <div class="row">
                    <div class="col-md-4 mb-4">
                      <div class="card p-3">
                        <div class="d-flex">
                          <div>
                            <img
                              src="@/assets/user.png"
                              alt=""
                              class="img-fluid"
                            />
                          </div>
                          <div class="card-title ps-2 pt-2">
                            <h6 class="card-title">
                              User Name Goes Here<br />
                              <span>
                                <ul class="d-flex list-unstyled pt-1">
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                </ul>
                              </span>
                            </h6>
                          </div>
                        </div>
                        <div class="card-text p-0">
                          Lorem ipsum dolor sit amet consectetur. Quam interdum
                          risus vitae vulputate. Adipiscing aliquam lacus amet
                          sit malesuada. A porttitor consectetur egestas
                          hendrerit. Purus turpis lorem lacus vitae in ultrices
                          in at. Morbi turpis tristique ut pellentesque eu
                          venenatis augue.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-4">
                      <div class="card p-3">
                        <div class="d-flex">
                          <div>
                            <img
                              src="@/assets/user.png"
                              alt=""
                              class="img-fluid"
                            />
                          </div>
                          <div class="card-title ps-2 pt-2">
                            <h6 class="card-title">
                              User Name Goes Here<br />
                              <span>
                                <ul class="d-flex list-unstyled pt-1">
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                </ul>
                              </span>
                            </h6>
                          </div>
                        </div>
                        <div class="card-text p-0">
                          Lorem ipsum dolor sit amet consectetur. Quam interdum
                          risus vitae vulputate. Adipiscing aliquam lacus amet
                          sit malesuada. A porttitor consectetur egestas
                          hendrerit. Purus turpis lorem lacus vitae in ultrices
                          in at. Morbi turpis tristique ut pellentesque eu
                          venenatis augue.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-4">
                      <div class="card p-3">
                        <div class="d-flex">
                          <div>
                            <img
                              src="@/assets/user.png"
                              alt=""
                              class="img-fluid"
                            />
                          </div>
                          <div class="card-title ps-2 pt-2">
                            <h6 class="card-title">
                              User Name Goes Here<br />
                              <span>
                                <ul class="d-flex list-unstyled pt-1">
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                </ul>
                              </span>
                            </h6>
                          </div>
                        </div>
                        <div class="card-text p-0">
                          Lorem ipsum dolor sit amet consectetur. Quam interdum
                          risus vitae vulputate. Adipiscing aliquam lacus amet
                          sit malesuada. A porttitor consectetur egestas
                          hendrerit. Purus turpis lorem lacus vitae in ultrices
                          in at. Morbi turpis tristique ut pellentesque eu
                          venenatis augue.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-4">
                      <div class="card p-3">
                        <div class="d-flex">
                          <div>
                            <img
                              src="@/assets/user.png"
                              alt=""
                              class="img-fluid"
                            />
                          </div>
                          <div class="card-title ps-2 pt-2">
                            <h6 class="card-title">
                              User Name Goes Here<br />
                              <span>
                                <ul class="d-flex list-unstyled pt-1">
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                </ul>
                              </span>
                            </h6>
                          </div>
                        </div>
                        <div class="card-text p-0">
                          Lorem ipsum dolor sit amet consectetur. Quam interdum
                          risus vitae vulputate. Adipiscing aliquam lacus amet
                          sit malesuada. A porttitor consectetur egestas
                          hendrerit. Purus turpis lorem lacus vitae in ultrices
                          in at. Morbi turpis tristique ut pellentesque eu
                          venenatis augue.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-4">
                      <div class="card p-3">
                        <div class="d-flex">
                          <div>
                            <img
                              src="@/assets/user.png"
                              alt=""
                              class="img-fluid"
                            />
                          </div>
                          <div class="card-title ps-2 pt-2">
                            <h6 class="card-title">
                              User Name Goes Here<br />
                              <span>
                                <ul class="d-flex list-unstyled pt-1">
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                </ul>
                              </span>
                            </h6>
                          </div>
                        </div>
                        <div class="card-text p-0">
                          Lorem ipsum dolor sit amet consectetur. Quam interdum
                          risus vitae vulputate. Adipiscing aliquam lacus amet
                          sit malesuada. A porttitor consectetur egestas
                          hendrerit. Purus turpis lorem lacus vitae in ultrices
                          in at. Morbi turpis tristique ut pellentesque eu
                          venenatis augue.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-4">
                      <div class="card p-3">
                        <div class="d-flex">
                          <div>
                            <img
                              src="@/assets/user.png"
                              alt=""
                              class="img-fluid"
                            />
                          </div>
                          <div class="card-title ps-2 pt-2">
                            <h6 class="card-title">
                              User Name Goes Here<br />
                              <span>
                                <ul class="d-flex list-unstyled pt-1">
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                  <li class="pe-1">
                                    <i class="fa-solid fa-star"></i>
                                  </li>
                                </ul>
                              </span>
                            </h6>
                          </div>
                        </div>
                        <div class="card-text p-0">
                          Lorem ipsum dolor sit amet consectetur. Quam interdum
                          risus vitae vulputate. Adipiscing aliquam lacus amet
                          sit malesuada. A porttitor consectetur egestas
                          hendrerit. Purus turpis lorem lacus vitae in ultrices
                          in at. Morbi turpis tristique ut pellentesque eu
                          venenatis augue.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> -->
            <!-- end of section-3 -->

            <!-- section-4 -->
            <div id="FAQs">
              <section class="faq-section pb-5 px-lg-5">
                <div class="design mt-5">
                  <div class="container px-xxl-5 mt-5">
                    <h3 class="faq-heading text-center">
                      Have Questions? We've got answers!
                    </h3>
                    <h6 class="faq-subheading text-center mb-3 px-md-5">
                      Explore our comprehensive list of frequently asked questions to find the information you need quickly and easily.
                    </h6>

                    <div class="faq-wrapper px-lg-5 px-md-4 px-sm-2 mt-md-5">
                      <div
                        v-for="(faq, index) in faqs"
                        :key="index"
                        class="faq-box"
                        style="border-bottom: 1px solid #e5e4e2"
                      >
                        <div
                          class="question d-flex justify-content-between align-items-center text-left"
                          @click="toggleCollapse(index)"
                          :data-bs-toggle="`collapse-${index}`"
                          :data-bs-target="`#faq-${index}`"
                          :aria-expanded="faq.expanded"
                          style="font-size: 18px; font-weight: bold"
                        >
                          <p
                            class="text-left pb-1 fs-5 fw-lighter pt-4 faq-question"
                          >
                            {{ faq.question }}
                          </p>
                          <i v-if="!faq.expanded" class="far fa-plus"></i>
                          <i v-else class="fas fa-times"></i>
                        </div>
                        <div
                          :id="`faq-${index}`"
                          class="collapse"
                          :class="{ show: faq.expanded }"
                        >
                          <p
                            class="faq-answer text-start text-break"
                            style="color: #8f9daa; font-family: Poppins"
                          >
                            {{ faq.answer }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <!-- end of section-4 -->

            <!-- section-5 -->
            <div id="contact-us">
              <section class="contact py-5 px-xl-5">
                <div class="container">
                  <h4 class="px-md-5 heading text-md-start text-center">
                    Have Questions? Contact Us Here
                  </h4>
                  <div class="row flex-wrap-reverse">
                    <div
                      class="col-xxl-6 d-flex justify-content-md-start justify-content-center align-items-center"
                    >
                      <!-- Form -->
                      <form
                        class="px-md-5"
                        v-on:submit.prevent="onContact()"
                        id="myForms"
                      >
                        <div class="form__group field mb-3">
                          <input
                            type="input"
                            class="form__field"
                            placeholder="Name"
                            name="name"
                            autocomplete="off"
                            required
                          />
                          <label for="name" class="form__label"
                            >Enter your name</label
                          >
                        </div>
                        <div class="form__group field mb-3">
                          <input
                            type="email"
                            class="form__field"
                            placeholder="Email"
                            name="email"
                            autocomplete="off"
                            required
                          />
                          <label for="email" class="form__label"
                            >Enter your email address</label
                          >
                        </div>
                        <div class="form__group field mb-3">
                          <input
                            type="input"
                            class="form__field"
                            placeholder="Phone Number"
                            name="phone"
                            autocomplete="off"
                            title="Please enter exactly 10 digits"
                              maxlength="10" pattern="\d{10}"
                            required
                          />
                          <label for="name" class="form__label"
                            >Enter your mobile number</label
                          >
                        </div>
                        <div class="form__group field mb-3">
                          <textarea
                            type="input"
                            class="form__field"
                            placeholder="Phone Number"
                            autocomplete="off"
                            name="message"
                            rows="3"
                            required
                          />
                          <label for="query" class="form__label"
                            >Write a query here</label
                          >
                        </div>
                        <div class="float-md-end text-center">
                          <button type="submit" class="btn btn-primary">
                            Send Message
                          </button>
                        </div>
                      </form>
                      <!-- Form -->
                    </div>
                    <div
                      class="col-xxl-6 d-flex justify-content-center align-items-center"
                    >
                      <div>
                        <img
                          src="@/assets/contact.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <!-- end of section-5 -->
          </div>
          <!-- HomeScreen-First-Section -->

          <!-- Home-Second-Section -->
          <div class="col-xxl-4 col-xl-5 col-lg-6 col-md-6 px-0">
            <aside
              v-if="
                $route.path !== '/login' &&
                $route.path !== '/signin' &&
                $route.path !== '/otp-verification' &&
                $route.path !== '/KYC-1' &&
                $route.path !== '/KYC-2'
              "
            >
              <div style="margin-top: 80px">
                <div class="px-4">
                  <div class="row">
                    <div
                      class="col-md-9 d-flex align-items-center justify-content-center px-0 my-0 my-1"
                      style="border: 2px solid #ff6600; border-radius: 5px"
                    >
                      <select
                        class="form-select"
                        v-model="branch"
                        aria-label="Default select example"
                        style="border: none"
                      >
                        <option value="majestic" selected>
                          Bengaluru - Majestic
                        </option>
                        <option value="nayabazar">Cuttack - Naya bazar</option>
                        <option value="PATIA">Bhubaneshwar - Patia</option>
                        <option value="JAGAMARA">
                          Bhubaneshwar - Jagamara
                        </option>
                      </select>
                    </div>
                    <div
                      class="col-md-3"
                      data-bs-toggle="modal"
                      data-bs-target="#filtermodal"
                    >
                      <button
                        type="button"
                        class="btn btn-primary my-1 py-3 w-100"
                        style="
                          background: #011640;
                          border: none;
                          border-radius: 10px;
                        "
                      >
                        <img src="@/assets/Group (1).png" alt="" />
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-md-12 d-flex align-items-center justify-content-center py-0"
                      style="border: 1px solid #eef1f6; border-radius: 10px"
                    >
                      <div class="row">
                        <div class="col-md-5 p-0">
                          <div class="form-floating p-0">
                            <input
                              type="text"
                              v-model="displayDateTime"
                              class="form-control"
                              placeholder="name@example.com"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              style="
                                max-width: 100%;
                                border: none;
                                focus: none;
                                box-shadow: none;
                              "
                            />
                            <label for="Start" class=""
                              >Start Date & Time</label
                            >
                          </div>
                        </div>
                        <div
                          class="col-md-2 d-flex align-items-center justify-content-center"
                        >
                          <div>
                            <img
                              src="@/assets/filterArrow.svg"
                              alt=""
                              class="img-fluid ps-1 pb-1"
                            />
                          </div>
                        </div>
                        <div class="col-md-5 p-0">
                          <div class="form-floating p-0">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="name@example.com"
                              v-model="displayEndDateTime"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop2"
                              style="
                                max-width: 100%;
                                border: none;
                                focus: none;
                                box-shadow: none;
                              "
                            />
                            <label for="End" class="">End Date & Time</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="filterData == '' && displayDateTime == ''">
                  <!-- card-section -->
                  <div
                    class="pt-5"
                    v-if="filterData == '' || displayEndDateTime == ''"
                  >
                    <div
                      class="d-flex flex-column align-items-center justify-content-center"
                      style="height: 380px !important"
                    >
                      <div>
                        <img
                          src="@/assets/calendar.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div class="px-5">
                        <h6 class="pt-3 px-5 text-center calendar">
                          Kindly choose the start date and time, as well as the
                          end date and time.
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card section data -->
                <section class="px-4 pt-3" v-else>
                  <div>
                    <div v-if="filterData.length > 0">
                      <div
                        class="card mb-3 p-2"
                        v-for="(bike, index) in filterData"
                        :key="bike.id"
                        style="height: 160px !important"
                      >
                        <div class="row pt-1">
                          <div class="col-6">
                            <div
                              class="d-flex align-items-center justify-content-center"
                              style="
                                border-radius: 10px;
                                border: 2px solid #eef1f6;
                              "
                            >
                              <img
                                v-bind:src="`https://speedrent.in/adminapp/uploads/${bike.bikeImage}`"
                                alt="Bike"
                                class="img-fluid"
                                v-on:click="vehicle(bike)"
                                style="
                                  width: 100%;
                                  object-fit: cover !important;
                                  height: 130px !important;
                                "
                              />
                            </div>
                          </div>

                          <div class="col-6 p-0">
                            <div class="card-body ps-0 py-0">
                              <div class="mb-1">
                                <h5 class="vehicle-title">
                                  {{ bike.bikeName }} <br /><span
                                    style="color: #ff6600"
                                    >₹ {{ bike.daily }} /day
                                  </span>
                                </h5>
                                <p class="vehicle-text pt-1">
                                  <img
                                    src="@/assets/card-location.svg"
                                    alt=""
                                  />
                                  <span class="ps-2">{{ bike.branch }}</span>
                                </p>
                                <div
                                  class="d-flex justify-content-between pt-1"
                                >
                                  <div class="w-100">
                                    <button
                                      type="button"
                                      class="btn w-100"
                                      style="
                                        background-color: #ff6600;
                                        color: #fff;
                                      "
                                      v-on:click="book(bike, prices[index])"
                                    >
                                      Book Now
                                    </button>
                                  </div>

                                  <div class="ps-2" @click="addWishlist(bike)">
                                    <button
                                      type="button"
                                      class="btn"
                                      style="background: #f3f7fe"
                                    >
                                      <img src="@/assets/like.svg" alt="" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        class="card mb-3 p-2"
                        v-for="(bike, index) in bikes"
                        :key="bike.id"
                        style="height: 160px !important"
                      >
                        <div class="row pt-1">
                          <div class="col-6">
                            <div
                              class="d-flex align-items-center justify-content-center"
                              style="
                                border-radius: 10px;
                                border: 2px solid #eef1f6;
                              "
                            >
                              <img
                                v-bind:src="`https://speedrent.in/adminapp/uploads/${bike.bikeImage}`"
                                alt="Bike"
                                class="img-fluid"
                                v-on:click="vehicle(bike)"
                                style="
                                  width: 100%;
                                  object-fit: cover !important;
                                  height: 130px !important;
                                "
                              />
                            </div>
                          </div>

                          <div class="col-6 p-0">
                            <div class="card-body ps-0 py-0">
                              <div class="mb-1">
                                <h5 class="vehicle-title pt-1">
                                  {{ bike.bikeName }} <br /><span
                                    style="color: #ff6600"
                                    >₹ {{ bike.booking_amount }}
                                  </span>
                                </h5>
                                <p class="vehicle-text pb-1">
                                  <img
                                    src="@/assets/card-location.svg"
                                    alt=""
                                  />
                                  <span class="ps-2">{{ bike.branch }}</span>
                                </p>
                                <div
                                  class="d-flex justify-content-between pt-1"
                                >
                                  <div class="w-100">
                                    <button
                                      type="button"
                                      class="btn w-100"
                                      style="
                                        background-color: #ff6600;
                                        color: #fff;
                                      "
                                      v-on:click="book(bike, prices[index])"
                                    >
                                      Book Now
                                    </button>
                                  </div>

                                  <div class="ps-2" @click="addWishlist(bike)">
                                    <button
                                      type="button"
                                      class="btn"
                                      style="background: #f3f7fe; border: none"
                                    >
                                      <img src="@/assets/like.svg" alt="" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <!-- card section -->
              </div>
            </aside>
          </div>
          <!-- Home-Second-Section -->
        </div>
      </div>
      <!-- HomeScreen-WebView -->

      <!-- HomeScreen-MobileView -->
      <div class="d-md-none">
        <div class="">
          <div
            v-if="
              $route.path !== '/login' &&
              $route.path !== '/signin' &&
              $route.path !== '/otp-verification' &&
              $route.path !== '/KYC-1' &&
              $route.path !== '/KYC-2'
            "
          >
            <div style="margin-top: 80px">
                <div class="px-3">
                  <div class="row">
                    <div
                      class="col-9 d-flex align-items-center justify-content-center py-0"
                      style="border: 1px solid #eef1f6; border-radius: 10px"
                    >
                      <div class="row">
                        <div class="col-5 p-0">
                          <div class="form-floating p-0">
                            <input
                              type="text"
                              v-model="displayDateTime"
                              class="form-control ps-3"
                              placeholder="name@example.com"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              style="
                                max-width: 100%;
                                border: none;
                                focus: none;
                                box-shadow: none;
                                font-size: 12px;
                              "
                            />
                            <label for="Start" class="ps-3" style="font-size: 12px;"
                              >Start Date & Time</label
                            >
                          </div>
                        </div>
                        <div
                          class="col-2 d-flex align-items-center justify-content-center"
                        >
                          <div>
                            <img
                              src="@/assets/filterArrow.svg"
                              alt=""
                              class="img-fluid ps-0 pb-1"
                            />
                          </div>
                        </div>
                        <div class="col-5 p-0">
                          <div class="form-floating p-0">
                            <input
                              type="text"
                              class="form-control ps-1"
                              placeholder="name@example.com"
                              v-model="displayEndDateTime"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop2"
                              style="
                                max-width: 100%;
                                border: none;
                                focus: none;
                                box-shadow: none;
                                font-size: 12px;
                              "
                            />
                            <label for="End" class="ps-1" style="font-size: 12px;">End Date & Time</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-3"
                      data-bs-toggle="modal"
                      data-bs-target="#filtermodal"
                    >
                      <button
                        type="button"
                        class="btn btn-primary my-1 py-3 w-100"
                        style="
                          background: #011640;
                          border: none;
                          border-radius: 10px;
                        "
                      >
                        <img src="@/assets/Group (1).png" alt="" />
                      </button>
                    </div>
                  </div>
                </div>

                <div v-if="filterData == '' && displayDateTime == ''">
                  <!-- card-section -->
                  <div
                    class="pt-5"
                    v-if="filterData == '' || displayEndDateTime == ''"
                  >
                    <div
                      class="d-flex flex-column align-items-center justify-content-center"
                      style="height: 380px !important"
                    >
                      <div>
                        <img
                          src="@/assets/calendar.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div class="px-5">
                        <h6 class="pt-3 px-5 text-center calendar">
                          Kindly choose the start date and time, as well as the
                          end date and time.
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- card section data -->
                <section class="px-0 pt-3" v-else>
                  <div>
                    <div v-if="filterData.length > 0">
                      <div
                        class="card mb-3 p-2"
                        v-for="(bike, index) in filterData"
                        :key="bike.id"
                        style="height: 160px !important"
                      >
                        <div class="row pt-1">
                          <div class="col-6">
                            <div
                              class="d-flex align-items-center justify-content-center"
                              style="
                                border-radius: 10px;
                                border: 2px solid #eef1f6;
                              "
                            >
                              <img
                                v-bind:src="`https://speedrent.in/adminapp/uploads/${bike.bikeImage}`"
                                alt="Bike"
                                class="img-fluid"
                                v-on:click="vehicle(bike)"
                                style="
                                  width: 100%;
                                  object-fit: cover !important;
                                  height: 130px !important;
                                "
                              />
                            </div>
                          </div>

                          <div class="col-6 p-0">
                            <div class="card-body ps-0 py-0">
                              <div class="mb-1">
                                <h5 class="vehicle-title">
                                  {{ bike.bikeName }} <br /><span
                                    style="color: #ff6600"
                                    >₹ {{ bike.daily }} /day
                                  </span>
                                </h5>
                                <p class="vehicle-text pt-1">
                                  <img
                                    src="@/assets/card-location.svg"
                                    alt=""
                                  />
                                  <span class="ps-2">{{ bike.branch }}</span>
                                </p>
                                <div
                                  class="d-flex justify-content-between pt-1"
                                >
                                  <div class="w-100">
                                    <button
                                      type="button"
                                      class="btn w-100"
                                      style="
                                        background-color: #ff6600;
                                        color: #fff;
                                      "
                                      v-on:click="book(bike, prices[index])"
                                    >
                                      Book Now
                                    </button>
                                  </div>

                                  <!-- <div class="ps-2" @click="addWishlist(bike)">
                                    <button
                                      type="button"
                                      class="btn"
                                      style="background: #f3f7fe"
                                    >
                                      <img src="@/assets/like.svg" alt="" />
                                    </button>
                                  </div> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        class="card mb-2 px-2 pt-1"
                        v-for="(bike, index) in bikes"
                        :key="bike.id"
                        style="height: 160px !important"
                      >
                      <div class="px-2 pt-2">
                        <div class="row">
                          <div class="col-6">
                            <div
                              class="d-flex align-items-center justify-content-center"
                              style="
                                border-radius: 10px;
                                border: 2px solid #eef1f6;
                              "
                            >
                              <img
                                v-bind:src="`https://speedrent.in/adminapp/uploads/${bike.bikeImage}`"
                                alt="Bike"
                                class="img-fluid"
                                v-on:click="vehicle(bike)"
                                style="
                                  width: 100%;
                                  object-fit: cover !important;
                                  height: 130px !important;
                                "
                              />
                            </div>
                          </div>

                          <div class="col-6 p-0">
                            <div class="card-body ps-0 py-0">
                              <div class="mb-1">
                                <h5 class="vehicle-title">
                                  {{ bike.bikeName }} <br /><span
                                    style="color: #ff6600"
                                    >₹ {{ bike.booking_amount }}
                                  </span>
                                </h5>
                                <p class="vehicle-text">
                                  <img
                                    src="@/assets/card-location.svg"
                                    alt=""
                                  />
                                  <span class="ps-2">{{ bike.branch }}</span>
                                </p>
                                <div
                                  class="d-flex justify-content-between"
                                >
                                  <div class="w-100">
                                    <button
                                      type="button"
                                      class="btn w-100"
                                      style="
                                        background-color: #ff6600;
                                        color: #fff;
                                      "
                                      v-on:click="book(bike, prices[index])"
                                    >
                                      Book Now
                                    </button>
                                  </div>

                                  <!-- <div class="ps-2" @click="addWishlist(bike)">
                              <button
                                type="button"
                                class="btn  wish"
                                style="background: #f3f7fe"
                                id="like-btn"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="white"
                                  id="heart-svg"
                                  class="bi bi-heart-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
                                  />
                                </svg>
                              </button>
                            </div> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </section>
                <!-- card section -->
              </div>
          </div>
        </div>
      </div>
      <!-- HomeScreen-MobileView -->
    </div>

    <p id="random_num" hidden></p>

    <!-- Footer -->
    <div class="d-none d-md-block">
      <section
        class="footer pt-5"
        v-if="
          $route.path !== '/login' &&
          $route.path !== '/signin' &&
          $route.path !== '/otp-verification' &&
          $route.path !== '/KYC-1' &&
          $route.path !== '/KYC-2'
        "
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-3 col-12 mb-3">
              <div class="d-flex flex-column">
                <div>
                  <img
                    src="@/assets/speedrentlogo 2.png"
                    alt=""
                    class="img-fluid footer-img"
                  />
                </div>
                <div class="d-flex py-2">
                  <div>
                    <i class="fa-solid fa-envelope me-2" style="font-size: 14px;"></i>
                  </div>
                  <div class="footer-description">speedrentbikes@gmail.com</div>
                </div>
                <div>
                  <div class="d-flex py-2">
                    <div>
                      <i class="fa-solid fa-location-dot me-2 pt-2"></i>
                    </div>
                    <div class="footer-description">
                      Plot No 280 Starcity Road Near RTO-II, Patia, Bhubaneswar,
                      Odisha 751024.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-4 mb-3">
              <div>
                <h4 class="footer-header pb-2">Quick Links</h4>
                <ul class="list-unstyled">
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/arrow-right.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2">
                      <a href="/" class="footer-link"
                        >Bike Rental in Bhubaneswar</a
                      >
                    </div>
                  </li>
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/arrow-right.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2">
                      <a href="/" class="footer-link">Bike Rental in Cuttack</a>
                    </div>
                  </li>
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/arrow-right.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2">
                      <a href="/" class="footer-link"
                        >Bike Rental in Bangalore</a
                      >
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-3 col-md-4 mb-3">
              <div>
                <h4 class="footer-header pb-2">Terms & Policies</h4>
                <ul class="list-unstyled">
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/invoice.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2">
                      <a href="/privacy-policy" class="footer-link"
                        >Privacy Policy</a
                      >
                    </div>
                  </li>
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/invoice.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2">
                      <a href="/terms&conditions" class="footer-link"
                        >Terms & Conditions</a
                      >
                    </div>
                  </li>
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/invoice.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2 footer-link">
                      <a href="/refund&cancellations" class="footer-link"
                        >Refund & Cancellations</a
                      >
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-3 col-md-4 mb-3">
              <div>
                <h4 class="footer-header pb-2">Get it from</h4>
              </div>
              <div class="d-flex flex-lg-row flex-column">
                <div class="mb-3">
                  <a href="/">
                    <img
                      src="@/assets/app-store.png"
                      class="img-fluid py-1 px-1"
                      alt="App Store"
                    />
                  </a>
                </div>
                <div class="ps-xl-2 ps-0">
                  <a href="https://play.google.com/store/apps/details?id=com.customer.speedrent">
                    <img
                      src="@/assets/play-store.png"
                      class="img-fluid py-1 px-1"
                      alt="App Store"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="container pb-1">
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="copyrights text-md-start text-center">
                <a href="/" style="color: #000">
                  © 2023 Speed Rent. All Rights Reserved
                </a>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="float-md-end float-none">
                <div class="d-flex justify-content-center">
                  <div class="pe-5">
                    <a href="https://www.facebook.com/profile.php?id=61555955444703&mibextid=ZbWKwL" style="color: black"
                      ><i class="fa-brands fa-square-facebook"></i
                    ></a>
                  </div>
                  <div class="pe-5">
                    <a href="https://www.instagram.com/speedrent_bike_rental?igsh=MjZ3NmszZmV5eWho" style="color: black"
                      ><i class="fa-brands fa-instagram"></i
                    ></a>
                  </div>
                  <div class="">
                    <a href="https://www.youtube.com/@Speedrent-mg3zo" style="color: black"
                      ><i class="fa-brands fa-youtube"></i></a>
                  </div>
                  <!-- <div>
                    <a href="/" style="color: black"
                      ><i class="fa-brands fa-x-twitter"></i
                    ></a>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Footer -->
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "App",
  data() {
    return {
      faqs: [
        {
          question: "What is Speed Rent?",
          answer:
            "Speed Rent is one of the Best Bike & Scooter rental companies in Odisha. You can get bikes for rent from us.",
          expanded: false,
        },
        {
          question: "Where does Speed Rent Services Available?",
          answer:
            "Our bike rental services are available in Bhubaneswar, Cuttack and Puri",
          expanded: false,
        },
        {
          question:
            "What are the Documents required to book the bike on rental?",
          answer:
            "Booking a bike through Speed Rent is easy. You just need an Original Aadhaar Card and Driving Licence for verification purposes.",
          expanded: false,
        },
        {
          question: "Do I need to pay Security Deposit?",
          answer:
            "Yes, there will be a nominal amount of Security Deposit you need to pay for hiring any bike for rent.",
          expanded: false,
        },
        {
          question: "Do I need to fuel the Bike?",
          answer:
            "Yes, you need to fuel the bike for your travel. Sometimes bikes will be sufficiently fueled for the journey, in that case, you need to leave the vehicle with same fuel level at the time of returning. If you fuel the vehicle more than level while taking, we will refund the difference amount.",
          expanded: false,
        },
        {
          question: "Can I extend my bike rental duration?",
          answer:
            "Yes, you can extend your bike rental booking, duration in advance subject to the availability of the bike for the extension. In case, if the same bike is not available for extension, you need to return it at the given time and you can get another available bike for rental.",
          expanded: false,
        },
        // Add more FAQ items as needed
      ],
      kycDone: "",
      dates: null,
      time: "",
      datesend: null,
      timeend: "",
      displayDateTime: "",
      displayEndDateTime: "",
      dbstartdate: "",
      dbenddate: "",
      dbstarttime: "",
      dbendtime: "",
      branch: "PATIA",
      bikes: [],
      prices: [],
      vehicleData: "",
      fuelType: "",
      transmissionType: "",
      ratings: "",
      filterData: [],
      confirmbooking: "",
      username: "",
      email: "",
      phone: "",
      message: "",
      userDetails: [],
      isLiked: false
    };
  },
  watch: {
    branch(newbranch) {
      // console.log(newbranch);
      this.$storage.setStorageSync("branch", newbranch);
    },

    bike(newbike) {
      this.$storage.setStorageSync("bike", newbike);
    },

    displayDateTime(newdisplayDateTime) {
      this.$storage.setStorageSync("displayDateTime", newdisplayDateTime);
    },

    displayEndDateTime(newdisplayEndDateTime) {
      this.$storage.setStorageSync("displayEndDateTime", newdisplayEndDateTime);
    },

    dates(newdate) {
      // console.log(newdate);
      if (newdate.length < 12) {
        this.dbstartdate = newdate;
        newdate = new Date(newdate).toString();
      } else {
        var obj = {
          Jan: "01",
          Feb: "02",
          Mar: "03",
          Apr: "04",
          May: "05",
          Jun: "06",
          Jul: "07",
          Aug: "08",
          Sep: "09",
          Oct: "10",
          Nov: "11",
          Dec: "12",
        };
        var arr = newdate.split(" ");
        this.dbstartdate = `${arr[3]}-${obj[arr[1]]}-${arr[2]}`;
      }
      // console.log(newdate);
      var date = newdate.split(" ")[2] + " " + newdate.split(" ")[1];
      this.displayDateTime = date + ", 08:00 AM";
      this.$storage.setStorageSync("mydate", this.dbstartdate);
      // console.log(this.$storage.getStorageSync("mydate"));
    },

    datesend(newdate2) {
      if (newdate2.length < 12) {
        this.dbenddate = newdate2;
        newdate2 = new Date(newdate2).toString();
      } else {
        var obj = {
          Jan: "01",
          Feb: "02",
          Mar: "03",
          Apr: "04",
          May: "05",
          Jun: "06",
          Jul: "07",
          Aug: "08",
          Sep: "09",
          Oct: "10",
          Nov: "11",
          Dec: "12",
        };
        var arr = newdate2.split(" ");

        this.dbenddate = `${arr[3]}-${obj[arr[1]]}-${arr[2]}`;
      }
      this.dbenddate = this.dbenddate.split(",")[0];

      var datesend = newdate2.split(" ")[2] + " " + newdate2.split(" ")[1];
      // console.log(this.dbenddate);

      this.displayEndDateTime = datesend + ", 08:00 AM";
      this.$storage.setStorageSync("mydate2", this.dbenddate);
      // console.log(this.$storage.getStorageSync("mydate2"));
    },

    time(newtime) {
      // console.log(newtime);
      if (newtime.split(" ")[1] == "AM") {
        this.dbstarttime = newtime.split(" ")[0] + ":00";
      } else {
        var data = parseInt(newtime.split(":")[0]);
        data += 12;
        this.dbstarttime = `${data}:${newtime.split(":")[1].split(" ")[0]}:00`;
      }
      this.displayDateTime = this.displayDateTime.split(",")[0] + ", ";
      // console.log(this.displayDateTime);
      this.displayDateTime += newtime;
      // console.log(this.dbstarttime);
      this.$storage.setStorageSync("mytime", this.dbstarttime);
      // console.log(this.$storage.getStorageSync("mytime"));
    },

    async timeend(newtimeend) {
      if (newtimeend.split(" ")[1] == "AM") {
        this.dbendtime = (await newtimeend.split(" ")[0]) + ":00";
        // console.log(this.dbendtime);
      }
      if (newtimeend.split(" ")[1] == "PM") {
        var data = parseInt(newtimeend.split(":")[0]);
        data += 12;
        this.dbendtime = await `${data}:${
          newtimeend.split(":")[1].split(" ")[0]
        }:00`;
      }
      // console.log(this.displayEndDateTime);
      try {
        this.displayEndDateTime = this.displayEndDateTime.split(",")[0] + ", ";
      } catch (err) {
        console.log(err);
      }
      // console.log(this.dbendtime);
      this.displayEndDateTime += newtimeend;
      this.$storage.setStorageSync("mytime2", this.dbendtime);
    },
  },
  methods: {
    getSelectedValue(containerClass) {
      var container = document.querySelector(
        `.${containerClass} .swiper-slide-active`
      );
      return container ? container.getAttribute("value") : null;
    },

    toggleCollapse(index) {
      // Close all FAQs
      this.faqs.forEach((faq, i) => {
        if (i !== index) {
          faq.expanded = false;
        }
      });

      // Toggle the selected FAQ
      this.faqs[index].expanded = !this.faqs[index].expanded;
    },

    Clear() {
      this.displayDateTime = null;
      this.dbstartdate = null;
      this.dbstarttime = null;
      $("#staticBackdrop").modal("hide");
      this.ClearEnd();
    },

    ClearEnd() {
      this.displayEndDateTime = "";
      this.dbenddate = null;
      this.dbendtime = null;
      $("#staticBackdrop2").modal("hide");
    },

    async Logout() {
      $("#logoutmodal").modal("show");
    },

    No() {
      $("#logoutmodal").modal("hide");
      // this.$router.go(this.$router.currentRoute);
    },

    Okay() {
      $("#minmodal").modal("hide");
      this.$router.go(this.$router.currentRoute);
    },

    Yes() {
      this.$storage.clearStorageSync();
      $("#logoutmodal").modal("hide");
      this.$router.go(this.$router.currentRoute);
    },

    vehicle(bike) {
      $("#vehicle").modal("show");

      axios
        .post(
          "https://speedrent.in/adminapp/bikeDetails.php",
          {
            bikeNumber: bike["bikeNumber"],
          },
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }
        )
        .then((response) => {
          // console.log(response);
          // console.log(response.data);
          this.vehicleData = response.data;
          // this.$router.push({path: '/'})
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "Server responded with status code:",
              error.response.status
            );
            console.log("Response data:", error.response.data);
          } else if (error.request) {
            console.log("No response received:", error.request);
          } else {
            console.log("Error creating request:", error.message);
          }
        });
    },

    getbikes() {
      // console.log("Called");
      this.bikes = [];
      this.prices = [];
      // console.log("++++++++++++++++++++");

      // console.log(this.dbstarttime);
      // console.log(this.dbstartdate);
      // console.log("++++++++++++++++++++");
      // console.log(this.dbenddate);
      // console.log(this.$storage.getStorageSync("mytime2"));
      var date1 = new Date(`${this.dbstartdate} ${this.dbstarttime}`);
      var date2 = new Date(
        `${this.$storage.getStorageSync(
          "mydate2"
        )} ${this.$storage.getStorageSync("mytime2")}`
      );
      // console.log(date1);
      // console.log(date2);
      // var date1 = this.dbendtime;
      // var date2 = this.dbstartdate;
      // var diff = date1 - date2;
      // var diffInHours = diff / 1000 / 60 / 60;
      var one_hour = 1000 * 60 * 60;
      var diffInHours = Math.round((date2 - date1) / one_hour);
      console.log("diffInHours " + diffInHours);

      if (diffInHours < 6) {
        // console.log("Diff called");
        $("#minmodal").modal("show");
      } else {
        // console.log({
        //   pickupDate: this.dbstartdate,
        //   pickupTime: this.dbstarttime,
        //   dropDate: this.dbenddate,
        //   dropTime: this.dbendtime,
        //   branch: this.branch,
        // });
        axios
          .post(
            "https://speedrent.in/v2web/getBikes.php",
            {
              pickupDate: this.dbstartdate,
              pickupTime: this.dbstarttime,
              dropDate:
                this.dbenddate == null
                  ? this.$storage.getStorageSync("mydate2")
                  : this.dbenddate,
              dropTime: this.$storage.getStorageSync("mytime2"),
              branch: this.branch,
            },
            {
              headers: {
                "Content-type":
                  "application/x-www-form-urlencoded;charset=utf-8",
              },
            }
          )
          .then((response) => {
            // console.log(response);
            this.bikes = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    Submit() {
      $("#staticBackdrop").modal("hide");

      this.filterData = [];

      if (this.dates == null) {
        this.dates = new Date().toString();
      }

      // var _this = this;
      var _this = this;
      var selectedHour = this.getSelectedValue("swiper-container.hours");
      var selectedMeridiem = this.getSelectedValue("swiper-container.seconds");
      var formattedTime = `${selectedHour} ${selectedMeridiem}`;
      document.getElementById("time").value = formattedTime;
      // console.log("Updated time:", formattedTime);
      _this.time = formattedTime;

      this.bikes = [];
      this.prices = [];
      // console.log(this.dbstarttime);
      // console.log(this.dbstartdate);
      this.ClearEnd();
    },

    SubmitEnd() {
      $("#staticBackdrop2").modal("hide");

      if (this.datesend == null) {
        this.datesend = new Date().toString();
      }

      var _this = this;
      var selectedHour2 = this.getSelectedValue("swiper-container.hours-end");
      var selectedMeridiem2 = this.getSelectedValue(
        "swiper-container.seconds-end"
      );
      var formattedTime2 = `${selectedHour2} ${selectedMeridiem2}`;
      document.getElementById("input").value = formattedTime2;
      // console.log("Updated time:", formattedTime2);
      _this.timeend = formattedTime2;
      // console.log(
      //   this.displayDateTime.length + "," + this.displayEndDateTime.length
      // );
      if (this.displayDateTime.length > 0) {
        // this.getbikes();
        setTimeout(() => this.getbikes(), 500);
      } else {
        $("#minmodal").modal("show");
      }
    },

    booknow() {
      window.location.href =
        "https://speedrent.in/adminapp/cashfree/createOrder.php?bookingId=" +
        this.$storage.getStorageSync("bookingId") +
        "&amount=" +
        this.$storage.getStorageSync("final_amount") +
        "&phone=" +
        this.$storage.getStorageSync("pnumber");
    },

    book(bike, price) {
      var bookingId = Math.floor(Math.random() * 9000000000) + 1000000000;
      this.$storage.setStorageSync("final_amount", price);
      this.$storage.setStorageSync("bookingId", bookingId);
      this.$storage.setStorageSync("bikeNumber", bike["bikeNumber"]);
      this.$storage.setStorageSync("final_amount", bike["booking_amount"]);

      if (this.$storage.getStorageSync("message") == "verified") {
        axios
          .post(
            "https://speedrent.in/adminapp/customerapp/addBooking.php",
            {
              bikeNumber: this.$storage.getStorageSync("bikeNumber"),
              pickupDate: this.$storage.getStorageSync("mydate"),
              pickupTime: this.$storage.getStorageSync("mytime"),
              dropDate: this.$storage.getStorageSync("mydate2"),
              dropTime: this.$storage.getStorageSync("mytime2"),
              cNumber: this.$storage.getStorageSync("pnumber"),
              bookingId: this.$storage.getStorageSync("bookingId"),
              bookingAmount: this.$storage.getStorageSync("final_amount"),
              branch: this.branch,
            },
            {
              headers: {
                "Content-type":
                  "application/x-www-form-urlencoded;charset=utf-8",
              },
            }
          )
          .then(() => {
            $("#confirmbooking").modal("show");
            axios
              .post(
                "https://speedrent.in/adminapp/bookingDetails.php",
                {
                  bookingId: this.$storage.getStorageSync("bookingId"),
                },
                {
                  headers: {
                    "Content-type":
                      "application/x-www-form-urlencoded;charset=utf-8",
                  },
                }
              )
              .then((response) => {
                this.confirmbooking = response.data;
                // console.log(response.data);
                // Swal.fire({
                //   icon: "success",
                //   title: "Booking Successfull!",
                //   showConfirmButton: false,
                //   timer: 1500,
                // });
                // window.location.href =
                //   "https://speedrent.in/adminapp/cashfree/createOrder.php?bookingId=" +
                //   this.$storage.getStorageSync("bookingId") +
                //   "&amount=" +
                //   this.$storage.getStorageSync("final_amount");
              })
              .catch((error) => {
                if (error.response) {
                  console.log(
                    "Server responded with status code:",
                    error.response.status
                  );
                  console.log("Response data:", error.response.data);
                } else if (error.request) {
                  console.log("No response received:", error.request);
                } else {
                  console.log("Error creating request:", error.message);
                }
              });
          })
          .catch((error) => {
            if (error.response) {
              console.log(
                "Server responded with status code:",
                error.response.status
              );
              console.log("Response data:", error.response.data);
            } else if (error.request) {
              console.log("No response received:", error.request);
            } else {
              console.log("Error creating request:", error.message);
            }
          });
      } else {
        this.$router.push({ path: "/signin" });
      }
    },

    myFilter() {
      this.bikes = [];
      this.prices = [];

      // console.log(this.$storage.getStorageSync("kycflag"));
      axios
        .post(
          "https://speedrent.in/v2web/filter.php",
          {
            fuelType: this.fuelType == "PETROL" ? "PETROL" : "ELECTRIC",
            transmissionType:
              this.transmissionType == "MOPPED" ? "MOPPED" : "GEAR",
            rating: this.ratings == "3" ? "3" : "All",
            branch: this.branch,
          },
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }
        )
        .then((response) => {
          // console.log(response);
          this.filterData = response.data;
          $("#filtermodal").modal("hide");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    clearFilter() {
      this.filterData = [];
      this.fuelType = [];
      this.transmissionType = [];
      this.ratings = [];
      $("#filtermodal").modal("hide");
      this.getbikes();
    },

    onContact() {
      axios
        .post(
          "https://speedrent.in/v2web/userinfo.php",
          {
            username: this.username,
            email: this.email,
            phone: this.phone,
            message: this.message,
          },
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }
        )
        .then((response) => {
          // console.log(response);
          $("#myForms")[0].reset();
          $("#contactmodal").modal("show");
          setTimeout(function () {
            $("#contactmodal").modal("hide");
          }, 2000);
          // Swal.fire({
          //   icon: "success",
          //   title: "Query Submitted!",
          //   showConfirmButton: false,
          //   timer: 1500,
          // });
          // $("#exampleModals").modal("hide");
        })
        .then(() => {
          // $("#email-modal").modal("show");
          // setTimeout(function () {
          //   $("#email-modal").modal("hide");
          // }, 2000);
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "Server responded with status code:",
              error.response.status
            );
            console.log("Response data:", error.response.data);
          } else if (error.request) {
            console.log("No response received:", error.request);
          } else {
            console.log("Error creating request:", error.message);
          }
        });
    },

    addWishlist(bike) {
      if (this.$storage.getStorageSync("message") == "verified") {
        var button = document.getElementById("like-btn");
      var heartSvg = document.getElementById("heart-svg");

      this.isLiked = !this.isLiked;

      if (this.isLiked) {
        button.style.background = "#F7E6DB";
        heartSvg.setAttribute("fill", "orange");

        axios
          .post(
            "https://speedrent.in/v2web/wishlist.php",
            {
              bikeNumber: bike["bikeNumber"],
              phone: this.$storage.getStorageSync("pnumber"),
              // customerName: this.$storage.getStorageSync("cName"),
              // phone: bike["cNumber"],
              customerName: bike["cName"],
              branch: bike["branch"],
              fuelType: bike["fuelType"],
              transmittonType: bike["transmissionType"],
              bikeName: bike["bikeName"],
            },
            {
              headers: {
                "Content-type":
                  "application/x-www-form-urlencoded;charset=utf-8",
              },
            }
          )
          .then((response) => {
            // console.log(response);
          }).then(() => {
            // $("#wishalert").modal("show");
            // setTimeout(function () {
            //   $("#wishalert").hide();
            //   $(".modal-backdrop").remove();
            // }, 1500);
          })
      } 
      else {
        
        button.style.background = "#f3f7fe";
        heartSvg.setAttribute("fill", "white");

        axios
        .post(
          "https://speedrent.in/v2web/deletewishlist.php",
          {
            bikeNumber: bike["bikeNumber"],
              phone: this.$storage.getStorageSync("pnumber"),
          },
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }
        )
        .then((response) => {
          // console.log(response)
          // this.$router.go(this.$router.currentRoute);
        });
    
      }
      }
      else {
        this.$router.push({ path: "/signin" });
      }
    },

    onEditPro() {
      axios
      .post(
        "https://speedrent.in/v2web/editProfile.php",
        {
          image: this.selfie.split(',')[1],
          name: this.userDetails.name,
          email: this.userDetails.email,
          number: this.userDetails.number,
        },
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        $("#profilemodal").modal("hide");
      })
      .catch((error) => {
        console.log(error);
      });
    },
    preview(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.selfie = reader.result;
        // console.log('result', reader.result)
      };
      reader.readAsDataURL(file);
    },

    editProfile() {
      $(".offcanvas").offcanvas("hide");
      $("#profilemodal").modal("show");
    },
  },

  mounted() {
    // console.log(this.$storage.getStorageSync('message'));
    // console.log(this.$storage.getStorageSync('kycRequired'));
    // console.log(this.$storage.getStorageSync('kycflag'));

    axios
      .post(
        "https://speedrent.in/v2web/getUserDetails.php",
        {
          phone: this.$storage.getStorageSync("pnumber"),
        },
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        this.userDetails = response.data;
        this.kycDone = response.data.kycDone;
        //  console.log(this.kycDone)
      })
      .catch((error) => {
        console.log(error);
      });

    var _this = this;

    // start_date
    $(function () {
      $("#date").datepicker({
        minDate: 0,
        dateFormat: "yy-mm-dd",
      });

      $("#date").on("change", function () {
        var selected = $(this).val();
        // console.log(selected);
        _this.dates = selected;
        // console.log(_this.dates);
      });

      $("#time").on("change", function () {
        _this.time = "";
        var selected = $(this).val();
        _this.time = selected;
      });
    });
    // start_date

    // end_date
    $(function () {
      $("#dateend").datepicker({
        minDate: 0,
        dateFormat: "yy-mm-dd",
      });

      $("#dateend").on("change", function () {
        var selected = $(this).val();
        // console.log(selected);
        _this.datesend = selected;
        // console.log(_this.dates);
      });

      $("#timeend").on("change", function () {
        _this.timeend = "";
        var selected = $(this).val();
        // console.log(selected);
        _this.timeend = selected;
      });
    });
    // end_date

    // time picker starts
    var defaults = {
      pagination: ".swiper-pagination",
      slidesPerView: 3,
      freeMode: true,
      freeModeSticky: true,
      freeModeMomentumRatio: 0.25,
      freeModeVelocityRatio: 0.25,
      freeModeMinimumVelocity: 0.1,
      loop: true,
      loopAdditionalSlides: 5,
      direction: "vertical",
      slideToClickedSlide: true,
      centeredSlides: true,
      onSlideChangeEnd: updateInput,
    };

    var hours = new Swiper(
      ".swiper-container.hours",
      Object.assign({}, defaults, {
        initialSlide: 14,
      })
    );
    hours.on("slideChangeEnd", function () {
      console.log("hi");
    });

    var minutes = new Swiper(
      ".swiper-container.minutes",
      Object.assign({}, defaults, {
        initialSlide: 37,
      })
    );

    var seconds = new Swiper(".swiper-container.seconds", defaults);

    var hours = new Swiper(
      ".swiper-container.hours-end",
      Object.assign({}, defaults, {
        initialSlide: 14,
      })
    );
    hours.on("slideChangeEnd", function () {
      console.log("hi");
    });

    var minutes = new Swiper(
      ".swiper-container.minutes-end",
      Object.assign({}, defaults, {
        initialSlide: 37,
      })
    );

    var seconds = new Swiper(".swiper-container.seconds-end", defaults);

    var input = document.getElementById("input");

    input.addEventListener("focus", moveLeft);

    input.addEventListener("click", function (evt) {
      var start = input.selectionStart;
      var mod = start % 3;
      start -= mod;
      input.setSelectionRange(start, start + 2);
    });

    input.addEventListener("keydown", function (evt) {
      evt.preventDefault();
      switch (evt.keyCode) {
        case 38:
          change(true);
          break;
        case 40:
          change();
          break;
        case 37:
          moveLeft();
          break;
        case 39:
          moveRight();
          break;
      }
    });

    function moveLeft(e) {
      var start = input.selectionStart;
      var end = input.selectionEnd;

      var invalidSelection = end - start !== 2 || start % 3 !== 0;

      if (invalidSelection) {
        input.setSelectionRange(0, 2);
      } else if (start > 0) {
        start -= 3;
        input.setSelectionRange(start, start + 2);
      }
    }

    function moveRight() {
      // console.log("moveRight")
      var start = input.selectionStart;
      var end = input.selectionEnd;

      if (end - start !== 2) {
        input.setSelectionRange(0, 2);
      }

      if (end < input.value.length) {
        start += 3;
      }

      input.setSelectionRange(start, start + 2);
    }

    function findPart() {
      console.log("moveRight");
      var start = input.selectionStart;
      var mod = start % 3;
      start -= mod;

      if (start === 6) {
        return seconds;
      } else if (start === 3) {
        return minutes;
      }

      return hours;
    }

    function change(direction) {
      console.log("moveRight");
      var part = findPart();
      direction ? part.slideNext(true, 50) : part.slidePrev(true, 50);
    }

    function updateInput() {
      console.log("moveRight");
      if (!input) {
        return false;
      }

      var start = input.selectionStart;
      var end = input.selectionEnd;
      input.value =
        pad(hours.realIndex) +
        ":" +
        pad(minutes.realIndex) +
        ":" +
        pad(seconds.realIndex);
      input.setSelectionRange(start, end);
    }

    function pad(v) {
      console.log("moveRight");
      return v > 9 ? v : "0" + String(v);
    }

    input.focus();

    // time picker ends
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Satoshi";
  src: url("~@/assets/fonts/Satoshi-Medium.ttf");
}
.main {
  overflow-x: hidden;
}
a {
  text-decoration: none;
  color: #000;
}
.logo {
  width: 60px;
}
/* hero-section */
.hero {
  background: #fcfcfc;
}
.hero .heading span {
  color: rgba(255, 102, 0, 1);
  font-size: 32px;
}
.hero .heading {
  color: rgba(0, 0, 0, 1);
  font-family: "Satoshi";
  font-weight: 900;
}
.hero .header-text {
  color: rgba(153, 157, 168, 1);
  font-family: "Satoshi";
  font-weight: 400;
}
.hero .btn-primary {
  background: rgba(255, 102, 0, 1);
  border: none;
  font-family: "Satoshi";
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}
.hero .btn-primary:hover {
  background: rgba(255, 102, 0, 1);
  border: none;
}
/* section-1 */
.discover .discover-text {
  color: rgba(1, 22, 64, 1);
  font-family: "Satoshi";
  font-weight: 900;
}
.discover .img-text {
  color: rgba(1, 22, 64, 1);
  font-family: "Satoshi";
  font-weight: 400;
}
/* section-2 */
.services .card {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border: none;
}
.services .card-text {
  color: rgba(153, 157, 168, 1);
  font-family: "Satoshi";
  font-weight: 400;
}
.services .icons {
  background: rgba(255, 102, 0, 0.07);
  height: 36px;
  width: 36px;
  border-radius: 5px;
}
.services .card-title {
  color: rgba(1, 22, 64, 1);
  font-family: "Satoshi";
  font-weight: 500;
}
.services .service-img {
  width: 24px;
  border: none;
}
.services .sub-heading {
  color: rgba(255, 102, 0, 1);
  font-family: "Satoshi";
  font-weight: 500;
}
.services .heading {
  color: rgba(1, 22, 64, 1);
  font-family: "Satoshi";
  font-weight: 500;
}
@media (min-width: 576px) {
  .services .card {
    height: 180px;
  }
}
@media (min-width: 768px) {
  .services .card {
    height: 250px;
  }
}
@media (min-width: 992px) {
  .services .card {
    height: 400px;
  }
}
@media (min-width: 1200px) {
  .services .card {
    height: 260px;
  }
}
@media (min-width: 1400px) {
  .services .card {
    height: 260px;
  }
}
/* reviews */
.reviews {
  background: rgba(1, 10, 38, 1);
}
.reviews .card {
  background: rgba(52, 59, 81, 1);
}
.reviews .fa-star {
  color: #ffd700 !important;
}
.reviews .card-title {
  color: rgba(255, 255, 255, 1);
  font-family: "Satoshi";
  font-weight: 500;
}
.reviews .card-text {
  color: rgba(179, 181, 190, 1);
  font-family: "Satoshi";
  font-weight: 400;
}
.reviews .heading {
  color: rgba(255, 255, 255, 1);
  font-family: "Satoshi";
  font-weight: 900;
}
.reviews .sub-heading {
  color: rgba(153, 157, 168, 1);
  font-family: "Satoshi";
  font-weight: 400;
}
/* faq section */
.faq-section .faq-heading {
  color: rgba(0, 0, 0, 1);
  font-family: "Satoshi";
  font-weight: 900;
}
.faq-section .faq-subheading {
  color: rgba(185, 193, 204, 1);
  font-weight: 400;
  font-family: "Poppins";
}
.faq-question {
  color: rgba(32, 36, 45, 1);
  font-family: "Satoshi";
  font-weight: 500;
}
.faq-section .faq-answer {
  color: rgba(143, 157, 170, 1);
  font-family: "Satoshi";
  font-weight: 300;
}
.faq-section .fa-plus {
  color: rgba(255, 102, 0, 1);
}
.faq-section .fa-times {
  color: rgba(255, 102, 0, 1);
}
.calendar {
  font-family: "Satoshi";
  font-weight: 400;
  color: #999da8;
}
/* contact */
.contact .heading {
  color: rgba(1, 25, 54, 1);
  font-family: "Satoshi";
  font-weight: 400;
}
.contact .btn-primary {
  background: rgba(255, 102, 0, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
}
.contact .btn-primary:hover {
  background: rgba(255, 102, 0, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
}
.contact form {
  width: 100%;
}
.contact .form__group {
  position: relative;
  padding: 15px 0 0;
  width: 100%;
}
.contact .form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ccc;
  outline: 0;
  font-size: 1rem;
  color: #000;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
  /* font-weight: 400; */
  font-family: "Satoshi";
}
.contact .form__field::placeholder {
  color: transparent;
}
.contact .form__field:placeholder-shown ~ .form__label {
  font-size: 1.1rem;
  cursor: text;
  top: 20px;
  font-weight: 400;
  font-family: "Satoshi";
}
.contact .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  color: rgba(128, 144, 166, 1);
  font-weight: 400;
  font-family: "Satoshi";
}
.contact .form__field:focus {
  padding-bottom: 6px;
  font-weight: 200;
  border-width: 1px;
  border-image: linear-gradient(
    to right,
    rgb(217, 224, 234),
    rgb(206, 212, 220)
  );
  border-image-slice: 1;
}
.contact .form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: rgba(128, 144, 166, 1);
  font-weight: 400;
  font-family: "Satoshi";
}
.contact .form__field:required,
.form__field:invalid {
  box-shadow: none;
}
/* navbar */
nav .nav-link {
  color: rgba(1, 22, 64, 1);
  font-family: "Satoshi";
  font-weight: 500;
}
nav .download {
  background: rgba(255, 102, 0, 1);
  border-radius: 5px;
}
nav .btn-txt {
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-family: "Satoshi";
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .d {
    padding-top: 14px;
  }
}
.navbar-toggler:focus,
.btn-close:focus {
  outline: none !important;
  box-shadow: none !important;
}
/* @media (min-width: 1400px) {
  .navbar-nav {
    padding-left: 270px !important;
  }
} */
/* @media (min-width: 1400px) {
  .city-dropdown {
    padding-right: 100px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .city-dropdown {
    padding-right: 120px;
  }
}
@media (min-width: 1193px) and (max-width: 1199.98px) {
  .city-dropdown {
    padding-right: 200px;
  }
}
@media (min-width: 992px) and (max-width: 1192.98px) {
  .city-dropdown {
    padding-right: 120px;
  }
} */
/* end of navbar */

/* sidebar */
aside {
  position: fixed;
  padding-top: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 0;
  bottom: 0;
}
aside::-webkit-scrollbar {
  display: none;
}
.vehicle-title {
  font-weight: 500;
}
.vehicle-text {
  font-weight: 300;
  color: #8f9daa;
}
@media screen and (min-width: 567px) {
  .vehicle-text {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 585px) {
  .booking-btn {
    width: 60%;
  }
}
/* end of sidebar */

/* footer */
.footer {
  background: #f9f9fc;
  position: sticky;
}
.footer a {
  text-decoration: none;
}
.footer .fa-solid {
  color: #ff6600;
}
.footer .footer-header {
  font-family: "Satoshi";
  font-weight: 600;
  color: #ff6600;
}
.footer .footer-link {
  font-weight: 500;
  font-family: "Satoshi";
  color: #010a26;
}
/* end of footer */

.ui-datepicker {
  width: 100% !important;
  height: auto;
  margin: 5px auto 0;
  /* -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5); */
}

.ui-datepicker a {
  text-decoration: none;
}
.ui-datepicker table {
  width: 100%;
}
.ui-datepicker-header {
  background-color: #ff66000d !important;
  color: #ff6600;
  font-weight: bold;
  line-height: 30px;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: transparent;
  border-radius: 5px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.ui-datepicker-title {
  text-align: center;
}
.ui-datepicker-prev,
.ui-datepicker-next {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  background-image: url("~@/assets/arrow.png");
  background-repeat: no-repeat;
  line-height: 600%;
  overflow: hidden;
}
.ui-datepicker-prev {
  float: left;
  background-position: center -30px;
}
.ui-datepicker-next {
  float: right;
  background-position: center 0px;
}
.ui-datepicker th {
  /* text-transform: uppercase; */
  padding: 5px 0;
  color: #000;
  text-align: center;
}
.ui-datepicker tbody td {
  padding: 0;
}

.ui-datepicker thead {
  background: #fff !important;
}

.ui-datepicker td span,
.ui-datepicker td a {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  width: 100%;
  height: 100%;
  line-height: 30px;
  color: #000;
}
.ui-datepicker-calendar .ui-state-default {
  background-color: #fff;
}
.ui-datepicker-unselectable .ui-state-default {
  background: #fff;
  color: #b4b3b3;
}
.ui-datepicker-calendar .ui-state-hover {
  background: #ff6600;
  color: #fff;
}

.ui-datepicker-calendar .ui-state-active {
  background: #ff6600;
  color: #fff;
  position: relative;
  margin: -1px;
}

/* time  */

.input-wrapper {
  text-align: center;
}

.picker {
  position: relative;
  width: 140px;
  overflow: hidden;
  margin: 1rem auto 0;
  /* outline: 1px solid #ccc; */
  padding: 0 30px;
  background-color: #fff;
  /* color: #252525; */
  color: #ff6600;
}

.swiper-container {
  width: 80px;
  height: 170px;
  float: left;
}

.swiper-slide {
  text-align: center;
  font-size: 1.5rem;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  opacity: 0.25;
  transition: opacity 0.3s ease;
  cursor: default;
  font-weight: bold;
  -webkit-tap-highlight-color: transparent;
}

.swiper-slide-prev,
.swiper-slide-next {
  cursor: pointer;
}

.swiper-slide-active {
  opacity: 1;
}

.vizor {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  /* color: #f1ecec; */
  height: 70px;
  position: absolute;
  top: 50%;
  left: 1rem;
  right: 1rem;
  transform: translateY(-50%);
  font-size: 2rem;
  line-height: 62px;
}

.vizor:before,
.vizor:after {
  content: ":";
  display: inline-block;
  line-height: inherit;
  height: 100%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}

.vizor:before {
  left: 95px;
}

.vizor:after {
  left: 175px;
}

.arrows .swiper-container:after,
.arrows .swiper-container:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px;
  border-color: transparent;
  opacity: 0.5;
}

.arrows .swiper-container:before {
  top: 0.5rem;
  border-top-width: 0;
  border-bottom-color: currentColor;
}

.arrows .swiper-container:after {
  bottom: 0.5rem;
  border-bottom-width: 0;
  border-top-color: currentColor;
}
/* time picker ends */

.vehicle-btn {
  background: #ff6600;
  color: #fff;
  border: none;
}
.vehicle-btn:hover {
  background: #ff6600;
  color: #fff;
  border: none;
}

.btn-outline-danger {
  background: rgb(217, 224, 234);
  color: #000;
  border: none;
}
.btn-outline-danger:hover {
  background: rgb(217, 224, 234);
  color: #000;
  border: none;
}

.btn-outline-success {
  background: #ff6600;
  color: #fff;
  border: none;
}
.btn-outline-success:hover {
  background: #ff6600;
  color: #fff;
  border: none;
}
.form-select {
  box-shadow: none;
}
#logoutmodal .btn-primary {
  background: #011640;
  color: #fff;
  border: none;
}
#logoutmodal .btn-primary:hover {
  background: #011640;
  color: #fff;
  border: none;
}
#logoutmodal .btn-outline-primary {
  color: #000;
  border-color: #ff6600;
}
#logoutmodal .btn-outline-primary:hover {
  color: #fff;
  background: #ff6600;
}
#minmodal .btn-primary {
  background: #ff6600;
  color: #fff;
  border: none;
}
#minmodal .btn-primary:hover {
  background: #ff6600;
  color: #fff;
  border: none;
}
#confirmbooking .btn-primary {
  background: #ff6600;
  color: #fff;
  border: none;
}
#confirmbooking .btn-primary:hover {
  background: #ff6600;
  color: #fff;
  border: none;
}

/* form */
.profile form {
  background: #fff;
  border-radius: 10px;
}
.profile form .register-btn {
  background: #ff6600 !important;
  color: #fff !important;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.profile form .register-btn:hover {
  background: #ffffff;
  color: #ff6600;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.profile .form-label {
  color: #676c7d;
}
.profile .sub {
  color: #676c7d;
}

.profile .form-control {
  color: #20242d;
}
.profile .container {
  box-shadow: 0px 0px 15px 0px #00000012;
}
.profile .edit {
  color: #8f9daa;
}
.profile .rounded {
  background: #ff6600;
  color: #ffffff;
  border: 0;
}
.profile .alter-button {
  font-family: Inter;
  font-size: 20px;
}
.profile .profile-img {
  position: relative;
}
.profile .delete-icon {
  position: absolute;
  bottom: 0px;
  left: 90px;
}

.profile #drop-area {
  width: 125px;
  height: 125px;
  padding: 0px 10px;
  /* text-align: center; */
  border-radius: 8px;
  border: 1px solid #000;
}
.profile #img-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.profile #img-view img {
  width: 100px;
  /* object-fit: cover; */
}
.profile .imgs {
  object-fit: cover;
  height: 80px;
}
.nav-btn:hover {
  color: #fff;
  border: none;
}
.footer-img {
  width: 100px;
  height: 100px;
}

.vimg {
  height: 20px;
}
.vimg1 {
  width: 26px;
  height: 24px;
}

.profile .imgs {
  object-fit: cover;
  height: 100px;
}
</style>


