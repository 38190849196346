<template>
  <div class="main">
    <!-- Web View -->
    <section class="web d-none d-lg-block">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-7 col-lg-6 first-sec">
            <div class="row">
              <div class="col-md-12">
                <div class="px-5 pt-5">
                  <img
                    src="@/assets/speedrentlogo.png"
                    alt=""
                    class="logo img-fluid"
                  />
                </div>
              </div>
            </div>
            <div class="row pt-xxl-5 pt-5">
              <div
                class="col-md-12 d-flex align-items-center justify-content-start pt-xl-2 pt-5"
              >
                <div class="ps-xxl-5 ps-xl-2 ps-0 pt-xxl-5">
                  <img
                    src="@/assets/Hero.png"
                    alt=""
                    class="img-fluid hero-img ps-5"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-5 col-lg-6 second-sec">
            <div class="row">
              <div class="col-md-12">
                <router-link to="/">
                  <div class="px-5 pt-5 float-end">
                    <button type="button" class="btn btn-outline px-5">
                      Skip
                    </button>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex align-items-center justify-content-center"
              >
                <div class="content py-5">
                  <form
                    class="w-100 d-flex align-items-center justify-content-center pt-1"
                    v-on:submit.prevent="onAdhaarKYC()"
                  >
                    <div class="px-lg-4 px-xxl-5 py-4 form">
                      <div class="d-flex justify-content-between">
                        <div>
                          <h6>KYC - Step 1/2</h6>
                          <p style="font-size: 14px">
                            Please provide your Aadhaar details for verification
                            purposes.
                          </p>
                        </div>
                        <div>
                          <a href="/">
                            <img
                              src="@/assets/skipbtn.png"
                              alt=""
                              class="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                      <div class="w-100 pb-xxl-4 pb-3">
                        <label for="aadhar">Adhaar Number</label>
                        <input
                          type="text"
                          v-model="userDetails.adhaar"
                          maxlength="12"
                          class="form-control"
                          id="autoSizingInput"
                          placeholder="Enter your mobile number"
                          title="Please enter exactly 12 digits"
                          pattern="\d{12}"
                          required
                        />
                      </div>
                      <div class="pb-3 pb-xxl-4">
                        <label for="">Adhaar Picture(Front Side)</label>
                        <label for="input-file" id="drop-area">
                          <input
                            type="file"
                            accept="image/*"
                            id="input-file"
                            @change="preview"
                            hidden
                          />
                          <div id="img-view">
                            <div v-if="adhaar1">
                              <img class="img-fluid imgs" :src="adhaar1" />
                            </div>
                            <div v-else>
                              <img
                                v-bind:src="`https://speedrent.in/adminapp/uploads/${userDetails.adhaarImage}`"
                                alt=""
                                class="img-fluid imgs"
                              />
                            </div>
                          </div>
                        </label>
                        <div
                          v-if="!isFrontImageUploaded && submitted"
                          class="text-danger"
                        >
                          Front side image is required.
                        </div>
                      </div>
                      <div class="pb-3 pb-xxl-4">
                        <label for="">Adhaar Picture(Back Side)</label>
                        <label for="input-file2" id="drop-area2">
                          <input
                            type="file"
                            accept="image/*"
                            id="input-file2"
                            @change="previewback"
                            hidden
                          />
                          <div id="img-view2">
                            <div v-if="adhaar2">
                              <img class="img-fluid imgs" :src="adhaar2" />
                            </div>
                            <div v-else>
                              <img
                                v-bind:src="`https://speedrent.in/adminapp/uploads/${userDetails.adhaarImage}`"
                                alt=""
                                class="img-fluid imgs"
                              />
                            </div>
                          </div>
                        </label>
                        <div
                          v-if="!isBackImageUploaded && submitted"
                          class="text-danger"
                        >
                          Back side image is required.
                        </div>
                      </div>
                      <div class="text-center">
                        <button
                          type="submit"
                          class="btn btn-primary btn-large w-100 text-uppercase"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- mobile login view -->
    <section class="mobile-login p-0 d-lg-none">
      <div class="log-sec row">
        <div class="col-12 py-3">
          <router-link to="/">
            <div
              class="logo-c col-12 d-flex align-items-end justify-content-end py-3 px-5"
            >
              <button type="button" class="btn btn-outline-light px-3">
                <img src="@/assets/arrow.svg" alt="" /> Skip
              </button>
            </div>
          </router-link>
          <div class="col-12 d-flex align-items-center justify-content-center">
            <img src="@/assets/loginlogo.png" alt="" class="img-fluid" />
          </div>
        </div>

        <div
          class="logo-c2 col-12 bg-light rounded-5 rounded-bottom pb-0 px-5 pt-5"
        >
          <div class="">
            <form class="w-100" v-on:submit.prevent="onAdhaarKYC()">
              <div class="px-lg-4 px-xxl-5 py-4 form">
                <div class="d-flex justify-content-between">
                  <div>
                    <h6>KYC - Step 1/2</h6>
                    <p style="font-size: 14px">
                      Please provide your Aadhaar details for verification
                      purposes.
                    </p>
                  </div>
                  <div>
                    <a href="/">
                      <img
                        src="@/assets/skipbtn.png"
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                  </div>
                </div>
                <div class="w-100 pb-xxl-4 pb-3">
                  <label for="aadhar">Aadhar Number</label>
                  <input
                    type="text"
                    v-model="userDetails.adhaar"
                    maxlength="12"
                    class="form-control"
                    id="autoSizingInput"
                    placeholder="Enter your mobile number"
                    title="Please enter exactly 12 digits"
                          pattern="\d{12}"
                          required
                  />
                </div>
                <div class="pb-3 pb-xxl-4">
                  <label for="">Adhaar Picture(Front Side)</label>
                  <label for="input-file" id="drop-area">
                    <input
                      type="file"
                      accept="image/*"
                      id="input-file"
                      @change="preview"
                      hidden
                    />
                    <div id="img-view">
                            <div v-if="adhaar1">
                              <img class="img-fluid imgs" :src="adhaar1" />
                            </div>
                            <div v-else>
                              <img
                                v-bind:src="`https://speedrent.in/adminapp/uploads/${userDetails.adhaarImage}`"
                                alt=""
                                class="img-fluid imgs"
                              />
                            </div>
                          </div>
                  </label>
                  <div
                          v-if="!isFrontImageUploaded && submitted"
                          class="text-danger"
                        >
                          Front side image is required.
                        </div>
                </div>
                <div class="pb-3 pb-xxl-4">
                  <label for="">Adhaar Picture(Back Side)</label>
                  <label for="input-file2" id="drop-area2">
                    <input
                      type="file"
                      accept="image/*"
                      id="input-file2"
                      @change="previewback"
                      hidden
                    />
                    <div id="img-view2">
                            <div v-if="adhaar2">
                              <img class="img-fluid imgs" :src="adhaar2" />
                            </div>
                            <div v-else>
                              <img
                                v-bind:src="`https://speedrent.in/adminapp/uploads/${userDetails.adhaarImage}`"
                                alt=""
                                class="img-fluid imgs"
                              />
                            </div>
                          </div>
                  </label>
                  <div
                          v-if="!isBackImageUploaded && submitted"
                          class="text-danger"
                        >
                          Back side image is required.
                        </div>
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-primary btn-large w-100 text-uppercase"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!-- mobile login view ends-->
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      adhaarNumber: "",
      adhaar1: "",
      adhaar2: "",
      isFrontImageUploaded: "",
      isBackImageUploaded: "",
      submitted: false,
      userDetails: {
        adhaar: "",
        adhaar1: "",
        adhaar2: "",
      },
    };
  },
  watch: {
    adhaarNumber(newadhaarNumber) {
      this.$storage.setStorageSync('adhaarNumber', newadhaarNumber);
    },
    adhaar1(newadhaar1) {
      this.$storage.setStorageSync('adhaar1', newadhaar1);
    },
    adhaar2(newadhaar2) {
      this.$storage.setStorageSync('adhaar2', newadhaar2);
    },
  },
  methods: {
    preview(e) {
      this.isFrontImageUploaded = true;
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.adhaar1 = reader.result;
        // console.log('result', reader.result)
      };
      reader.readAsDataURL(file);
    },

    previewback(e) {
      this.isBackImageUploaded = true;
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.adhaar2 = reader.result;
        // console.log('result', reader.result)
      };
      reader.readAsDataURL(file);
    },

    onAdhaarKYC() {
      this.$router.push({path: '/edit-dl'})         
    }

  },
  mounted() {
    axios
      .post(
        "https://speedrent.in/v2web/getUserDetails.php",
        {
          phone: this.$storage.getStorageSync("pnumber"),
        },
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        this.userDetails = response.data;
        this.kycDone = response.data.kycDone;
        //  console.log(this.kycDone)
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
</script>

<style scoped>
@font-face {
  font-family: "Satoshi";
  src: url("~@/assets/fonts/Satoshi-Medium.ttf");
}
.main {
  overflow-x: hidden;
}
a {
  text-decoration: none;
}
@media (max-width: 1200px) {
  .hero-img {
    max-width: 350px !important;
  }
}
/* Web View */
.web {
  background: #f5d8c5;
}
@media (min-width: 1400px) {
  .web .first-sec {
    background-image: url("@/assets/Intersect2.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
  }
}
@media (max-width: 1399.98px) {
  .web .first-sec {
    background-image: url("@/assets/Intersect3.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: auto;
  }
}
.web .heading {
  color: #ffffff;
  font-family: "Satoshi";
  font-weight: 400;
}
.web form {
  background: #fff;
  border-radius: 10px;
  width: 70%;
}
.web form select:focus {
  box-shadow: none;
  border: 1px solid gray;
}
.web form input:focus {
  box-shadow: none;
  border: 1px solid gray;
}
.web .btn-primary {
  background: #ff6600;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-primary:hover {
  background: #ff6600;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-outline {
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-outline:hover {
  background-color: #000;
  border: none;
  color: #fff;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}

.web #drop-area {
  width: 100%;
  height: 130px;
  padding: 30px;
  /* text-align: center; */
  border-radius: 8px;
  border: 1px solid #e5e4e4;
}
.web #img-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.web #img-view img {
  width: 100px;
}

.web #drop-area2 {
  width: 100%;
  height: 130px;
  padding: 30px;
  /* text-align: center; */
  border-radius: 8px;
  border: 1px solid #e5e4e4;
}
.web #img-view2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.web #img-view2 img {
  width: 100px;
}
.web .imgs {
  object-fit: cover;
  height: 100px;
}
/* mobile view  */
.mob-kyc-1 {
  /* height: 100vh; */
  background: #fff;
  overflow-y: scroll;
}
.log-sec {
  background: #ff6602 !important;
}
@media screen and (min-width: 1200px) {
  .mob-kyc-1 {
    display: none;
  }
  .hero {
    display: block !important;
  }
}
@media screen and (min-width: 1200px) {
  .mob-kyc-1 {
    display: none !important;
  }
}
.mobile-login .btn-primary {
  background: #ff6600;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.mobile-login .btn-primary:hover {
  background: #ff6600;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}

.mobile-login #drop-area {
  width: 100%;
  height: 130px;
  padding: 30px;
  /* text-align: center; */
  border-radius: 8px;
  border: 1px solid #e5e4e4;
}
.mobile-login #img-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.mobile-login #img-view img {
  width: 100px;
}

.mobile-login #drop-area2 {
  width: 100%;
  height: 130px;
  padding: 30px;
  /* text-align: center; */
  border-radius: 8px;
  border: 1px solid #e5e4e4;
}
.mobile-login #img-view2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.mobile-login #img-view2 img {
  width: 100px;
}
.mobile-login .imgs {
  object-fit: cover;
  height: 100px;
}
</style>