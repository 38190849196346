import "bootstrap/dist/css/bootstrap.css"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vue3Storage from "vue3-storage"




const app = createApp(App)

app.use(router);
app.use(Vue3Storage);



app.mount('#app');

import "bootstrap/dist/js/bootstrap.js";
