<template>
  <div class="main">

        <!-- Logout Modal -->
<div class="modal fade" id="logoutmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container py-2">
          <div class="d-flex flex-column align-items-center justify-content-center">
            <img src="@/assets/logout.png" alt="" class="img-fluid">
            <h5>Do you want to logout?</h5>
            <p class="text-center">Confirmation prompt to log out from the current session securely</p>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <div class="pe-2 w-100">
              <button type="button" class="btn btn-primary w-100" v-on:click="No()">No</button>
            </div>
            <div class="ps-2 w-100">
              <button type="button" class="btn btn-outline-primary w-100" v-on:click="Yes()">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    <!-- Logout Modal -->


<!-- Profile Edit -->
    <div
      class="modal fade profile"
      id="profilemodal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">My Profile</h5>
            <button
              type="button"
              class="btn-close btn-close-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="content pb-2">
              <form class="p-4" v-on:submit.prevent="onEditPro()" >
                    <div class="mb-2">
                      <label for="" class="pb-2">Profile Picture</label>
                      <div class="pb-2 pb-xxl-2">
                        <label for="input-file" id="drop-area">
                          <input
                            type="file"
                            accept="image/*"
                            id="input-file"
                            @change="preview"
                            hidden
                          />
                          <div id="img-view">
                            <div v-if="selfie">
                              <img class="img-fluid imgs" :src="selfie" />
                            </div>
                            <div v-else>
                              <img
                                v-bind:src="`https://speedrent.in/adminapp/uploads/${userDetails.number}.jpg`"
                                alt=""
                                class="img-fluid imgs"
                              />
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="name" class="form-label">Name</label>
                      <input
                        type="text"
                        v-model="userDetails.name"
                        class="form-control"
                        placeholder="Enter your full name"
                        id="name"
                        required
                      />
                    </div>
                    <div class="sub mb-4">
                      Mobile Number:
                      <div class="d-flex pt-2">
                        <div class="col-auto pe-2">
                          <select class="form-select" id="autoSizingSelect" disabled>
                            <option selected>+91</option>
                            <option value="1">+44</option>
                            <option value="2">+66</option>
                            <option value="3">+88</option>
                          </select>
                        </div>
                        <div class="w-100">
                          <input
                            type="text"
                            v-model="userDetails.number"
                            class="form-control"
                            id="autoSizingInput"
                            placeholder="Mobile Number"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="cMail" class="form-label"
                        >Email Address:</label
                      >
                      <input
                        type="email"
                        v-model="userDetails.email"
                        class="form-control"
                        id="cMail"
                        placeholder="Enter your email address"
                      />
                    </div>
                    <div class="text-center">
                      <button
                        type="submit"
                        class="btn btn-primary register-btn w-100"
                      >
                        Edit Profile
                      </button>
                    </div>
                  </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Profile Edit -->

    <div class="container px-5 py-2">
      <!-- Navbar -->
      <div class="container-fluid">
        <nav
          class="navbar navbar-expand-md navbar-light bg-light fixed-top d-none d-md-block bg-info"
          v-if="
            $route.path !== '/login' &&
            $route.path !== '/signin' &&
            $route.path !== '/otp-verification' &&
            $route.path !== '/KYC-1' &&
            $route.path !== '/KYC-2'
          "
          style="border: 0.1px solid #f1ecec"
        >
          <div class="d-flex align-items-center">
            <a class="navbar-brand ps-lg-5" href="/">
              <img
                src="@/assets/speedrentlogo.png"
                alt=""
                class="logo img-fluid"
              />
            </a>
            <button
              class="navbar-toggler shadow-none border-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="offcanvas offcanvas-start"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                  Features
                </h5>
                <button
                  type="button"
                  class="btn-close shadow-none"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                <ul class="navbar-nav justify-content-center flex-grow-1">
                  <li class="nav-item mx-xl-3 mx-md-2">
                    <a href="#blogs" class="nav-link">Blogs</a>
                  </li>
                  <!-- <li class="nav-item mx-xl-3 mx-md-2">
                    <a href="" class="nav-link">Testimonials</a>
                  </li> -->
                  <li class="nav-item mx-xl-3 mx-md-2">
                    <a href="#FAQs" class="nav-link">FAQ's</a>
                  </li>
                  <li class="nav-item mx-xl-3 mx-md-2">
                    <a href="#contact-us" class="nav-link">Contact Us</a>
                  </li>
                  <li>
                    <div
                      v-if="
                        this.$storage.getStorageSync('message') == 'verified'
                      "
                    >
                      <div class="d">
                        <a href="/" class="nav-btn">
                          <div class="download">
                            <button
                              type="button"
                              class="btn btn-download btn-txt"
                            >
                              Download App
                            </button>
                          </div></a
                        >
                      </div>
                    </div>
                    <div v-else>
                      <div class="d">
                          <div class="download">
                            <button
                              type="button"
                              class="btn btn-download btn-txt"
                              style="border: none;"
                            >
                            <a href="/signin" class="nav-btn text-white">
                              Login
                            </a><span class="text-white">/</span>
                            <a href="/signin" class="nav-btn text-white">
                              SignUp
                            </a>
                            </button>
                          </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="d-lg-flex">
                  <div class="sec-nav">
                    <div>
                      <button
                        class="btn"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#right"
                        aria-controls="offcanvasRight"
                        style="border: none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-list"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <!-- Web-Toggle-Button -->
        <div class="d-none d-md-block">
          <div
            class="offcanvas offcanvas-end"
            tabindex="-1"
            id="right"
            aria-labelledby="offcanvasRightLabel"
          >
            <div class="offcanvas-header">
              <img src="@/assets/speedrentlogo 1.png" alt="" />
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body">
              <div class="row">
                <div class="col 12">
                  <div class="bookfont">
                    <div class="row gap-0 border-bottom">
                      <div class="col-2">
                        <div
                          v-if="
                            this.$storage.getStorageSync('message') ==
                            'verified'
                          "
                        >
                        <img
                          v-bind:src="`https://speedrent.in/adminapp/uploads/${userDetails.number}.jpg`"
                          class="img-fluid"
                          style="height: 56px; width: 56px; object-fit:cover;"
                        />
                        </div>
                        <div v-else>
                          <div class="pt-1">
                            <img src="@/assets/Profile.png" alt="" class="">
                          </div>
                        </div>
                      </div>
                      <div class="col-7 text-start pt-2">
                        <div
                          v-if="
                            this.$storage.getStorageSync('message') ==
                            'verified'
                          "
                        >
                          <h6 class="m-0">{{ userDetails.name }}</h6>
                          <p style="color: #ff6600">
                            +91 {{ this.$storage.getStorageSync("pnumber") }}
                          </p>
                        </div>
                        <div v-else>
                          <h6 class="m-0">Welcome</h6>
                          <p style="color: #ff6600">Please Login..</p>
                        </div>
                      </div>
                      <div class="col-3 pt-2">
                        <div
                          v-if="
                            this.$storage.getStorageSync('message') ==
                            'verified'
                          "
                        >
                          <div v-on:click="editProfile()">
                            <div
                              class="rounded px-1 py-1"
                              style="color: white; background: #ff6600"
                            >
                              <div class="d-flex">
                                <div class="">
                                  <img src="@/assets/pen-circle 1.svg" alt="" />
                                </div>
                                <div class="ps-1 pt-1">EDIT</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <div
                        v-if="
                          this.$storage.getStorageSync('message') == 'verified'
                        "
                      >
                        <a href="/rides">
                          <p>
                            <img src="@/assets/book1.png" alt="" />
                            <span class="ps-1"> My Bookings</span>
                          </p>
                        </a>
                      </div>
                      <div v-else>
                        <a href="/signin">
                          <p>
                            <img src="@/assets/book1.png" alt="" />
                            <span class="ps-1"> My Bookings</span>
                          </p>
                        </a>
                      </div>
                    </div>
                    <div>
                      <div
                        v-if="
                          this.$storage.getStorageSync('message') == 'verified'
                        "
                      >
                        <a href="/wishlist">
                          <p>
                            <img src="@/assets/heart.png" alt="" />
                            <span class="ps-1"> Wishlist</span>
                          </p>
                        </a>
                      </div>
                      <div v-else>
                        <a href="/signin">
                          <p>
                            <img src="@/assets/heart.png" alt="" />
                            <span class="ps-1"> Wishlist</span>
                          </p>
                        </a>
                      </div>
                    </div>
                    <div
                      v-if="
                        this.$storage.getStorageSync('message') == 'verified'
                      "
                    >
                      <div v-if="this.kycDone === 'true'">
                        <p>
                          <img src="@/assets/kycimage.png" alt="" />
                          <span class="ps-2 pe-2">KYC</span>
                          <label
                            style="
                              background-color: #00b950;
                              color: white;
                              border-radius: 5px;
                            "
                            class="br-0 px-1"
                          >
                            Verfication Success
                          </label>
                        </p>
                      </div>
                      <div v-else>
                        <div
                          v-if="
                            this.$storage.getStorageSync('register') ==
                            'Customer added successfully..!'
                          "
                        >
                          <a href="/KYC-1">
                            <p>
                              <img src="@/assets/kycimage.png" alt="" />
                              <span class="ps-2 pe-2">KYC</span>
                              <label
                                style="
                                  background-color: #fea53f;
                                  color: white;
                                  border-radius: 5px;
                                "
                                class="br-0 px-1"
                              >
                                Verfication Pending
                              </label>
                            </p>
                          </a>
                        </div>

                        <div v-else>
                          <a href="/signup">
                            <p>
                              <img src="@/assets/kycimage.png" alt="" />
                              <span class="ps-2 pe-2">KYC</span>
                              <label
                                style="
                                  background-color: #fea53f;
                                  color: white;
                                  border-radius: 5px;
                                "
                                class="br-0 px-1"
                              >
                                Verfication Pending
                              </label>
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <a href="/signin">
                        <p>
                          <img src="@/assets/kycimage.png" alt="" />
                          <span class="ps-2 pe-2">KYC</span>
                          <label
                            style="
                              background-color: #fea53f;
                              color: white;
                              border-radius: 5px;
                            "
                            class="br-0 px-1"
                          >
                            Verfication Pending
                          </label>
                        </p>
                      </a>
                    </div>
                    <div>
                      <a href="/terms&conditions">
                        <p>
                          <img src="@/assets/policesimage.png" alt="" />
                          <span class="ps-2">Terms & Conditions</span>
                        </p>
                      </a>
                    </div>
                    <div>
                      <a href="/privacy-policy">
                        <p>
                          <img src="@/assets/policesimage.png" alt="" />
                          <span class="ps-2">Privacy Policy</span>
                        </p>
                      </a>
                    </div>
                    <div>
                      <a href="/refund&cancellations">
                        <p>
                          <img src="@/assets/policesimage.png" alt="" />
                          <span class="ps-2">Refund & Cancellations</span>
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="offcanvas-footer p-3">
              <div
                class="logbutton"
                v-if="this.$storage.getStorageSync('message') == 'verified'"
              >
                <button
                  @click.prevent="Logout()"
                  class="btn btn-primary w-100 b-0"
                  type="button"
                  style="background: #f3fbfe; color: #1291ee"
                >
                  <span class="float-start">Logout</span>
                  <span class="float-end">
                    <img
                      src="@/assets/form-logout.png"
                      alt=""
                      class="img-fluid"
                    />
                  </span>
                </button>
              </div>
              <div v-else>
                <a href="/signin">
                  <button
                    class="btn btn-primary w-100 b-0"
                    type="button"
                    style="background: #ff6600; color: #fff; border: none"
                  >
                    <span class="text-center">Login</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- Web-Toggle-Button -->
        <!-- Mobile-Toggle-Button -->
        <div class="nav2 pt-3 d-md-none fixed-top navbar-light bg-light">
          <div class="container-fluid">
            <div class="row">
              <div class="col-8">
                <div v-if="$route.path == '/bookings'">
                  <div class="d-flex">
                    <div>
                      <a href="/">
                        <div style="background: #fff; border: none">
                          <img
                            src="@/assets/wishlist/wishlistarrow.svg"
                            alt=""
                            class="img-fluid"
                          />
                        </div>
                      </a>
                    </div>
                    <div class="ps-1">
                      <select
                        class="form-select"
                        v-model="branch"
                        aria-label="Default select example"
                        style="border: none"
                      >
                        <option value="majestic" selected>
                          Bengaluru - Majestic
                        </option>
                        <option value="nayabazar">Cuttack - Naya bazar</option>
                        <option value="PATIA">Bhubaneshwar - Patia</option>
                        <option value="JAGAMARA">
                          Bhubaneshwar - Jagamara
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <a class="navbar-brand ps-lg-5" href="/">
                    <img
                      src="@/assets/speedrentlogo.png"
                      alt=""
                      class="logo img-fluid pb-2"
                      style="width: 56px !important"
                    />
                  </a>
                </div>
              </div>
              <div class="col-4">
                <div class="float-end">
                  <div class="sec-nav pe-md-5">
                    <div>
                      <button
                        class="btn"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                        style="border: none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-list"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                          />
                        </svg>
                      </button>

                      <div
                        class="offcanvas offcanvas-start"
                        tabindex="-1"
                        id="offcanvasRight"
                        aria-labelledby="offcanvasRightLabel"
                      >
                        <div class="offcanvas-header">
                          <img src="@/assets/speedrentlogo 1.png" alt="" />
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="offcanvas-body">
                          <div class="row">
                            <div class="col 12">
                              <div class="bookfont">
                                <div class="row gap-0 border-bottom">
                                  <div class="col-2">
                                    <div
                          v-if="
                            this.$storage.getStorageSync('message') ==
                            'verified'
                          "
                        >
                        <img
                          v-bind:src="`https://speedrent.in/adminapp/uploads/${userDetails.number}.jpg`"
                          class="img-fluid"
                          style="height: 56px; width: 56px; object-fit:cover;"
                        />
                        </div>
                        <div v-else>
                          <div class="pt-1">
                            <img src="@/assets/Profile.png" alt="" class="">
                          </div>
                        </div>
                                  </div>
                                  <div class="col-7 text-start pt-2">
                                    <div
                                      v-if="
                                        this.$storage.getStorageSync(
                                          'message'
                                        ) == 'verified'
                                      "
                                    >
                                      <h6 class="m-0">
                                        {{ userDetails.name }}
                                      </h6>
                                      <p style="color: #ff6600">
                                        +91
                                        {{
                                          this.$storage.getStorageSync(
                                            "pnumber"
                                          )
                                        }}
                                      </p>
                                    </div>
                                    <div v-else>
                                      <h6 class="m-0">Welcome</h6>
                                      <p style="color: #ff6600">
                                        Please Login..
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-3 pt-2">
                                    <div
                                      v-if="
                                        this.$storage.getStorageSync(
                                          'message'
                                        ) == 'verified'
                                      "
                                    >
                                      <div v-on:click="editProfile()">
                                        <div
                                          class="rounded px-1 py-1"
                                          style="
                                            color: white;
                                            background: #ff6600;
                                          "
                                        >
                                          <div class="d-flex">
                                            <div class="">
                                              <img
                                                src="@/assets/pen-circle 1.svg"
                                                alt=""
                                              />
                                            </div>
                                            <div class="ps-1 pt-1">EDIT</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="mt-4">
                                  <div
                                    v-if="
                                      this.$storage.getStorageSync('message') ==
                                      'verified'
                                    "
                                  >
                                    <a href="/rides">
                                      <p>
                                        <img src="@/assets/book1.png" alt="" />
                                        <span class="ps-1"> My Bookings</span>
                                      </p>
                                    </a>
                                  </div>
                                  <div v-else>
                                    <a href="/signin">
                                      <p>
                                        <img src="@/assets/book1.png" alt="" />
                                        <span class="ps-1"> My Bookings</span>
                                      </p>
                                    </a>
                                  </div>
                                </div>
                                <div>
                                  <div
                                    v-if="
                                      this.$storage.getStorageSync('message') ==
                                      'verified'
                                    "
                                  >
                                    <a href="/wishlist">
                                      <p>
                                        <img src="@/assets/heart.png" alt="" />
                                        <span class="ps-1"> Wishlist</span>
                                      </p>
                                    </a>
                                  </div>
                                  <div v-else>
                                    <a href="/signin">
                                      <p>
                                        <img src="@/assets/heart.png" alt="" />
                                        <span class="ps-1"> Wishlist</span>
                                      </p>
                                    </a>
                                  </div>
                                </div>

                                <div
                                  v-if="
                                    this.$storage.getStorageSync('message') ==
                                    'verified'
                                  "
                                >
                                  <div v-if="this.kycDone === 'true'">
                                    <p>
                                      <img src="@/assets/kycimage.png" alt="" />
                                      <span class="ps-2 pe-2">KYC</span>
                                      <label
                                        style="
                                          background-color: #00b950;
                                          color: white;
                                          border-radius: 5px;
                                        "
                                        class="br-0 px-1"
                                      >
                                        Verfication Success
                                      </label>
                                    </p>
                                  </div>
                                  <div v-else>
                                    <div
                                      v-if="
                                        this.$storage.getStorageSync(
                                          'register'
                                        ) == 'Customer added successfully..!'
                                      "
                                    >
                                      <a href="/KYC-1">
                                        <p>
                                          <img
                                            src="@/assets/kycimage.png"
                                            alt=""
                                          />
                                          <span class="ps-2 pe-2">KYC</span>
                                          <label
                                            style="
                                              background-color: #fea53f;
                                              color: white;
                                              border-radius: 5px;
                                            "
                                            class="br-0 px-1"
                                          >
                                            Verfication Pending
                                          </label>
                                        </p>
                                      </a>
                                    </div>

                                    <div v-else>
                                      <a href="/signup">
                                        <p>
                                          <img
                                            src="@/assets/kycimage.png"
                                            alt=""
                                          />
                                          <span class="ps-2 pe-2">KYC</span>
                                          <label
                                            style="
                                              background-color: #fea53f;
                                              color: white;
                                              border-radius: 5px;
                                            "
                                            class="br-0 px-1"
                                          >
                                            Verfication Pending
                                          </label>
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div v-else>
                                  <a href="/signin">
                                    <p>
                                      <img src="@/assets/kycimage.png" alt="" />
                                      <span class="ps-2 pe-2">KYC</span>
                                      <label
                                        style="
                                          background-color: #fea53f;
                                          color: white;
                                          border-radius: 5px;
                                        "
                                        class="br-0 px-1"
                                      >
                                        Verfication Pending
                                      </label>
                                    </p>
                                  </a>
                                </div>
                                <div>
                                  <a href="/terms&conditions">
                                    <p>
                                      <img
                                        src="@/assets/policesimage.png"
                                        alt=""
                                      />
                                      <span class="ps-2"
                                        >Terms & Conditions</span
                                      >
                                    </p>
                                  </a>
                                </div>
                                <div>
                                  <a href="/privacy-policy">
                                    <p>
                                      <img
                                        src="@/assets/policesimage.png"
                                        alt=""
                                      />
                                      <span class="ps-2">Privacy Policy</span>
                                    </p>
                                  </a>
                                </div>
                                <div>
                                  <a href="/refund&cancellations">
                                    <p>
                                      <img
                                        src="@/assets/policesimage.png"
                                        alt=""
                                      />
                                      <span class="ps-2"
                                        >Refund & Cancellations</span
                                      >
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="offcanvas-footer p-3">
                          <div
                            class="logbutton"
                            v-if="
                              this.$storage.getStorageSync('message') ==
                              'verified'
                            "
                          >
                            <button
                              @click.prevent="Logout()"
                              class="btn btn-primary w-100 b-0"
                              type="button"
                              style="background: #f3fbfe; color: #1291ee"
                            >
                              <span class="float-start">Logout</span>
                              <span class="float-end">
                                <img
                                  src="@/assets/form-logout.png"
                                  alt=""
                                  class="img-fluid"
                                />
                              </span>
                            </button>
                          </div>
                          <div v-else>
                            <a href="/signin">
                              <button
                                class="btn btn-primary w-100 b-0"
                                type="button"
                                style="
                                  background: #ff6600;
                                  color: #fff;
                                  border: none;
                                "
                              >
                                <span class="text-center">Login</span>
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Mobile-Toggle-Button -->
      </div>
      <!-- Navbar -->
                <div style="margin-top: 110px;">
                  <div class="container px-md-5 py-md-5">
                <div class="p-0">
            <a href="/">
            <i class="fa-solid fa-circle-arrow-left" style="font-size: 30px;"></i>
            </a></div>
          <div>
            <h3 class="text-center pb-4">Refund & Cancellations</h3>
          </div>
                <br />

                <h3>Payment</h3>
                <div>
                  <p>
                    1.Full payment should be made in advance before reserving
                    and renting your selected bikes.
                  </p>

                  <p>
                    2.Any delay in payment will not be accepted and the bikes
                    will be handed over to you only after the complete amount is
                    paid.
                  </p>

                  <p>
                    3.We ensure that hirers receive their paid receipts if no
                    amount is due. Do not pay any amount till you receive your
                    paid receipts.
                  </p>

                  <p>
                    4.The bike remains in your custody till the rental period
                    and should be safely returned back after use.
                  </p>

                  <p>
                    5.If you buy the bike’s insurance coverage then in case of
                    any theft you will not be subjected to fine.
                  </p>

                  <p>
                    6.Daily bookings are for 24 hours (except some we rent on 12
                    hours)
                  </p>

                  <p>7.Hourly bookings are minimum of 5 hours.</p>
                </div>

                <h3>Security Deposit</h3>
                <div>
                  <p>
                    1.Hirers are required to pay a specific amount as security
                    deposit. This security deposit is collected so that if there
                    is any damage caused to the vehicle then the amount can be
                    depreciated from your security deposit.
                  </p>

                  <p>
                    2.On safely returning back the hired bike, the entire safety
                    deposit amount is handed back to the customer.
                  </p>

                  <p>
                    3.In case there is any damage brought to the bike then the
                    amount is deducted from your security deposit and the
                    balance amount is returned back.
                  </p>

                  <p>
                    4.This amount paid by you is refundable and is entitled to
                    adjustments only under certain specific cases.
                  </p>

                  <p>
                    5.All the important documents of the bike are provided to
                    customers as soon as they fulfill all the requirements and
                    agree to all the terms and conditions.
                  </p>
                </div>
              </div>
                </div>
              </div>

              <!-- Footer -->
    <div class="d-none d-md-block">
      <section
        class="footer pt-5"
        v-if="
          $route.path !== '/login' &&
          $route.path !== '/signin' &&
          $route.path !== '/otp-verification' &&
          $route.path !== '/KYC-1' &&
          $route.path !== '/KYC-2'
        "
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-3 col-12 mb-3">
              <div class="d-flex flex-column">
                <div>
                  <img
                    src="@/assets/speedrentlogo 2.png"
                    alt=""
                    class="img-fluid footer-img"
                  />
                </div>
                <div class="d-flex py-2">
                  <div>
                    <i class="fa-solid fa-envelope me-2" style="font-size: 14px;"></i>
                  </div>
                  <div class="footer-description">speedrentbikes@gmail.com</div>
                </div>
                <div>
                  <div class="d-flex py-2">
                    <div>
                      <i class="fa-solid fa-location-dot me-2 pt-2"></i>
                    </div>
                    <div class="footer-description">
                      Plot No 280 Starcity Road Near RTO-II, Patia, Bhubaneswar,
                      Odisha 751024.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-4 mb-3">
              <div>
                <h4 class="footer-header pb-2">Quick Links</h4>
                <ul class="list-unstyled">
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/arrow-right.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2">
                      <a href="/" class="footer-link"
                        >Bike Rental in Bhubaneswar</a
                      >
                    </div>
                  </li>
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/arrow-right.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2">
                      <a href="/" class="footer-link">Bike Rental in Cuttack</a>
                    </div>
                  </li>
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/arrow-right.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2">
                      <a href="/" class="footer-link"
                        >Bike Rental in Bangalore</a
                      >
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-3 col-md-4 mb-3">
              <div>
                <h4 class="footer-header pb-2">Terms & Policies</h4>
                <ul class="list-unstyled">
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/invoice.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2">
                      <a href="/privacy-policy" class="footer-link"
                        >Privacy Policy</a
                      >
                    </div>
                  </li>
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/invoice.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2">
                      <a href="/terms&conditions" class="footer-link"
                        >Terms & Conditions</a
                      >
                    </div>
                  </li>
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/invoice.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2 footer-link">
                      <a href="/refund&cancellations" class="footer-link"
                        >Refund & Cancellations</a
                      >
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-3 col-md-4 mb-3">
              <div>
                <h4 class="footer-header pb-2">Get it from</h4>
              </div>
              <div class="d-flex flex-lg-row flex-column">
                <div class="mb-3">
                  <a href="/">
                    <img
                      src="@/assets/app-store.png"
                      class="img-fluid py-1 px-1"
                      alt="App Store"
                    />
                  </a>
                </div>
                <div class="ps-xl-2 ps-0">
                  <a href="https://play.google.com/store/apps/details?id=com.customer.speedrent">
                    <img
                      src="@/assets/play-store.png"
                      class="img-fluid py-1 px-1"
                      alt="App Store"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="container pb-1">
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="copyrights text-md-start text-center">
                <a href="/" style="color: #000">
                  © 2023 Speed Rent. All Rights Reserved
                </a>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="float-md-end float-none">
                <div class="d-flex justify-content-center">
                  <div class="pe-5">
                    <a href="https://www.facebook.com/profile.php?id=61555955444703&mibextid=ZbWKwL" style="color: black"
                      ><i class="fa-brands fa-square-facebook"></i
                    ></a>
                  </div>
                  <div class="pe-5">
                    <a href="https://www.instagram.com/speedrent_bike_rental?igsh=MjZ3NmszZmV5eWho" style="color: black"
                      ><i class="fa-brands fa-instagram"></i
                    ></a>
                  </div>
                  <div class="">
                    <a href="https://www.youtube.com/@Speedrent-mg3zo" style="color: black"
                      ><i class="fa-brands fa-youtube"></i></a>
                  </div>
                  <!-- <div>
                    <a href="/" style="color: black"
                      ><i class="fa-brands fa-x-twitter"></i
                    ></a>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Footer -->
  </div>
</template>

<script>
import axios from "axios";
export default {
data() {
    return {
      userDetails: []
    }
  },
  methods: {
    async Logout() {
      $("#logoutmodal").modal("show");
    },

    No() {
      $("#logoutmodal").modal("hide");
      this.$router.go(this.$router.currentRoute);
    },
    Yes() {
      this.$storage.clearStorageSync();
      $("#logoutmodal").modal("hide");
      this.$router.go(this.$router.currentRoute);
    },
    editProfile() {
      $(".offcanvas").offcanvas("hide");
      $("#profilemodal").modal("show");
    },
    onEditPro() {
      axios
      .post(
        "https://speedrent.in/v2web/editProfile.php",
        {
          image: this.selfie.split(',')[1],
          name: this.userDetails.name,
          email: this.userDetails.email,
          number: this.userDetails.number,
        },
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        $("#profilemodal").modal("hide");
      })
      .catch((error) => {
        console.log(error);
      });
    },
    preview(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.selfie = reader.result;
        // console.log('result', reader.result)
      };
      reader.readAsDataURL(file);
    },
  },
  mounted() {
    axios
    .post(
      "https://speedrent.in/v2web/getUserDetails.php",
      {
       phone: this.$storage.getStorageSync("pnumber"),
      },
      {
        headers: {
          "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      }
    )
    .then((response) => {
      console.log(response);
      this.userDetails = response.data;
     this.kycDone = response.data.kycDone;
     console.log(this.kycDone)
    })
    .catch((error) => {
      console.log(error);
    });
  }

}
</script>

<style scoped>
.main {
  overflow-x: hidden;
}
a {
  text-decoration: none;
  color: #0000;
}
.logo {
  width: 60px;
}
/* navbar */
nav .nav-link {
  color: rgba(1, 22, 64, 1);
  font-family: "Satoshi";
  font-weight: 500;
}
nav .download {
  background: rgba(255, 102, 0, 1);
  border-radius: 5px;
}
nav .btn-txt {
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-family: "Satoshi";
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .d {
    padding-top: 14px;
  }
}
.navbar-toggler:focus,
.btn-close:focus {
  outline: none !important;
  box-shadow: none !important;
}
/* end of navbar */

/* footer */
.footer {
  background: #f9f9fc;
  position: sticky;
}
.footer a {
  text-decoration: none;
}
.footer .fa-solid {
  color: #ff6600;
}
.footer .footer-header {
  font-family: "Satoshi";
  font-weight: 600;
  color: #ff6600;
}
.footer .footer-link {
  font-weight: 500;
  font-family: "Satoshi";
  color: #010a26;
}
/* end of footer */
#logoutmodal .btn-primary {
  background: #011640;
  color: #fff;
  border: none;
}
#logoutmodal .btn-primary:hover {
  background: #011640;
  color: #fff;
  border: none;
}
#logoutmodal .btn-outline-primary {
  color: #000;
  border-color: #ff6600;
}
#logoutmodal .btn-outline-primary:hover {
  color: #fff;
  background: #ff6600;
}
a {
  text-decoration: none;
  color: #000;
}

/* form */
.profile form {
  background: #fff;
  border-radius: 10px;
}
.profile form .register-btn {
  background: #ff6600 !important;
  color: #fff !important;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.profile form .register-btn:hover {
  background: #ffffff;
  color: #ff6600;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.profile .form-label {
  color: #676c7d;
}
.profile .sub {
  color: #676c7d;
}

.profile .form-control {
  color: #20242d;
}
.profile .container {
  box-shadow: 0px 0px 15px 0px #00000012;
}
.profile .edit {
  color: #8f9daa;
}
.profile .rounded {
  background: #ff6600;
  color: #ffffff;
  border: 0;
}
.profile .alter-button {
  font-family: Inter;
  font-size: 20px;
}
.profile .profile-img {
  position: relative;
}
.profile .delete-icon {
  position: absolute;
  bottom: 0px;
  left: 90px;
}

.profile #drop-area {
  width: 125px;
  height: 125px;
  padding: 0px 10px;
  /* text-align: center; */
  border-radius: 8px;
  border: 1px solid #000;
}
.profile #img-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.profile #img-view img {
  width: 100px;
  /* object-fit: cover; */
}
.profile .imgs {
  object-fit: cover;
  height: 80px;
}
.nav-btn:hover {
  color: #fff;
  border: none;
}
.footer-img {
  width: 100px;
  height: 100px;
}
.profile .imgs {
  object-fit: cover;
  height: 100px;
}
</style>