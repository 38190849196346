<template>
  <div>
    <div
      class="row border d-flex align-items-center justify-content-center p-2"
    >
      <div class="col-2">
       <router-link to="/"> <img
          src="@/assets/wishlist/wishlistarrow.svg"
          class="img-fluid"
          alt=""
        /></router-link>
      </div>
      <div class="col-10">
        <h3 class="text-center pe-5">My Profile</h3>
      </div>
    </div>
    <section class="px-5 pt-3">
        <div class="d-flex justify-content-center">
                <div class="content py-5">
                    <form class="p-4" v-on:submit.prevent="onRegister()" >
                    <div class="mb-2">
                      <label for="" class="pb-2">Profile Picture</label>
                      <div class="pb-2 pb-xxl-2">
                        <label for="input-file" id="drop-area">
                          <input
                            type="file"
                            accept="image/*"
                            id="input-file"
                            @change="preview"
                            hidden
                          />
                          <div id="img-view">
                            <div v-if="selfie">
                              <img class="img-fluid" :src="selfie" />
                            </div>
                            <div v-else>
                              <img
                                src="@/assets/person.png"
                                alt=""
                                class="img-fluid"
                              />
                            </div>
                          </div>
                        </label>
                        <div
                          v-if="!isSelfieUploaded && submitted"
                          class="text-danger"
                        >
                          Selfie is required.
                        </div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="name" class="form-label">Name</label>
                      <input
                        type="text"
                        v-model="cName"
                        class="form-control"
                        placeholder="Enter your full name"
                        id="name"
                        required
                      />
                    </div>
                    <div class="sub mb-4">
                      Mobile Number:
                      <div class="d-flex pt-2">
                        <div class="col-auto pe-2">
                          <select class="form-select" id="autoSizingSelect" disabled>
                            <option selected>+91</option>
                            <option value="1">+44</option>
                            <option value="2">+66</option>
                            <option value="3">+88</option>
                          </select>
                        </div>
                        <div class="w-100">
                          <input
                            type="text"
                            v-model="cNumber"
                            class="form-control"
                            id="autoSizingInput"
                            placeholder="Mobile Number"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="cMail" class="form-label"
                        >Email Address:</label
                      >
                      <input
                        type="email"
                        v-model="cMail"
                        class="form-control"
                        id="cMail"
                        placeholder="Enter your email address"
                      />
                    </div>
                    <div class="text-center">
                      <button
                        type="submit"
                        class="btn btn-primary register-btn w-100"
                        :disabled="!selfie || !cName || !cMail"
                      >
                        Register
                      </button>
                    </div>
                  </form>
                  <!-- <form class="p-4" v-on:submit.prevent="onRegister()">
                   
                    <div class="mb-2">
                      <label for="" class="pb-2">Profile Picture</label>
                      <div class="pb-2 pb-xxl-2">
                        <label for="input-file" id="drop-area">
                          <input
                            type="file"
                            accept="image/*"
                            id="input-file"
                            @change="preview"
                            hidden
                          />
                          <div id="img-view">
                            <div v-if="selfie">
                              <img
                                class="img-fluid"
                                style="
                                  width:250px;
                                  height:100px;
                                  border-radius: 8px;
                                "
                                :src="selfie"
                              />
                            </div>
                            <div v-else>
                              <img
                                src="@/assets/person.png"
                                alt=""
                                class="img-fluid"
                              />
                            </div>
                          </div>
                          <div
                            class="d-flex justify-content-end position-relative"
                            v-if="this.isSelfieUploaded && !this.selfie == ''"
                          >
                            <img
                              src="@/assets/trash.png"
                              class="img-fluid position-absolute"
                              alt=""
                              style="position: absolute;left: 79px;bottom:5px"
                              @click="removeImg"
                            />
                          </div>
                          <div
                            class="d-flex justify-content-end position-relative"
                            v-else
                          >
                            <img
                              src=""
                              class="img-fluid position-absolute"
                              alt=""
                              style="position: absolute; left: 79px;bottom:5px"
                              @click="preview(e)"
                            />
                          </div>
                        </label>
                        
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="name" class="form-label">Name</label>
                      <input
                        type="text"
                        v-model="changeName"
                        class="form-control"
                        placeholder="Enter your full name"
                        id="name"
                        required
                      />
                      <div v-if="isNameEntered" class="text-danger">
                        Enter Name is required.
                      </div>
                    </div>
                    <div class="sub mb-4">
                      Mobile Number:
                      <div class="d-flex pt-2">
                        <div class="col-auto pe-2">
                          <select class="form-select" id="autoSizingSelect" disabled>
                            <option selected>+91</option>
                            <option value="1">+44</option>
                            <option value="2">+66</option>
                            <option value="3">+88</option>
                          </select>
                        </div>
                        <div class="w-100">
                          <input
                            type="text"
                            v-model="changeNumber"
                            class="form-control"
                            id="autoSizingInput"
                            placeholder="Mobile Number"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="cMail" class="form-label"
                        >Email Address:</label
                      >
                      <input
                        type="email"
                        v-model="changeMail"
                        class="form-control"
                        id="changeMail"
                        placeholder="Enter your email address"
                      />
                    </div>
                    <div class="text-center  d-flex justify-content-evenly">
                      <button type="button" class="btn border-0 w-50 me-2" style="color:black;background:#F3F7FF">
                        BACK
                      </button>
                      <button type="submit" class="btn  border-0 w-50 ms-2" style="color:white;background:#FF6600">
                        SAVE
                      </button>
                    </div>
                  </form> -->
                </div>
              </div>
    </section>
  </div>
  <!-- <div>
    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            Policies & Conditions
          </button>
        </h2>
        <div
          id="collapseTwo"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body d-flex flex-column">
           
            <router-link to="/" class="text-decoration-none py-1 text-dark">Terms And Conditions</router-link>
             <router-link to="/" class="text-decoration-none py-1 text-dark">Privacy Policy</router-link>
            <router-link to="/" class="text-decoration-none py-1 text-dark">Refund Policys</router-link>
          </div>
        </div>
      </div>
    </div>
    <div>
      
    </div>
  </div> -->
 
  <!-- </div> -->
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
export default {
  name: "ProfileEdit",
   data() {
    return {
      selfie: "",
      cName: "",
      cNumber: this.$storage.getStorageSync('pnumber'),
      cMail: "",
     
      isNameEntered: "",
      submitted: false,
    };
   },

   watch: {
    cNumber(newcNumber) {
      this.$storage.setStorageSync("cNumber", newcNumber);
    },
    cName(newcName) {
      this.$storage.setStorageSync("cName", newcName);
    }
  },
   
 methods: {
    removeImg() {
      this.isSelfieUploaded = false;
      this.selfie = "";
    },
    preview(e) {
      this.isSelfieUploaded = true;
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.selfie = reader.result;
        console.log('result', reader.result)
      };
      reader.readAsDataURL(file);
    },

    onRegister() {
       axios
          .post(
            "https://speedrent.in/v2web/editProfile.php",
            {
              image: this.selfie,
              name: this.cName,
              number: this.cNumber,
              email: this.cMail,
            },
            {
              headers: {
                "Content-type":
                  "application/x-www-form-urlencoded;charset=utf-8",
              },
            }
          )
          .then((response) => {
            console.log(response);
            // Swal.fire({
            //   icon: "success",
            //   title: "Profile Updated Successfull!",
           
            //   showConfirmButton: false,
            //   timer: 2000,
            // })
            // .then(() => {
            //   this.$router.push({ path: "/" });
            // });
          })
          .catch((error) => {
            if (error.response) {
              console.log(
                "Server responded with status code:",
                error.response.status
              );
              console.log("Response data:", error.response.data);
            } else if (error.request) {
              console.log("No response received:", error.request);
            } else {
              console.log("Error creating request:", error.message);
            }
          });
    //   }
    },
  },
};
</script>

<style scoped>

@font-face {
  font-family: "Satoshi";
  src: url("~@/assets/fonts/Satoshi-Medium.ttf");
}
/* Web View */

.web .heading {
  color: #ffffff;
  font-family: "Satoshi";
  font-weight: 400;
}
.web .btn-primary {
  background: #ffffff;
  color: #ff6600;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-primary:hover {
  background: #ffffff;
  color: #ff6600;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-outline {
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-outline:hover {
  background: #000;
  border: none;
  color: #fff;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}

/* form */
form {
  background: #fff;
  border-radius: 10px;
}
form .register-btn {
  background: #ff6600 !important;
  color: #fff !important;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
form .register-btn:hover {
  background: #ffffff;
  color: #ff6600;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.form-label {
  color: #676c7d;
}
.sub {
  color: #676c7d;

}

.form-control {
  color: #20242d;
}
.container {
  box-shadow: 0px 0px 15px 0px #00000012;
}
.edit {
  color: #8f9daa;
}
.rounded {
  background: #ff6600;
  color: #ffffff;
  border: 0;
}
.alter-button {
  font-family: Inter;
  font-size: 20px;
}
.profile-img {
  position: relative;
}
.delete-icon {
  position: absolute;
  bottom: 0px;
  left: 90px;
}

#drop-area {
  width: 125px;
  height: 125px;
  padding:0px 10px;
  /* text-align: center; */
  border-radius: 8px;
  border: 1px solid #000;
}
#img-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
#img-view img {
  width: 100px;
  /* object-fit: cover; */
}
</style>