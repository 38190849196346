<template>
  <div class="main">
    <!-- Register ALERT Modal -->
    <div
      class="modal fade"
      id="registeralert"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body py-4">
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <div>
                <img src="@/assets/otpalert.png" alt="" class="img-fluid" />
              </div>
              <p class="text-center py-3">
                You have successfully Registerd.<br> Please Complete Your
                <span style="color: #ff6600">KYC</span>!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Web View -->
    <section class="web d-none d-lg-block">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-7 col-lg-6 first-sec">
            <div class="row">
              <div class="col-md-12">
                <div class="px-5 pt-5">
                  <img
                    src="@/assets/speedrentlogo.png"
                    alt=""
                    class="logo img-fluid"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex align-items-center justify-content-start pt-5"
                style=""
              >
                <div class="ps-xxl-5 ps-xl-2 pt-5 ps-0">
                  <img
                    src="@/assets/Hero.png"
                    alt=""
                    class="img-fluid hero-img ps-5"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-5 col-lg-6 second-sec">
            <div class="row">
              <div class="col-md-12">
                <!-- <router-link to="/">
                  <div class="px-5 pt-5 float-end">
                    <button type="button" class="btn btn-outline px-5">
                      Skip
                    </button>
                  </div>
                </router-link> -->
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 d-flex justify-content-center">
                <div class="content py-5">
                  <form class="p-4" v-on:submit.prevent="onRegister()">
                    <div class="row">
                      <div class="col-md-9">
                        <div>
                          <h5>Register your account</h5>
                          <p class="edit">
                            Create an account to easily rent a vehicle of your
                            choice.
                          </p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <!-- <div class="float-end">
                          <a href="/">
                            <img
                              src="@/assets/skipbtn.png"
                              alt=""
                              class="img-fluid"
                            />
                          </a>
                        </div> -->
                      </div>
                    </div>
                    <div class="mb-2">
                      <label for="" class="pb-2">Profile Picture</label>
                      <div class="pb-2 pb-xxl-2">
                        <label for="input-file" id="drop-area">
                          <input
                            type="file"
                            accept="image/*"
                            id="input-file"
                            @change="preview"
                            hidden
                          />
                          <div id="img-view">
                            <div v-if="selfie">
                              <img class="img-fluid imgs" :src="selfie" />
                            </div>
                            <div v-else>
                              <img
                                src="@/assets/person.png"
                                alt=""
                                class="img-fluid"
                              />
                            </div>
                          </div>
                        </label>
                        <div
                          v-if="!isSelfieUploaded && submitted"
                          class="text-danger"
                        >
                          Selfie is required.
                        </div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="name" class="form-label">Name</label>
                      <input
                        type="text"
                        v-model="cName"
                        class="form-control"
                        placeholder="Enter your full name"
                        id="name"
                        required
                      />
                    </div>
                    <div class="sub mb-4">
                      Mobile Number:
                      <div class="d-flex pt-2">
                        <div class="col-auto pe-2">
                          <select class="form-select" id="autoSizingSelect" disabled>
                            <option selected>+91</option>
                            <option value="1">+44</option>
                            <option value="2">+66</option>
                            <option value="3">+88</option>
                          </select>
                        </div>
                        <div class="w-100">
                          <input
                            type="text"
                            v-model="cNumber"
                            class="form-control"
                            id="autoSizingInput"
                            placeholder="Mobile Number"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="cMail" class="form-label"
                        >Email Address:</label
                      >
                      <input
                        type="email"
                        v-model="cMail"
                        class="form-control"
                        id="cMail"
                        placeholder="Enter your email address"
                        required
                      />
                    </div>
                    <div class="text-center">
                      <button
                        type="submit"
                        class="btn btn-primary register-btn w-100"
                        :disabled="!cName || !cMail"
                      >
                        Register
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- mobile view -->
    <section class="mobile d-lg-none">
      <div class="container-fluid p-2">
        <form class="p-5" v-on:submit.prevent="onRegister()">
                    <div class="row">
                      <div class="col-md-9">
                        <div>
                          <h5>Register your account</h5>
                          <p class="edit">
                            Create an account to easily rent a vehicle of your
                            choice.
                          </p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <!-- <div class="float-end">
                          <a href="/">
                            <img
                              src="@/assets/skipbtn.png"
                              alt=""
                              class="img-fluid"
                            />
                          </a>
                        </div> -->
                      </div>
                    </div>
                    <div class="mb-2">
                      <label for="" class="pb-2">Profile Picture</label>
                      <div class="pb-2 pb-xxl-2">
                        <label for="input-file" id="drop-area1">
                          <input
                            type="file"
                            accept="image/*"
                            id="input-file"
                            @change="preview"
                            hidden
                          />
                          <div id="img-view1">
                            <div v-if="selfie">
                              <img class="img-fluid imgs" :src="selfie" />
                            </div>
                            <div v-else>
                              <img
                                src="@/assets/person.png"
                                alt=""
                                class="img-fluid"
                              />
                            </div>
                          </div>
                        </label>
                        <div
                          v-if="!isSelfieUploaded && submitted"
                          class="text-danger"
                        >
                          Selfie is required.
                        </div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="name" class="form-label">Name</label>
                      <input
                        type="text"
                        v-model="cName"
                        class="form-control"
                        placeholder="Enter your full name"
                        id="name"
                        required
                      />
                    </div>
                    <div class="sub mb-4">
                      Mobile Number:
                      <div class="d-flex pt-2">
                        <div class="col-auto pe-2">
                          <select class="form-select" id="autoSizingSelect" disabled>
                            <option selected>+91</option>
                            <option value="1">+44</option>
                            <option value="2">+66</option>
                            <option value="3">+88</option>
                          </select>
                        </div>
                        <div class="w-100">
                          <input
                            type="text"
                            v-model="cNumber"
                            class="form-control"
                            id="autoSizingInput"
                            placeholder="Mobile Number"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="cMail" class="form-label"
                        >Email Address:</label
                      >
                      <input
                        type="email"
                        v-model="cMail"
                        class="form-control"
                        id="cMail"
                        placeholder="Enter your email address"
                        required
                      />
                    </div>
                    <div class="text-center">
                      <button
                        type="submit"
                        class="btn btn-primary register-btn w-100"
                        :disabled="!cName || !cMail"
                      >
                        Register
                      </button>
                    </div>
                  </form>
      </div>
    </section>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      selfie: "",
      cName: "",
      cNumber: this.$storage.getStorageSync('pnumber'),
      cMail: "",
      isSelfieUploaded: "",
      submitted: false,
    };
  },
  watch: {
    selfie(newSelfie) {
      this.$storage.setStorageSync("selfie", newSelfie);
    },
    cNumber(newcNumber) {
      this.$storage.setStorageSync("cNumber", newcNumber);
    },
    cName(newcName) {
      this.$storage.setStorageSync("cName", newcName);
    }
  },

  methods: {
    preview(e) {
      this.isSelfieUploaded = true;
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.selfie = reader.result;
        // console.log('result', reader.result)
      };
      reader.readAsDataURL(file);
    },

    onRegister() {
      // console.log(this.$storage.getStorageSync('selfie').split(',')[1])
      // console.log(this.$storage.getStorageSync('cNumber'))

      // console.log(this.$storage.getStorageSync("cName"));


      this.submitted = true;
      if (!this.selfie) {
        this.isSelfieUploaded = false;
      }

      if (this.selfie) {
        this.isSelfieUploaded = true;
      

      axios
        .post(
          "https://speedrent.in/v2web/registerUser.php",
          {
            selfie: this.selfie.split(',')[1],
            cName: this.cName,
            cNumber: this.cNumber,
            cMail: this.cMail,
          },
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }
        )
        .then((response) => {
          // console.log(response);
          this.$storage.setStorageSync('register', response.data.message);
          $("#registeralert").modal("show");
              setTimeout(function () {
                $("#registeralert").hide();
                $(".modal-backdrop").remove();
              }, 2500)
          this.$router.push({ path: "/KYC-1" });
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "Server responded with status code:",
              error.response.status
            );
            console.log("Response data:", error.response.data);
          } else if (error.request) {
            console.log("No response received:", error.request);
          } else {
            console.log("Error creating request:", error.message);
          }
        });

      }

    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Satoshi";
  src: url("~@/assets/fonts/Satoshi-Medium.ttf");
}
.main {
  overflow-x: hidden;
}
@media (max-width: 1200px) {
  .hero-img {
    max-width: 350px !important;
  }
}
/* Web View */
.web {
  background: #f5d8c5;
}
@media (min-width: 1400px) {
  .web .first-sec {
    background-image: url("@/assets/Intersect2.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
  }
}
@media (max-width: 1399.98px) {
  .web .first-sec {
    background-image: url("@/assets/Intersect3.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: auto;
  }
}
.web .heading {
  color: #ffffff;
  font-family: "Satoshi";
  font-weight: 400;
}
.web .btn-primary {
  background: #ffffff;
  color: #ff6600;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-primary:hover {
  background: #ffffff;
  color: #ff6600;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-outline {
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-outline:hover {
  background: #000;
  border: none;
  color: #fff;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
/* Mobile View */
.mobile {
  background: #f5d8c5;
}
.mobile .first-sec {
  background: url("@/assets/news/mmm.png");
  background-repeat: no-repeat;
  background-position: left 100%;
  background-size: contain;
  background-size: 100% 100%;
  height: 85vh;
}
.mobile .second-sec {
  height: 35vh;
}
.mobile .heading {
  color: #ffffff;
  font-family: "Satoshi";
  font-weight: 400;
}
.mobile .btn-primary {
  background: #ffffff;
  color: #ff6600;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.mobile .btn-primary:hover {
  background: #ffffff;
  color: #ff6600;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.mobile .btn-outline {
  background-color: transparent;
  border: 1px solid #f7e6db;
  color: #f7e6db;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.mobile .btn-outline:hover {
  background: #f7e6db;
  border: none;
  color: green;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
/* form */
form {
  background: #fff;
  border-radius: 10px;
}
form .register-btn {
  background: #ff6600 !important;
  color: #fff !important;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
form .register-btn:hover {
  background: #ffffff;
  color: #ff6600;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.form-label {
  color: #676c7d;
}
.sub {
  color: #676c7d;
}

.form-control {
  color: #20242d;
}
.container {
  box-shadow: 0px 0px 15px 0px #00000012;
}
.edit {
  color: #8f9daa;
}
.rounded {
  background: #ff6600;
  color: #ffffff;
  border: 0;
}
.alter-button {
  font-family: Inter;
  font-size: 20px;
}
.profile-img {
  position: relative;
}
.delete-icon {
  position: absolute;
  bottom: 0px;
  left: 90px;
}

#drop-area {
  width: 100px;
  height: 100px;
  padding: 30px;
  /* text-align: center; */
  border-radius: 8px;
  border: 1px solid #000;
}
#drop-area1 {
  width: 100px;
  height: 100px;
  padding: 30px;
  /* text-align: center; */
  border-radius: 8px;
  border: 1px solid #000;
}
#img-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
#img-view img {
  width: 300px;
  /* object-fit: cover; */
}

#img-view1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
#img-view1 img {
  width: 300px;
  /* object-fit: cover; */
}

.imgs {
  object-fit: cover;
  height: 80px;
}
</style>
