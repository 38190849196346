<template>
  <div class="main">

        <!-- Logout Modal -->
<div class="modal fade" id="logoutmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container py-2">
          <div class="d-flex flex-column align-items-center justify-content-center">
            <img src="@/assets/logout.png" alt="" class="img-fluid">
            <h5>Do you want to logout?</h5>
            <p class="text-center">Confirmation prompt to log out from the current session securely</p>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <div class="pe-2 w-100">
              <button type="button" class="btn btn-primary w-100" v-on:click="No()">No</button>
            </div>
            <div class="ps-2 w-100">
              <button type="button" class="btn btn-outline-primary w-100" v-on:click="Yes()">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    <!-- Logout Modal -->
    <div class="container px-5 py-2">

<!-- Profile Edit -->
    <div
      class="modal fade profile"
      id="profilemodal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">My Profile</h5>
            <button
              type="button"
              class="btn-close btn-close-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="content pb-2">
              <form class="p-4" v-on:submit.prevent="onEditPro()" >
                    <div class="mb-2">
                      <label for="" class="pb-2">Profile Picture</label>
                      <div class="pb-2 pb-xxl-2">
                        <label for="input-file" id="drop-area">
                          <input
                            type="file"
                            accept="image/*"
                            id="input-file"
                            @change="preview"
                            hidden
                          />
                          <div id="img-view">
                            <div v-if="selfie">
                              <img class="img-fluid imgs" :src="selfie" />
                            </div>
                            <div v-else>
                              <img
                                v-bind:src="`https://speedrent.in/adminapp/uploads/${userDetails.number}.jpg`"
                                alt=""
                                class="img-fluid imgs"
                              />
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="name" class="form-label">Name</label>
                      <input
                        type="text"
                        v-model="userDetails.name"
                        class="form-control"
                        placeholder="Enter your full name"
                        id="name"
                        required
                      />
                    </div>
                    <div class="sub mb-4">
                      Mobile Number:
                      <div class="d-flex pt-2">
                        <div class="col-auto pe-2">
                          <select class="form-select" id="autoSizingSelect" disabled>
                            <option selected>+91</option>
                            <option value="1">+44</option>
                            <option value="2">+66</option>
                            <option value="3">+88</option>
                          </select>
                        </div>
                        <div class="w-100">
                          <input
                            type="text"
                            v-model="userDetails.number"
                            class="form-control"
                            id="autoSizingInput"
                            placeholder="Mobile Number"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="cMail" class="form-label"
                        >Email Address:</label
                      >
                      <input
                        type="email"
                        v-model="userDetails.email"
                        class="form-control"
                        id="cMail"
                        placeholder="Enter your email address"
                      />
                    </div>
                    <div class="text-center">
                      <button
                        type="submit"
                        class="btn btn-primary register-btn w-100"
                      >
                        Edit Profile
                      </button>
                    </div>
                  </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Profile Edit -->

      <!-- Navbar -->
      <div class="container-fluid">
       <nav
          class="navbar navbar-expand-md navbar-light bg-light fixed-top d-none d-md-block bg-info"
          v-if="
            $route.path !== '/login' &&
            $route.path !== '/signin' &&
            $route.path !== '/otp-verification' &&
            $route.path !== '/KYC-1' &&
            $route.path !== '/KYC-2'
          "
          style="border: 0.1px solid #f1ecec"
        >
          <div class="d-flex align-items-center">
            <a class="navbar-brand ps-lg-5" href="/">
              <img
                src="@/assets/speedrentlogo.png"
                alt=""
                class="logo img-fluid"
              />
            </a>
            <button
              class="navbar-toggler shadow-none border-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="offcanvas offcanvas-start"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                  Features
                </h5>
                <button
                  type="button"
                  class="btn-close shadow-none"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                <ul class="navbar-nav justify-content-center flex-grow-1">
                  <li class="nav-item mx-xl-3 mx-md-2">
                    <a href="#blogs" class="nav-link">Blogs</a>
                  </li>
                  <!-- <li class="nav-item mx-xl-3 mx-md-2">
                    <a href="" class="nav-link">Testimonials</a>
                  </li> -->
                  <li class="nav-item mx-xl-3 mx-md-2">
                    <a href="#FAQs" class="nav-link">FAQ's</a>
                  </li>
                  <li class="nav-item mx-xl-3 mx-md-2">
                    <a href="#contact-us" class="nav-link">Contact Us</a>
                  </li>
                  <li>
                    <div
                      v-if="
                        this.$storage.getStorageSync('message') == 'verified'
                      "
                    >
                      <div class="d">
                        <a href="/" class="nav-btn">
                          <div class="download">
                            <button
                              type="button"
                              class="btn btn-download btn-txt"
                            >
                              Download App
                            </button>
                          </div></a
                        >
                      </div>
                    </div>
                    <div v-else>
                      <div class="d">
                          <div class="download">
                            <button
                              type="button"
                              class="btn btn-download btn-txt"
                              style="border: none;"
                            >
                            <a href="/signin" class="nav-btn text-white">
                              Login
                            </a><span class="text-white">/</span>
                            <a href="/signin" class="nav-btn text-white">
                              SignUp
                            </a>
                            </button>
                          </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="d-lg-flex">
                  <div class="sec-nav">
                    <div>
                      <button
                        class="btn"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#right"
                        aria-controls="offcanvasRight"
                        style="border: none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-list"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <!-- Web-Toggle-Button -->
        <div class="d-none d-md-block">
          <div
            class="offcanvas offcanvas-end"
            tabindex="-1"
            id="right"
            aria-labelledby="offcanvasRightLabel"
          >
            <div class="offcanvas-header">
              <img src="@/assets/speedrentlogo 1.png" alt="" />
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body">
              <div class="row">
                <div class="col 12">
                  <div class="bookfont">
                    <div class="row gap-0 border-bottom">
                      <div class="col-2">
                        <div
                          v-if="
                            this.$storage.getStorageSync('message') ==
                            'verified'
                          "
                        >
                        <img
                          v-bind:src="`https://speedrent.in/adminapp/uploads/${userDetails.number}.jpg`"
                          class="img-fluid"
                          style="height: 56px; width: 56px; object-fit:cover;"
                        />
                        </div>
                        <div v-else>
                          <div class="pt-1">
                            <img src="@/assets/Profile.png" alt="" class="">
                          </div>
                        </div>
                      </div>
                      <div class="col-7 text-start pt-2">
                        <div
                          v-if="
                            this.$storage.getStorageSync('message') ==
                            'verified'
                          "
                        >
                          <h6 class="m-0">{{ userDetails.name }}</h6>
                          <p style="color: #ff6600">
                            +91 {{ this.$storage.getStorageSync("pnumber") }}
                          </p>
                        </div>
                        <div v-else>
                          <h6 class="m-0">Welcome</h6>
                          <p style="color: #ff6600">Please Login..</p>
                        </div>
                      </div>
                      <div class="col-3 pt-2">
                        <div
                          v-if="
                            this.$storage.getStorageSync('message') ==
                            'verified'
                          "
                        >
                          <div v-on:click="editProfile()">
                            <div
                              class="rounded px-1 py-1"
                              style="color: white; background: #ff6600"
                            >
                              <div class="d-flex">
                                <div class="">
                                  <img src="@/assets/pen-circle 1.svg" alt="" />
                                </div>
                                <div class="ps-1 pt-1">EDIT</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-4">
                      <div
                        v-if="
                          this.$storage.getStorageSync('message') == 'verified'
                        "
                      >
                        <a href="/rides">
                          <p>
                            <img src="@/assets/book1.png" alt="" />
                            <span class="ps-1"> My Bookings</span>
                          </p>
                        </a>
                      </div>
                      <div v-else>
                        <a href="/signin">
                          <p>
                            <img src="@/assets/book1.png" alt="" />
                            <span class="ps-1"> My Bookings</span>
                          </p>
                        </a>
                      </div>
                    </div>
                    <div>
                      <div
                        v-if="
                          this.$storage.getStorageSync('message') == 'verified'
                        "
                      >
                        <a href="/wishlist">
                          <p>
                            <img src="@/assets/heart.png" alt="" />
                            <span class="ps-1"> Wishlist</span>
                          </p>
                        </a>
                      </div>
                      <div v-else>
                        <a href="/signin">
                          <p>
                            <img src="@/assets/heart.png" alt="" />
                            <span class="ps-1"> Wishlist</span>
                          </p>
                        </a>
                      </div>
                    </div>
                    <div
                      v-if="
                        this.$storage.getStorageSync('message') == 'verified'
                      "
                    >
                      <div v-if="this.kycDone === 'true'">
                        <p>
                          <img src="@/assets/kycimage.png" alt="" />
                          <span class="ps-2 pe-2">KYC</span>
                          <label
                            style="
                              background-color: #00b950;
                              color: white;
                              border-radius: 5px;
                            "
                            class="br-0 px-1"
                          >
                            Verfication Success
                          </label>
                        </p>
                      </div>
                      <div v-else>
                        <div
                          v-if="
                            this.$storage.getStorageSync('register') ==
                            'Customer added successfully..!'
                          "
                        >
                          <a href="/KYC-1">
                            <p>
                              <img src="@/assets/kycimage.png" alt="" />
                              <span class="ps-2 pe-2">KYC</span>
                              <label
                                style="
                                  background-color: #fea53f;
                                  color: white;
                                  border-radius: 5px;
                                "
                                class="br-0 px-1"
                              >
                                Verfication Pending
                              </label>
                            </p>
                          </a>
                        </div>

                        <div v-else>
                          <a href="/signup">
                            <p>
                              <img src="@/assets/kycimage.png" alt="" />
                              <span class="ps-2 pe-2">KYC</span>
                              <label
                                style="
                                  background-color: #fea53f;
                                  color: white;
                                  border-radius: 5px;
                                "
                                class="br-0 px-1"
                              >
                                Verfication Pending
                              </label>
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <a href="/signin">
                        <p>
                          <img src="@/assets/kycimage.png" alt="" />
                          <span class="ps-2 pe-2">KYC</span>
                          <label
                            style="
                              background-color: #fea53f;
                              color: white;
                              border-radius: 5px;
                            "
                            class="br-0 px-1"
                          >
                            Verfication Pending
                          </label>
                        </p>
                      </a>
                    </div>
                    <div>
                      <a href="/terms&conditions">
                        <p>
                          <img src="@/assets/policesimage.png" alt="" />
                          <span class="ps-2">Terms & Conditions</span>
                        </p>
                      </a>
                    </div>
                    <div>
                      <a href="/privacy-policy">
                        <p>
                          <img src="@/assets/policesimage.png" alt="" />
                          <span class="ps-2">Privacy Policy</span>
                        </p>
                      </a>
                    </div>
                    <div>
                      <a href="/refund&cancellations">
                        <p>
                          <img src="@/assets/policesimage.png" alt="" />
                          <span class="ps-2">Refund & Cancellations</span>
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="offcanvas-footer p-3">
              <div
                class="logbutton"
                v-if="this.$storage.getStorageSync('message') == 'verified'"
              >
                <button
                  @click.prevent="Logout()"
                  class="btn btn-primary w-100 b-0"
                  type="button"
                  style="background: #f3fbfe; color: #1291ee"
                >
                  <span class="float-start">Logout</span>
                  <span class="float-end">
                    <img
                      src="@/assets/form-logout.png"
                      alt=""
                      class="img-fluid"
                    />
                  </span>
                </button>
              </div>
              <div v-else>
                <a href="/signin">
                  <button
                    class="btn btn-primary w-100 b-0"
                    type="button"
                    style="background: #ff6600; color: #fff; border: none"
                  >
                    <span class="text-center">Login</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- Web-Toggle-Button -->
        <!-- Mobile-Toggle-Button -->
        <div class="nav2 pt-3 d-md-none fixed-top navbar-light bg-light">
          <div class="container-fluid">
            <div class="row">
              <div class="col-8">
                <div v-if="$route.path == '/bookings'">
                  <div class="d-flex">
                    <div>
                      <a href="/">
                        <div style="background: #fff; border: none">
                          <img
                            src="@/assets/wishlist/wishlistarrow.svg"
                            alt=""
                            class="img-fluid"
                          />
                        </div>
                      </a>
                    </div>
                    <div class="ps-1">
                      <select
                        class="form-select"
                        v-model="branch"
                        aria-label="Default select example"
                        style="border: none"
                      >
                        <option value="majestic" selected>
                          Bengaluru - Majestic
                        </option>
                        <option value="nayabazar">Cuttack - Naya bazar</option>
                        <option value="PATIA">Bhubaneshwar - Patia</option>
                        <option value="JAGAMARA">
                          Bhubaneshwar - Jagamara
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <a class="navbar-brand ps-lg-5" href="/">
                    <img
                      src="@/assets/speedrentlogo.png"
                      alt=""
                      class="logo img-fluid pb-2"
                      style="width: 56px !important"
                    />
                  </a>
                </div>
              </div>
              <div class="col-4">
                <div class="float-end">
                  <div class="sec-nav pe-md-5">
                    <div>
                      <button
                        class="btn"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                        style="border: none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-list"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                          />
                        </svg>
                      </button>

                      <div
                        class="offcanvas offcanvas-start"
                        tabindex="-1"
                        id="offcanvasRight"
                        aria-labelledby="offcanvasRightLabel"
                      >
                        <div class="offcanvas-header">
                          <img src="@/assets/speedrentlogo 1.png" alt="" />
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="offcanvas-body">
                          <div class="row">
                            <div class="col 12">
                              <div class="bookfont">
                                <div class="row gap-0 border-bottom">
                                  <div class="col-2">
                                    <div
                          v-if="
                            this.$storage.getStorageSync('message') ==
                            'verified'
                          "
                        >
                        <img
                          v-bind:src="`https://speedrent.in/adminapp/uploads/${userDetails.number}.jpg`"
                          class="img-fluid"
                          style="height: 56px; width: 56px; object-fit:cover;"
                        />
                        </div>
                        <div v-else>
                          <div class="pt-1">
                            <img src="@/assets/Profile.png" alt="" class="">
                          </div>
                        </div>
                                  </div>
                                  <div class="col-7 text-start pt-2">
                                    <div
                                      v-if="
                                        this.$storage.getStorageSync(
                                          'message'
                                        ) == 'verified'
                                      "
                                    >
                                      <h6 class="m-0">
                                        {{ userDetails.name }}
                                      </h6>
                                      <p style="color: #ff6600">
                                        +91
                                        {{
                                          this.$storage.getStorageSync(
                                            "pnumber"
                                          )
                                        }}
                                      </p>
                                    </div>
                                    <div v-else>
                                      <h6 class="m-0">Welcome</h6>
                                      <p style="color: #ff6600">
                                        Please Login..
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-3 pt-2">
                                    <div
                                      v-if="
                                        this.$storage.getStorageSync(
                                          'message'
                                        ) == 'verified'
                                      "
                                    >
                                      <div v-on:click="editProfile()">
                                        <div
                                          class="rounded px-1 py-1"
                                          style="
                                            color: white;
                                            background: #ff6600;
                                          "
                                        >
                                          <div class="d-flex">
                                            <div class="">
                                              <img
                                                src="@/assets/pen-circle 1.svg"
                                                alt=""
                                              />
                                            </div>
                                            <div class="ps-1 pt-1">EDIT</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="mt-4">
                                  <div
                                    v-if="
                                      this.$storage.getStorageSync('message') ==
                                      'verified'
                                    "
                                  >
                                    <a href="/rides">
                                      <p>
                                        <img src="@/assets/book1.png" alt="" />
                                        <span class="ps-1"> My Bookings</span>
                                      </p>
                                    </a>
                                  </div>
                                  <div v-else>
                                    <a href="/signin">
                                      <p>
                                        <img src="@/assets/book1.png" alt="" />
                                        <span class="ps-1"> My Bookings</span>
                                      </p>
                                    </a>
                                  </div>
                                </div>
                                <div>
                                  <div
                                    v-if="
                                      this.$storage.getStorageSync('message') ==
                                      'verified'
                                    "
                                  >
                                    <a href="/wishlist">
                                      <p>
                                        <img src="@/assets/heart.png" alt="" />
                                        <span class="ps-1"> Wishlist</span>
                                      </p>
                                    </a>
                                  </div>
                                  <div v-else>
                                    <a href="/signin">
                                      <p>
                                        <img src="@/assets/heart.png" alt="" />
                                        <span class="ps-1"> Wishlist</span>
                                      </p>
                                    </a>
                                  </div>
                                </div>

                                <div
                                  v-if="
                                    this.$storage.getStorageSync('message') ==
                                    'verified'
                                  "
                                >
                                  <div v-if="this.kycDone === 'true'">
                                    <p>
                                      <img src="@/assets/kycimage.png" alt="" />
                                      <span class="ps-2 pe-2">KYC</span>
                                      <label
                                        style="
                                          background-color: #00b950;
                                          color: white;
                                          border-radius: 5px;
                                        "
                                        class="br-0 px-1"
                                      >
                                        Verfication Success
                                      </label>
                                    </p>
                                  </div>
                                  <div v-else>
                                    <div
                                      v-if="
                                        this.$storage.getStorageSync(
                                          'register'
                                        ) == 'Customer added successfully..!'
                                      "
                                    >
                                      <a href="/KYC-1">
                                        <p>
                                          <img
                                            src="@/assets/kycimage.png"
                                            alt=""
                                          />
                                          <span class="ps-2 pe-2">KYC</span>
                                          <label
                                            style="
                                              background-color: #fea53f;
                                              color: white;
                                              border-radius: 5px;
                                            "
                                            class="br-0 px-1"
                                          >
                                            Verfication Pending
                                          </label>
                                        </p>
                                      </a>
                                    </div>

                                    <div v-else>
                                      <a href="/signup">
                                        <p>
                                          <img
                                            src="@/assets/kycimage.png"
                                            alt=""
                                          />
                                          <span class="ps-2 pe-2">KYC</span>
                                          <label
                                            style="
                                              background-color: #fea53f;
                                              color: white;
                                              border-radius: 5px;
                                            "
                                            class="br-0 px-1"
                                          >
                                            Verfication Pending
                                          </label>
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div v-else>
                                  <a href="/signin">
                                    <p>
                                      <img src="@/assets/kycimage.png" alt="" />
                                      <span class="ps-2 pe-2">KYC</span>
                                      <label
                                        style="
                                          background-color: #fea53f;
                                          color: white;
                                          border-radius: 5px;
                                        "
                                        class="br-0 px-1"
                                      >
                                        Verfication Pending
                                      </label>
                                    </p>
                                  </a>
                                </div>
                                <div>
                                  <a href="/terms&conditions">
                                    <p>
                                      <img
                                        src="@/assets/policesimage.png"
                                        alt=""
                                      />
                                      <span class="ps-2"
                                        >Terms & Conditions</span
                                      >
                                    </p>
                                  </a>
                                </div>
                                <div>
                                  <a href="/privacy-policy">
                                    <p>
                                      <img
                                        src="@/assets/policesimage.png"
                                        alt=""
                                      />
                                      <span class="ps-2">Privacy Policy</span>
                                    </p>
                                  </a>
                                </div>
                                <div>
                                  <a href="/refund&cancellations">
                                    <p>
                                      <img
                                        src="@/assets/policesimage.png"
                                        alt=""
                                      />
                                      <span class="ps-2"
                                        >Refund & Cancellations</span
                                      >
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="offcanvas-footer p-3">
                          <div
                            class="logbutton"
                            v-if="
                              this.$storage.getStorageSync('message') ==
                              'verified'
                            "
                          >
                            <button
                              @click.prevent="Logout()"
                              class="btn btn-primary w-100 b-0"
                              type="button"
                              style="background: #f3fbfe; color: #1291ee"
                            >
                              <span class="float-start">Logout</span>
                              <span class="float-end">
                                <img
                                  src="@/assets/form-logout.png"
                                  alt=""
                                  class="img-fluid"
                                />
                              </span>
                            </button>
                          </div>
                          <div v-else>
                            <a href="/signin">
                              <button
                                class="btn btn-primary w-100 b-0"
                                type="button"
                                style="
                                  background: #ff6600;
                                  color: #fff;
                                  border: none;
                                "
                              >
                                <span class="text-center">Login</span>
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Mobile-Toggle-Button -->
      </div>
      <!-- Navbar -->
                <div style="margin-top: 110px;">
                  <div class="container px-md-5 px-0 py-md-5">
                <div class="p-0">
            <a href="/">
            <i class="fa-solid fa-circle-arrow-left" style="font-size: 30px;"></i>
            </a></div>
          <div>
            <h3 class="text-center pb-4">Privacy Policy</h3>
          </div>
                <div>
                  <p>
                    Speed Rent (“us”, “we”, or “our”) operates the
                    https://speedrent.in/ Website/App and the Speed Rent mobile
                    application (the “Service”).
                  </p>
                  <p>
                    Speed Rent LLP is a company duly registered and incorporated
                    under the companies Act,2008. It is registered whose address
                    is at no. E-43/1, Pocket D, Okhla Phase II, Okhla Industrial
                    Area, New Delhi, Delhi 110020.
                  </p>
                  <p>
                    Speed Rent LLP owns and operates https://speedrent.in/ that
                    is a Website / App for booking two wheelers on rent/lease
                    for short term as well as for long term.
                  </p>
                  <p>
                    This document is published and shall be construed in
                    accordance with the provisions of the Information Technology
                    (reasonable security practices and procedures and sensitive
                    personal data of information) rules, 2011 under Information
                    Technology Act, 2000; that require publishing of the Privacy
                    Policy for collection, use, storage and transfer of
                    sensitive personal data or information.
                  </p>
                  <p>
                    Please read this Privacy Policy carefully by using the
                    Website / App, you indicate that you understand, agree and
                    consent to this Privacy Policy. If you do not agree with the
                    terms of this Privacy Policy, please do not use this Website
                    / App.
                  </p>
                  <p>
                    By providing us your Information or by making use of the
                    facilities provided by the Website / App, You hereby consent
                    to the collection, storage, processing and transfer of any
                    or all of Your Personal Information and Non-Personal
                    Information by us as specified under this Privacy Policy.
                    You further agree that such collection, use, storage and
                    transfer of Your Information shall not cause any loss or
                    wrongful gain to you or any other person.
                  </p>
                </div>
                <h3>
                  By registering at Gobikes you agree to the following terms and
                  conditions:
                </h3>
                <div>
                  <p>
                    1. Speed Rent LLP operates https://speedrent.in/ which is a
                    platform for renting two-wheelers. In order to book
                    two-wheelers from our platform, Customers need to signup or
                    log in by providing details like Full name, Mobile number,
                    Email id, Password, and Emergency contact number along with
                    the relationship, and blood type. By registering at Speed
                    Rent, You accept all the Terms and conditions and the
                    privacy policy and you take full responsibility for every
                    activities occurred under your name.
                  </p>
                  <p>
                    2. We expect to keep your personal data safe and suggest you
                    not to share it with anyone. You will not pretend to be
                    someone else or create fake accounts or multiple accounts.
                  </p>
                  <p>3. Information Collection And Use</p>
                  <p>
                    We collect several different types of information for
                    various purposes to provide and improve our Service to you.
                  </p>
                  <p>4. Types of Data Collected</p>
                  <p>Personal Data</p>
                  <p>
                    While using our Service, we may ask you to provide us with
                    certain personally identifiable information that can be used
                    to contact or identify you (“Personal Data”). Personally
                    identifiable information may include, but is not limited to:
                  </p>
                  <p>i. Email address</p>
                  <p>ii. First name and last name</p>
                  <p>iii. Phone number</p>
                  <p>iv. Employer</p>
                  <p>v. College Name</p>
                  <p>vi. Address, State, Province, ZIP/Postal code, City</p>
                  <p>vii. Cookies and Usage Data</p>
                  <p>viii. Usage Data</p>
                  <p>
                    5. Unique identifiers (such as membership number or email
                    addresses) are collected from Website / App visitors to
                    verify the user's identity and for use as account numbers in
                    our record system. Financial information (such as their bank
                    account or credit card numbers) is collected and used where
                    appropriate to bill the customer for services.
                  </p>
                  <p>
                    i. You can update your personal details by accessing your
                    account Profile page and making any necessary changes; this
                    will update your details across all services that you have
                    or will use at Speed Rent.
                  </p>
                  <p>
                    ii. Speed Rent will not use or share the Personally
                    Identifiable Information collected on our Website / App in
                    ways unrelated to the purpose for which you provided the
                    information without providing you with a choice whether to
                    permit any such unrelated uses. Speed Rent does not sell,
                    trade, or rent to others the Personally Identifiable
                    Information we collect.
                  </p>
                  <p>
                    iii. Speed Rent seeks to ensure that your information and
                    preferences are accurate and complete and ensure that we
                    don't hold personal information any longer than is
                    necessary. If you wish to view or change your information,
                    where possible you may do this online. Alternatively by
                    sending an email with your name, full mailing address, email
                    address, and relevant information about subscriptions and
                    registrations you have with us to the customer support team
                    at speedrentbikes@gmail.com.
                  </p>
                  <p>
                    iv.We collect Personally Identifiable Information when you
                    ask to receive e-newsletters, promotional emails, and other
                    information. We use this information to provide you with the
                    information you requested. You may opt-out of receiving
                    future mailings dropping us an email at
                    speedrentbikes@gmail.com.
                  </p>
                  <p>
                    v.Our primary goal in collecting information is to provide
                    customers with a better service and to provide all visitors
                    a smooth, efficient, and personalised experience while using
                    our Website / App. If you contact us, we may keep a record
                    of your correspondence or comments. We use this information
                    to help us provide better service in the event that you
                    contact us again.
                  </p>
                  <p>
                    vi.To use some of our services such as the 'shopping cart'
                    and the log on process, we use cookies. A 'cookie' is a
                    small data file created by your web browser and stored on
                    your computer's hard drive. Some of the information
                    collected also permits us to analyse traffic patterns on our
                    Website / App. This can enable us over time to provide you
                    with a better experience on our Website / App by improving
                    the content and making it easier to use.
                  </p>
                  <p>
                    vii.These terms and/or your use of the Website / App shall
                    be governed by and construed in accordance with Indian law
                    and the Indian Courts shall have exclusive jurisdiction over
                    any dispute which may arise.
                  </p>
                  <p>
                    viii.Speed Rent acknowledges your trust and is committed to
                    protecting the information you provide. To prevent
                    unauthorised access, maintain accuracy, and ensure proper
                    use of information, we employ physical, electronic, and
                    managerial processes to safeguard and secure the information
                    we collect online. Our site has security measures in place
                    to protect against the loss, misuse and alteration of the
                    information under our control. These include but are not
                    limited to a secured database containing personal data. The
                    database is located off the main web server and is not
                    accessible directly. Whilst we will use all reasonable
                    efforts to safeguard your personal data, you acknowledge
                    that the use of the internet is not entirely secure and for
                    this reason we cannot guarantee the security or integrity of
                    any personal data in transit from you or to you via the
                    internet.
                  </p>
                  <p>
                    ix.If you breach these or any of our other terms and
                    conditions we reserve the right to close your account, if we
                    do so, we may close all accounts you have open in your name.
                  </p>
                </div>
                <h3>USER INFORMATION</h3>
                <div>
                  <p>
                    6.To avail certain services on our App, users are required
                    to provide certain information for the registration process
                    namely:
                  </p>
                  <p>1. Name</p>
                  <p>2. Email address</p>
                  <p>3. Sex</p>
                  <p>4. Age</p>
                  <p>5. PIN code</p>
                  <p>6. Credit card or Debit card details</p>
                  <p>7. Medical records and history</p>
                  <p>8. Sexual orientation</p>
                  <p>9. Biometric information</p>
                  <p>10. Password</p>
                  <p>11. Occupation, interests, and the like</p>
                  <p>
                    The Information as supplied by the users enables us to
                    improve our sites and provide you the most user-friendly
                    experience. All required information is service dependent
                    and we may use the above said user information to, maintain,
                    protect, and improve its services (including advertising
                    services) and for developing new services Such information
                    will not be considered as sensitive if it is freely
                    available and accessible in the public domain or is
                    furnished under the Right to Information Act, 2005 or any
                    other law for the time being in force.
                  </p>
                </div>
                <h3>COOKIES</h3>
                <div>
                  <p>
                    To improve the responsiveness of the sites for our users, we
                    may use “cookies” or similar electronic tools to collect
                    information to assign each visitor a unique, random number
                    as a User Identification (User ID) to understand the user's
                    individual interests using the Identified Computer. Unless
                    you voluntarily identify yourself (through registration, for
                    example), we will have no way of knowing who you are, even
                    if we assign a cookie to your computer. The only personal
                    information a cookie can contain is information you supply
                    (an example of this is when you ask for our Personalised
                    Horoscope). A cookie cannot read data off your hard drive.
                    Our advertisers may also assign their own cookies to your
                    browser (if you click on their ads), a process that we do
                    not control.
                  </p>
                  <p>
                    Our web servers automatically collect limited information
                    about your computer's connection to the Internet,including
                    your IP address, when you visit our site. (Your IP address
                    is a number that lets computers attached to the Internet
                    know where to send you data — such as the web pages you
                    view.) Your IP address does not identify you personally. We
                    use this information to deliver our web pages to you upon
                    request, to tailor our site to the interests of our users,
                    to measure traffic within our site and let advertisers know
                    the geographic locations from where our visitors come.
                  </p>
                </div>
                <h3>INFORMATION SHARING</h3>
                <div>
                  <p>
                    We shares the sensitive personal information to any third
                    party without obtaining the prior consent of the user in the
                    following limited circumstances:
                  </p>
                  <p>
                    When it is requested or required by law or by any court or
                    governmental agency or authority to disclose,for the purpose
                    of verification of identity, or for the prevention,
                    detection, investigation including cyber incidents, or for
                    prosecution and punishment of offences. These disclosures
                    are made in good faith and belief that such disclosure is
                    reasonably necessary for enforcing these Terms; for
                    complying with the applicable laws and regulations.
                  </p>
                  <p>
                    We proposes to share such information within its group
                    companies and officers and employees of such group companies
                    for the purpose of processing personal information on its
                    behalf. We also ensure that these recipients of such
                    information agree to process such information based on our
                    instructions and in compliance with this Privacy Policy and
                    any other appropriate confidentiality and security measures.
                  </p>
                </div>
                <h3>INFORMATION SECURITY</h3>
                <div>
                  <p>
                    We take appropriate security measures to protect against
                    unauthorized access to or unauthorized alteration,
                    disclosure or destruction of data. These include internal
                    reviews of our data collection, storage and processing
                    practices and security measures, including appropriate
                    encryption and physical security measures to guard against
                    unauthorized access to systems where we store personal data.
                  </p>
                  <p>
                    All information gathered on our Website / App is securely
                    stored within our controlled database. The database is
                    stored on servers secured behind a firewall; access to the
                    servers is password-protected and is strictly limited.
                    However, as effective as our security measures are, no
                    security system is impenetrable. We cannot guarantee the
                    security of our database, nor can we guarantee that
                    information you supply will not be intercepted while being
                    transmitted to us over the Internet. And, of course, any
                    information you include in a posting to the discussion areas
                    is available to anyone with Internet access.
                  </p>
                  <p>
                    However the internet is an ever evolving medium. We may
                    change our Privacy Policy from time to time to incorporate
                    necessary future changes. Of course, our use of any
                    information we gather will always be consistent with the
                    policy under which the information was collected, regardless
                    of what the new policy maybe.
                  </p>
                </div>
              </div>
                </div>
              </div>

              <!-- Footer -->
    <div class="d-none d-md-block">
      <section
        class="footer pt-5"
        v-if="
          $route.path !== '/login' &&
          $route.path !== '/signin' &&
          $route.path !== '/otp-verification' &&
          $route.path !== '/KYC-1' &&
          $route.path !== '/KYC-2'
        "
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-3 col-12 mb-3">
              <div class="d-flex flex-column">
                <div>
                  <img
                    src="@/assets/speedrentlogo 2.png"
                    alt=""
                    class="img-fluid footer-img"
                  />
                </div>
                <div class="d-flex py-2">
                  <div>
                    <i class="fa-solid fa-envelope me-2" style="font-size: 14px;"></i>
                  </div>
                  <div class="footer-description">speedrentbikes@gmail.com</div>
                </div>
                <div>
                  <div class="d-flex py-2">
                    <div>
                      <i class="fa-solid fa-location-dot me-2 pt-2"></i>
                    </div>
                    <div class="footer-description">
                      Plot No 280 Starcity Road Near RTO-II, Patia, Bhubaneswar,
                      Odisha 751024.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-4 mb-3">
              <div>
                <h4 class="footer-header pb-2">Quick Links</h4>
                <ul class="list-unstyled">
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/arrow-right.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2">
                      <a href="/" class="footer-link"
                        >Bike Rental in Bhubaneswar</a
                      >
                    </div>
                  </li>
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/arrow-right.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2">
                      <a href="/" class="footer-link">Bike Rental in Cuttack</a>
                    </div>
                  </li>
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/arrow-right.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2">
                      <a href="/" class="footer-link"
                        >Bike Rental in Bangalore</a
                      >
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-3 col-md-4 mb-3">
              <div>
                <h4 class="footer-header pb-2">Terms & Policies</h4>
                <ul class="list-unstyled">
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/invoice.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2">
                      <a href="/privacy-policy" class="footer-link"
                        >Privacy Policy</a
                      >
                    </div>
                  </li>
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/invoice.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2">
                      <a href="/terms&conditions" class="footer-link"
                        >Terms & Conditions</a
                      >
                    </div>
                  </li>
                  <li class="d-flex pb-2">
                    <div>
                      <img src="@/assets/invoice.png" class="img-fluid" />
                    </div>
                    <div class="pt-1 ps-2 footer-link">
                      <a href="/refund&cancellations" class="footer-link"
                        >Refund & Cancellations</a
                      >
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-3 col-md-4 mb-3">
              <div>
                <h4 class="footer-header pb-2">Get it from</h4>
              </div>
              <div class="d-flex flex-lg-row flex-column">
                <div class="mb-3">
                  <a href="/">
                    <img
                      src="@/assets/app-store.png"
                      class="img-fluid py-1 px-1"
                      alt="App Store"
                    />
                  </a>
                </div>
                <div class="ps-xl-2 ps-0">
                  <a href="https://play.google.com/store/apps/details?id=com.customer.speedrent">
                    <img
                      src="@/assets/play-store.png"
                      class="img-fluid py-1 px-1"
                      alt="App Store"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="container pb-1">
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="copyrights text-md-start text-center">
                <a href="/" style="color: #000">
                  © 2023 Speed Rent. All Rights Reserved
                </a>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="float-md-end float-none">
                <div class="d-flex justify-content-center">
                  <div class="pe-5">
                    <a href="https://www.facebook.com/profile.php?id=61555955444703&mibextid=ZbWKwL" style="color: black"
                      ><i class="fa-brands fa-square-facebook"></i
                    ></a>
                  </div>
                  <div class="pe-5">
                    <a href="https://www.instagram.com/speedrent_bike_rental?igsh=MjZ3NmszZmV5eWho" style="color: black"
                      ><i class="fa-brands fa-instagram"></i
                    ></a>
                  </div>
                  <div class="">
                    <a href="https://www.youtube.com/@Speedrent-mg3zo" style="color: black"
                      ><i class="fa-brands fa-youtube"></i></a>
                  </div>
                  <!-- <div>
                    <a href="/" style="color: black"
                      ><i class="fa-brands fa-x-twitter"></i
                    ></a>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Footer -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      userDetails: []
    }
  },
  methods: {
    async Logout() {
      $("#logoutmodal").modal("show");
    },

    No() {
      $("#logoutmodal").modal("hide");
      this.$router.go(this.$router.currentRoute);
    },
    Yes() {
      this.$storage.clearStorageSync();
      $("#logoutmodal").modal("hide");
      this.$router.go(this.$router.currentRoute);
    },
    editProfile() {
      $(".offcanvas").offcanvas("hide");
      $("#profilemodal").modal("show");
    },
    onEditPro() {
      axios
      .post(
        "https://speedrent.in/v2web/editProfile.php",
        {
          image: this.selfie.split(',')[1],
          name: this.userDetails.name,
          email: this.userDetails.email,
          number: this.userDetails.number,
        },
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        $("#profilemodal").modal("hide");
      })
      .catch((error) => {
        console.log(error);
      });
    },
    preview(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.selfie = reader.result;
        // console.log('result', reader.result)
      };
      reader.readAsDataURL(file);
    },
  },
  mounted() {
    axios
    .post(
      "https://speedrent.in/v2web/getUserDetails.php",
      {
       phone: this.$storage.getStorageSync("pnumber"),
      },
      {
        headers: {
          "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      }
    )
    .then((response) => {
      console.log(response);
      this.userDetails = response.data;
     this.kycDone = response.data.kycDone;
     console.log(this.kycDone)
    })
    .catch((error) => {
      console.log(error);
    });
  }
}
</script>

<style scoped>
.main {
  overflow-x: hidden;
}
.logo {
  width: 60px;
}
a {
  text-decoration: none;
  color: #0000;
}
/* navbar */
nav .nav-link {
  color: rgba(1, 22, 64, 1);
  font-family: "Satoshi";
  font-weight: 500;
}
nav .download {
  background: rgba(255, 102, 0, 1);
  border-radius: 5px;
}
nav .btn-txt {
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-family: "Satoshi";
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .d {
    padding-top: 14px;
  }
}
.navbar-toggler:focus,
.btn-close:focus {
  outline: none !important;
  box-shadow: none !important;
}
/* end of navbar */

/* footer */
.footer {
  background: #f9f9fc;
  position: sticky;
}
.footer a {
  text-decoration: none;
}
.footer .fa-solid {
  color: #ff6600;
}
.footer .footer-header {
  font-family: "Satoshi";
  font-weight: 600;
  color: #ff6600;
}
.footer .footer-link {
  font-weight: 500;
  font-family: "Satoshi";
  color: #010a26;
}
/* end of footer */
#logoutmodal .btn-primary {
  background: #011640;
  color: #fff;
  border: none;
}
#logoutmodal .btn-primary:hover {
  background: #011640;
  color: #fff;
  border: none;
}
#logoutmodal .btn-outline-primary {
  color: #000;
  border-color: #ff6600;
}
#logoutmodal .btn-outline-primary:hover {
  color: #fff;
  background: #ff6600;
}
a {
  text-decoration: none;
  color: #000;
}

/* form */
.profile form {
  background: #fff;
  border-radius: 10px;
}
.profile form .register-btn {
  background: #ff6600 !important;
  color: #fff !important;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.profile form .register-btn:hover {
  background: #ffffff;
  color: #ff6600;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.profile .form-label {
  color: #676c7d;
}
.profile .sub {
  color: #676c7d;
}

.profile .form-control {
  color: #20242d;
}
.profile .container {
  box-shadow: 0px 0px 15px 0px #00000012;
}
.profile .edit {
  color: #8f9daa;
}
.profile .rounded {
  background: #ff6600;
  color: #ffffff;
  border: 0;
}
.profile .alter-button {
  font-family: Inter;
  font-size: 20px;
}
.profile .profile-img {
  position: relative;
}
.profile .delete-icon {
  position: absolute;
  bottom: 0px;
  left: 90px;
}

.profile #drop-area {
  width: 125px;
  height: 125px;
  padding: 0px 10px;
  /* text-align: center; */
  border-radius: 8px;
  border: 1px solid #000;
}
.profile #img-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.profile #img-view img {
  width: 100px;
  /* object-fit: cover; */
}
.profile .imgs {
  object-fit: cover;
  height: 80px;
}
.nav-btn:hover {
  color: #fff;
  border: none;
}
.footer-img {
  width: 100px;
  height: 100px;
}
.profile .imgs {
  object-fit: cover;
  height: 100px;
}
</style>