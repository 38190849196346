<template>
  <div class="main">
    
    <!-- Web View -->
    <section class="web d-none d-lg-block"> 
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-7 col-lg-6 first-sec">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="px-5 pt-5">
                                <img src="@/assets/speedrentlogo.png" alt="" class="logo img-fluid">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 d-flex align-items-center justify-content-start" style="height: 80vh;">
                            <div class="ps-xxl-5 ps-xl-2 ps-0">
                              <img src="@/assets/Hero.png" alt="" class="img-fluid hero-img ps-5">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-5 col-lg-6 second-sec">
                    <div class="row">
                        <div class="col-md-12">
                          <router-link to="/">
                            <div class="px-5 pt-5 float-end">
                                <button type="button" class="btn btn-outline px-5">Skip</button>
                            </div>
                          </router-link>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 d-flex align-items-center justify-content-center" style="height: 80vh;">
                            <div class="content pt-5">
                              <h2 class="text-center heading pt-5">Your journey begins with convenience, style, and endless possibilities!</h2>
                              <router-link to="/signin">
                                <div class="text-center pt-2">
                                  <button type="button" class="btn btn-primary px-5">Login</button>
                                </div>
                              </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- mobile view -->
    <section class="mobile d-lg-none pb-5"> 
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-5 first-sec">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="text-center pt-5">
                                <img src="@/assets/speedrentlogo.png" alt="" class="logo img-fluid">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" style="height: 80vh;">
                            <div class="text-center pt-5">
                              <img src="@/assets/loginImg.png" class="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 second-sec">
                    <div class="pt-5 content">
                        <h2 class="text-center heading px-5 pb-5">Your journey begins with convenience, style, and endless possibilities!</h2>
                        <div class="d-flex align-items-center justify-content-center">
                          <router-link to="/">
                            <div class="pe-3">
                                <button type="button" class="btn btn-outline text-uppercase px-5">Skip</button>
                            </div>
                          </router-link>
                            <router-link to="/signin">
                                <div class="ps-3">
                                    <button type="button" class="btn btn-primary text-uppercase px-5">Login</button>
                                </div>
                              </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style scoped>
/* @font-face {
  font-family: "Satoshi";
  src: url("~@/assets/fonts/Satoshi-Medium.ttf");
} */
.main {
  overflow: hidden;
}
@media (max-width: 1200px) {
  .hero-img {
    max-width: 350px !important;
  }
}
/* Web View */
.web {
    background: #ff6600;
}
@media (min-width: 1200px) {
    .web .first-sec { 
        background-image: url("@/assets/Intersect2.svg"); 
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
    }
}
@media (max-width: 1199.98px) {
    .web .first-sec { 
        background-image: url("@/assets/Intersect3.png"); 
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 100vh;
    }
}
.web .heading {
  color: #ffffff;
  font-family: "Satoshi";
  font-weight: 400; 
}
.web .btn-primary {
  background: #ffffff;
  color: #ff6600;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-primary:hover {
  background: #ffffff;
  color: #ff6600;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-outline {
  background-color: transparent;
  border: 1px solid #f7e6db;
  color: #f7e6db;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-outline:hover {
  background: #f7e6db;
  border: none;
  color: green;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
/* Mobile View */
.mobile {
  background: #ff6600;
}
.mobile .first-sec{
  background: url("@/assets/news/mmm.png");
  background-repeat: no-repeat;
  background-position: left 100%;
  background-size: contain;
  background-size: 100% 100%;
  height: 85vh;
}
.mobile .second-sec {
  height: 35vh;
}
.mobile .heading {
  color: #ffffff;
  font-family: "Satoshi";
  font-weight: 400; 
}
.mobile .btn-primary {
  background: #ffffff;
  color: #ff6600;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.mobile .btn-primary:hover {
  background: #ffffff;
  color: #ff6600;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.mobile .btn-outline {
  background-color: transparent;
  border: 1px solid #f7e6db;
  color: #f7e6db;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.mobile .btn-outline:hover {
  background: #f7e6db;
  border: none;
  color: green;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
</style>