<template>
  <div class="main">

    <div
      class="modal fade"
      id="successalert"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body py-4">
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <div>
                <img src="@/assets/otpalert.png" alt="" class="img-fluid" />
              </div>
              <p class="text-center py-3">
                KYC Completed!<br> Welcome to
                <span style="color: #ff6600">SPEED RENT</span>!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Web View -->
    <section class="web d-none d-lg-block">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-7 col-lg-6 first-sec">
            <div class="row">
              <div class="col-md-12">
                <div class="px-5 pt-5">
                  <img
                    src="@/assets/speedrentlogo.png"
                    alt=""
                    class="logo img-fluid"
                  />
                </div>
              </div>
            </div>
            <div class="row pt-xxl-5 pt-5">
              <div
                class="col-md-12 d-flex align-items-center justify-content-start pt-xl-2 pt-5"
              >
                <div class="ps-xxl-5 ps-xl-2 ps-0 pt-xxl-3">
                  <img
                    src="@/assets/Hero.png"
                    alt=""
                    class="img-fluid hero-img ps-5"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-5 col-lg-6 second-sec">
            <div class="row">
              <div class="col-md-12">
                <router-link to="/">
                  <div class="px-5 pt-5 float-end">
                    <button type="button" class="btn btn-outline px-5">
                      Skip
                    </button>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex align-items-center justify-content-center pt-xxl-5"
              >
                <div class="content py-5">
                  <form
                    v-on:submit.prevent="onDrivingKYC()"
                    class="w-100 d-flex align-items-center justify-content-center pt-1"
                  >
                    <div class="px-lg-4 px-xxl-5 py-4 form">
                      <div class="d-flex justify-content-between">
                        <div>
                          <h6>KYC - Step 2/2</h6>
                          <p style="font-size: 14px">
                            Please provide your Driving License details for
                            verification purposes.
                          </p>
                        </div>
                        <div>
                          <a href="/">
                            <img
                              src="@/assets/skipbtn.png"
                              alt=""
                              class="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                      <div class="w-100 pb-xxl-4 pb-3">
                        <label for="driving">Driving License Number</label>
                        <input
                          type="text"
                          v-model="userDetails.driving"
                          class="form-control"
                          id="autoSizingInput"
                          placeholder="Enter your Driving License number"
                          required
                        />
                      </div>
                      <div class="pb-3 pb-xxl-4">
                        <label for="driving-img"
                          >Driving License (Picture)</label
                        >
                        <label for="input-file" id="drop-area">
                          <input
                            type="file"
                            accept="image/*"
                            id="input-file"
                            @change="preview"
                            hidden
                          />
                          <div id="img-view">
                            <div v-if="drivingL">
                              <img class="img-fluid imgs" :src="drivingL" />
                            </div>
                            <div v-else>
                              <img
                                v-bind:src="`https://speedrent.in/adminapp/uploads/${userDetails.drivingImage}`"
                                alt=""
                                class="img-fluid imgs"
                              />
                            </div>
                          </div>
                        </label>
                        <div
                          v-if="!isDrivingUploaded && submitted"
                          class="text-danger"
                        >
                          Driving Liscence image is required.
                        </div>
                      </div>
                      <div class="text-center">
                        <button
                          type="submit"
                          class="btn btn-primary btn-large w-100 text-uppercase"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- mobile login view -->
    <section class="mobile-login p-0 d-lg-none">
      <div class="log-sec row">
        <div class="col-12 py-3">
          <router-link to="/">
            <div
              class="logo-c col-12 d-flex align-items-end justify-content-end py-3 px-5"
            >
              <button type="button" class="btn btn-outline-light px-3">
                <img src="@/assets/arrow.svg" alt="" /> Skip
              </button>
            </div>
          </router-link>
          <div class="col-12 d-flex align-items-center justify-content-center">
            <img src="@/assets/loginlogo.png" alt="" class="img-fluid" />
          </div>
        </div>

        <div
          class="logo-c2 col-12 bg-light rounded-5 rounded-bottom pb-0 px-5 pt-5"
        >
          <div class="mt-5">
            <form class="w-100" v-on:submit.prevent="onDrivingKYC()">
              <div class="px-lg-4 px-xxl-5 py-4 form">
                <div class="d-flex justify-content-between">
                  <div>
                    <h6>KYC - Step 2/2</h6>
                    <p style="font-size: 14px">
                      Please provide your Driving License details for
                      verification purposes.
                    </p>
                  </div>
                  <div>
                    <a href="/">
                      <img
                        src="@/assets/skipbtn.png"
                        alt=""
                        class="img-fluid"
                      />
                    </a>
                  </div>
                </div>
                <div class="w-100 pb-xxl-4 pb-3">
                  <label for="driving">Driving License Number</label>
                  <input
                  v-model="userDetails.driving"
                    type="text"
                    class="form-control"
                    id="autoSizingInput"
                    placeholder="Enter your Driving License number"
                    required
                  />
                </div>
                <div class="pb-3 pb-xxl-4">
                  <label for="">Driving License (Picture)</label>
                  <label for="input-file" id="drop-area">
                    <input
                      type="file"
                      accept="image/*"
                      id="input-file"
                      @change="preview"
                      hidden
                    />
                    <div id="img-view">
                            <div v-if="drivingL">
                              <img class="img-fluid imgs" :src="drivingL" />
                            </div>
                            <div v-else>
                              <img
                                v-bind:src="`https://speedrent.in/adminapp/uploads/${userDetails.drivingImage}`"
                                alt=""
                                class="img-fluid imgs"
                              />
                            </div>
                          </div>
                  </label>
                  <div
                          v-if="!isDrivingUploaded && submitted"
                          class="text-danger"
                        >
                          Driving Liscence image is required.
                        </div>
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-primary btn-large w-100 text-uppercase"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!-- mobile login view ends-->
  </div>
</template>

<script>
import axios from 'axios';
import Swal from "sweetalert2";

export default {
  name: "App",
  data() {
    return {
      drivingLiscence: "",
      driving: "",
      isDrivingUploaded: "",
      submitted: false,
      userDetails: {
        driving: "",
        drivingL: "",
      },
    };
  },
  watch: {
    drivingLiscence(newdrivingLiscence) {
      this.$storage.setStorageSync("drivingLiscence", newdrivingLiscence);
    },
    driving(newdriving) {
      this.$storage.setStorageSync("driving", newdriving);
    },
  },
  methods: {
    preview(e) {
      this.isdrivingUploaded = true;
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.drivingL = reader.result;
        // console.log('result', reader.result)
      };
      reader.readAsDataURL(file);
    },

    onDrivingKYC() {
      // console.log("hi drive")

      // this.submitted = true;
      // if (!this.driving) {
      //   this.isDrivingUploaded = false;
      // }

      // if (this.driving) {
      //   this.isDrivingUploaded = true;

      //   console.log(this.$storage.getStorageSync('selfie'))
      // console.log(this.$storage.getStorageSync('cNumber'))
      //   console.log(this.$storage.getStorageSync('adhaarNumber'))
      //   console.log(this.$storage.getStorageSync('adhaar1'))
      //   console.log(this.$storage.getStorageSync('adhaar2'))
      // console.log(this.$storage.getStorageSync('drivingLiscence'))
      // console.log(this.$storage.getStorageSync('driving'))

      axios
        .post(
          "https://speedrent.in/v2web/customerKyc.php",
          {
            adhaarNumber :  this.$storage.getStorageSync('adhaarNumber'),
            drivingLiscence: this.$storage.getStorageSync('drivingLiscence'),
            // selfie: this.$storage.getStorageSync('selfie').split(',')[1],
            adhaar1: this.$storage.getStorageSync('adhaar1').split(',')[1],
            adhaar2: this.$storage.getStorageSync('adhaar2').split(',')[1],
            driving: this.$storage.getStorageSync('driving').split(',')[1],
            cNumber: this.$storage.getStorageSync('pnumber'),
          },
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }
        )
        .then((response) => {

          // console.log(response);
          $("#successalert").modal("show");
          setTimeout(function () {
                $("#successalert").hide();
                $(".modal-backdrop").remove();
              }, 2500)
          this.$router.push({ path: "/" });
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "Server responded with status code:",
              error.response.status
            );
            console.log("Response data:", error.response.data);
          } else if (error.request) {
            console.log("No response received:", error.request);
          } else {
            console.log("Error creating request:", error.message);
          }
        });
    },
  },
  mounted() {
    axios
      .post(
        "https://speedrent.in/v2web/getUserDetails.php",
        {
          phone: this.$storage.getStorageSync("pnumber"),
        },
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        this.userDetails = response.data;
        this.kycDone = response.data.kycDone;
        //  console.log(this.kycDone)
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
</script>

<style scoped>
@font-face {
  font-family: "Satoshi";
  src: url("~@/assets/fonts/Satoshi-Medium.ttf");
}
.main {
  overflow-x: hidden;
}
a {
  text-decoration: none;
}
@media (max-width: 1200px) {
  .hero-img {
    max-width: 350px !important;
  }
}
/* Web View */
.web {
  background: #f5d8c5;
}
@media (min-width: 1400px) {
  .web .first-sec {
    background-image: url("@/assets/Intersect2.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
  }
}
@media (max-width: 1399.98px) {
  .web .first-sec {
    background-image: url("@/assets/Intersect3.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100vh;
  }
}
.web .heading {
  color: #ffffff;
  font-family: "Satoshi";
  font-weight: 400;
}
.web form {
  background: #fff;
  border-radius: 10px;
  width: 70%;
}
.web form select:focus {
  box-shadow: none;
  border: 1px solid gray;
}
.web form input:focus {
  box-shadow: none;
  border: 1px solid gray;
}
.web .btn-primary {
  background: #ff6600;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-primary:hover {
  background: #ff6600;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-outline {
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-outline:hover {
  background-color: #000;
  border: none;
  color: #fff;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}

.web #drop-area {
  width: 100%;
  height: 130px;
  padding: 30px;
  /* text-align: center; */
  border-radius: 8px;
  border: 1px solid #e5e4e4;
}
.web #img-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.web #img-view img {
  width: 100px;
}

.web #drop-area2 {
  width: 100%;
  height: 130px;
  padding: 30px;
  /* text-align: center; */
  border-radius: 8px;
  border: 1px solid #e5e4e4;
}
.web #img-view2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.web #img-view2 img {
  width: 100px;
}
.web .imgs {
  object-fit: cover;
  height: 100px;
}
/* mobile view  */
.mob-kyc-1 {
  height: 100vh;
  background: #fff;
}
.log-sec {
  background: #ff6602 !important;
}
@media screen and (min-width: 1200px) {
  .mob-kyc-1 {
    display: none;
  }
  .hero {
    display: block !important;
  }
}
@media screen and (min-width: 1200px) {
  .mob-kyc-1 {
    display: none !important;
  }
}
.mobile-login .btn-primary {
  background: #ff6600;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.mobile-login .btn-primary:hover {
  background: #ff6600;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}

.mobile-login #drop-area {
  width: 100%;
  height: 130px;
  padding: 30px;
  /* text-align: center; */
  border-radius: 8px;
  border: 1px solid #e5e4e4;
}
.mobile-login #img-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.mobile-login #img-view img {
  width: 100px;
}

.mobile-login #drop-area2 {
  width: 100%;
  height: 130px;
  padding: 30px;
  /* text-align: center; */
  border-radius: 8px;
  border: 1px solid #e5e4e4;
}
.mobile-login #img-view2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.mobile-login #img-view2 img {
  width: 100px;
}
.mobile-login .imgs {
  object-fit: cover;
  height: 100px;
}
</style>