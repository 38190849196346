<template>
  <div class="main">
    <!-- Web View -->
    <section class="web d-none d-lg-block">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-7 col-lg-6 first-sec">
            <div class="row">
              <div class="col-md-12">
                <div class="px-5 pt-5">
                  <img
                    src="@/assets/speedrentlogo.png"
                    alt=""
                    class="logo img-fluid"
                  />
                </div>
              </div>
            </div>
            <div class="row pt-xxl-5 pt-xl-3 pt-5">
              <div
                class="col-md-12 d-flex align-items-center justify-content-start pt-xl-2 pt-5"
              >
                <div class="ps-xxl-5 ps-xl-2 ps-0">
                  <img
                    src="@/assets/Hero.png"
                    alt=""
                    class="img-fluid hero-img ps-5"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-5 col-lg-6 second-sec">
            <div class="row">
              <div class="col-md-12">
                <router-link to="/">
                  <div class="px-5 pt-5 float-end">
                    <button type="button" class="btn btn-outline px-5">
                      Skip
                    </button>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="row pt-xxl-5 pt-xl-3 pt-3">
              <div
                class="col-md-12 d-flex align-items-center justify-content-center pt-5"
              >
                <div class="content pt-5">
                  <form
                    class="w-100 d-flex align-items-center justify-content-center p-5"
                    v-on:submit.prevent="onLogin()"
                  >
                    <div class="form">
                      <div>
                        <h6 class="pb-3">
                          Please enter your mobile number to get a verification
                          code
                        </h6>
                        <div class="d-flex mb-5">
                          <div class="col-auto pe-2">
                            <select class="form-select" id="autoSizingSelect">
                              <option selected>+91</option>
                              <option value="1">+44</option>
                              <option value="2">+66</option>
                              <option value="3">+88</option>
                            </select>
                          </div>
                          <div class="w-100">
                            <input
                              type="text"
                              v-model="pnumber"
                              class="form-control"
                              id="autoSizingInput"
                              placeholder="Enter your mobile number"
                              title="Please enter exactly 10 digits"
                              maxlength="10" pattern="\d{10}"
                              required
                            />
                          </div>
                        </div>
                        <div
                          class="form-check d-flex align-items-center justify-content-center pb-2"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="checked"
                            id="autoSizingCheck"
                          />
                          <label
                            class="form-check-label ps-2"
                            for="autoSizingCheck"
                          >
                            By clicking, I accept the <span><a href="/terms&conditions"><b>Terms</b> &
                            <b>Conditions.</b></a></span>
                          </label>
                        </div>
                        <div class="text-center pt-1">
                          <button
                            type="submit"
                            class="btn btn-primary btn-large w-100"
                            :disabled="!checked"
                          >
                            Get OTP
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- mobile login view -->
    <section class="mobile-login p-0 d-lg-none">
      <div class="log-sec row">
        <div class="col-12 pb-3 pt-4">
          <router-link to="/">
            <div
              class="logo-c col-12 d-flex align-items-end justify-content-end py-3 px-5"
            >
              <button type="button" class="btn btn-outline-light px-3">
                <img src="@/assets/arrow.svg" alt="" /> Skip
              </button>
            </div>
          </router-link>
          <div class="col-12 d-flex align-items-center justify-content-center">
            <img src="@/assets/loginlogo.png" alt="" class="img-fluid" />
          </div>
        </div>

        <div
          class="logo-c2 col-12 bg-light rounded-5 rounded-bottom pb-0 px-5 pt-5"
        >
          <div class="my-3">
            <form class="w-100" v-on:submit.prevent="onLogin()">
              <div class="px-lg-4 px-xxl-5 py-3 form">
                <div>
                  <h6 class="pb-3">
                    Please enter your mobile number to get a verification code
                  </h6>
                  <div class="d-flex pb-5">
                    <div class="col-auto pe-2">
                      <select class="form-select" id="autoSizingSelect">
                        <option selected>+91</option>
                        <option value="1">+44</option>
                        <option value="2">+66</option>
                        <option value="3">+88</option>
                      </select>
                    </div>
                    <div class="w-100">
                      <input
                      v-model="pnumber"
                        type="text"
                        class="form-control"
                        id="autoSizingInput"
                        placeholder="Enter your mobile number"
                        title="Please enter exactly 10 digits"
                        maxlength="10" pattern="\d{10}"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      class="form-check d-flex align-items-center justify-content-center pb-2 pt-2"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="checked"
                        id="autoSizingCheck"
                      />
                      <label
                        class="form-check-label ps-2"
                        for="autoSizingCheck"
                      >
                        By clicking, I accept the <span><a href="/terms&conditions"><b>Terms</b> &
                            <b>Conditions.</b></a></span>
                      </label>
                    </div>
                    <div class="text-center">
                      <button
                        type="submit"
                        class="btn btn-primary btn-large w-100 p-3 border-0"
                        :disabled="!checked"
                      >
                        Get OTP
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!-- mobile login view ends-->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      pnumber: "",
      checked: "",
    };
  },
  watch: {
    pnumber(newpnumber) {
      this.$storage.setStorageSync("pnumber", newpnumber);
    },
  },
  methods: {
    restrictChars: function ($event) {
      if (
        $event.charCode === 0 ||
        /\d/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    
    onLogin() {
      axios
        .post(
          "https://speedrent.in/v2web/customerOtp.php",
          {
            pnumber: this.pnumber,
          },
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }
        )
        .then((response) => {
        //   console.log(response);
          this.$router.push({ path: "/otp" });
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "Server responded with status code:",
              error.response.status
            );
            console.log("Response data:", error.response.data);
          } else if (error.request) {
            console.log("No response received:", error.request);
          } else {
            console.log("Error creating request:", error.message);
          }
        });
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "Satoshi";
  src: url("~@/assets/fonts/Satoshi-Medium.ttf");
}
.main {
  overflow-x: hidden;
}
a {
  text-decoration: none;
}
@media (max-width: 1200px) {
  .hero-img {
    max-width: 350px !important;
  }
}
/* Web View */
.web {
  background: #f5d8c5;
}
@media (min-width: 1200px) {
  .web .first-sec {
    background-image: url("@/assets/Intersect2.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
  }
}
@media (max-width: 1199.98px) {
  .web .first-sec {
    background-image: url("@/assets/Intersect3.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100vh;
  }
}
.web .heading {
  color: #ffffff;
  font-family: "Satoshi";
  font-weight: 400;
}
.web .btn-primary {
  background: #ff6600;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-primary:hover {
  background: #ff6600;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-outline {
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web .btn-outline:hover {
  background: #000;
  border: none;
  color: #fff;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.web form {
  background: #fff;
  border-radius: 10px;
}
.web form select:focus {
  box-shadow: none;
  border: 1px solid gray;
}
.web form input:focus {
  box-shadow: none;
  border: 1px solid gray;
}

/* mobile view css */
.figure-shadow {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.log-sec {
  height: 100vh;
}
.hero form {
  background: #fff;
  border-radius: 10px;
}
.hero form select:focus {
  box-shadow: none;
  border: 1px solid gray;
}
.hero form input:focus {
  box-shadow: none;
  border: 1px solid gray;
}
.hero .btn-primary {
  background: #f7b88f;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.hero .btn-primary:hover {
  background: #ff6600;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}

.btn-primary {
  background: #ff6600;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}
.btn-primary:hover {
  background: #ff6600;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "Inter";
  text-transform: uppercase;
}


.get-otp {
  background-color: #ffb180 !important;
  color: #fff;
}
.log-sec {
  background: #ff6602 !important;
}
@media screen and (min-width: 1200px) {
  .mobile-login {
    display: none;
  }
  .hero {
    display: block !important;
  }
}
a {
  text-decoration: none;
  color: #000;
}
</style>